import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  renderOption,
  placeholder = "Cualquier opción",
  height = "32px",
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ minWidth: 180 }}>
      <InputLabel
        sx={{
          lineHeight: "1rem",
          transform: "translate(14px, 6px) scale(1)",
          "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={label}
            sx={{
              height: height,
              ".MuiSelect-select": {
                paddingTop: "3px",
                paddingBottom: "3px",
                minHeight: "unset !important",
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap",
                overflow: "hidden",
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              gap: 0.5,
              overflow: "hidden",
              width: "100%",
            }}
          >
            {selected.length === 0 ? (
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>{placeholder}</span>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  overflow: "hidden",
                  flexWrap: "nowrap",
                }}
              >
                {selected.map((value, index) => {
                  const label =
                    options.find((opt) => opt.value === value)?.label || value;
                  const isLast = index === selected.length - 1;

                  return (
                    <Chip
                      key={value}
                      label={label}
                      size="small"
                      sx={{
                        height: "20px",
                        maxWidth: isLast ? "none" : "80px",
                        ".MuiChip-label": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                        },
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              minHeight: ITEM_HEIGHT,
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {renderOption ? renderOption(option) : option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
