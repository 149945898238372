import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  CircularProgress,
  Chip,
  Typography,
} from "@mui/material";

const KeywordForm = ({ open, onClose, onSubmit, initialData, loading }) => {
  const [formData, setFormData] = useState({
    text_keyword: "",
    expressions_to_search: [],
  });
  const [newExpression, setNewExpression] = useState("");

  useEffect(() => {
    if (initialData) {
      setFormData({
        text_keyword: initialData.text_keyword || "",
        expressions_to_search: initialData.expressions_to_search || [],
      });
    } else {
      setFormData({
        text_keyword: "",
        expressions_to_search: [],
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddExpression = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const expressions = newExpression
        .split(",")
        .map((exp) => exp.trim())
        .filter((exp) => exp);

      if (expressions.length > 0) {
        setFormData((prev) => ({
          ...prev,
          expressions_to_search: [
            ...new Set([...prev.expressions_to_search, ...expressions]),
          ],
        }));
        setNewExpression("");
      }
    }
  };

  const handleExpressionChange = (e) => {
    setNewExpression(e.target.value);
  };

  const handleDeleteExpression = (expressionToDelete) => {
    setFormData((prev) => ({
      ...prev,
      expressions_to_search: prev.expressions_to_search.filter(
        (exp) => exp !== expressionToDelete
      ),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          pb: 1,
          fontSize: "1.25rem",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        {initialData ? "Editar Palabra Clave" : "Nueva Palabra Clave"}
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Texto de la Palabra Clave"
            name="text_keyword"
            value={formData.text_keyword}
            onChange={handleChange}
            required
            size="small"
            sx={{
              mb: 3,
              mt: 2,
              "& .MuiInputLabel-root": {
                fontSize: "0.875rem",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.875rem",
              },
            }}
          />

          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Expresiones alternativas de búsqueda"
              value={newExpression}
              onChange={handleExpressionChange}
              onKeyDown={handleAddExpression}
              placeholder="Escribe y presiona Enter para añadir"
              size="small"
              sx={{
                "& .MuiInputLabel-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem",
                },
              }}
              helperText={
                <Typography
                  variant="caption"
                  sx={{ display: "block", mt: 0.5 }}
                >
                  Presiona Enter para añadir. Si pegas texto con comas, se
                  añadirán todas las expresiones.
                </Typography>
              }
            />

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.75,
                mt: 1.5,
                minHeight: "48px",
                p: 1,
                border: "1px solid #e0e0e0",
                borderRadius: 1,
                backgroundColor: "#fafafa",
              }}
            >
              {formData.expressions_to_search.map((expression, index) => (
                <Chip
                  key={index}
                  label={expression}
                  onDelete={() => handleDeleteExpression(expression)}
                  size="small"
                  sx={{
                    fontSize: "0.75rem",
                    "& .MuiChip-deleteIcon": {
                      fontSize: "1rem",
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          onClick={onClose}
          disabled={loading}
          size="small"
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
          }}
        >
          Cancelar
        </Button>
        <Box sx={{ position: "relative" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
            size="small"
            sx={{
              fontSize: "0.875rem",
              textTransform: "none",
              px: 3,
            }}
          >
            {initialData ? "Actualizar" : "Crear"}
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordForm;
