import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import campaignScheduleService from "../../services/campaignScheduleService";
import campaignService from "../../services/campaignService";
import SearchIcon from "@mui/icons-material/Search";

const CampaignScheduleList = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [formData, setFormData] = useState({
    id_locations: [],
    datetime_start: "",
    datetime_end: "",
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const searchTimeout = useRef(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const data = await campaignService.getCampaigns(clientId);
        const activeCampaigns = data.filter(
          (campaign) => !campaign.datetime_end
        );
        setCampaigns(activeCampaigns);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        showNotification("Error al cargar las campañas", "error");
      }
    };
    fetchCampaigns();
  }, [clientId]);

  const fetchSchedules = async () => {
    if (!selectedCampaignId) {
      setSchedules([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const data = await campaignScheduleService.getSchedules(
        clientId,
        selectedCampaignId
      );
      setSchedules(data);
    } catch (error) {
      console.error("Error fetching schedules:", error);
      showNotification("Error al cargar los horarios", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [selectedCampaignId]);

  const handleCampaignChange = (event) => {
    setSelectedCampaignId(event.target.value);
  };

  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSchedule(null);
    setFormData({
      id_locations: [],
      datetime_start: "",
      datetime_end: "",
    });
  };

  const handleOpenDialog = (schedule = null) => {
    if (schedule) {
      setSelectedSchedule(schedule);
      setFormData({
        id_locations: schedule.id_locations,
        datetime_start: schedule.datetime_start || "",
        datetime_end: schedule.datetime_end || "",
      });
    }
    setOpenDialog(true);
  };

  // Función auxiliar para añadir 23:59 a una fecha
  const addEndTime = (dateString) => {
    if (!dateString) return null;
    // Verificar si ya tiene formato de hora
    if (dateString.includes("T")) {
      return dateString;
    }
    return `${dateString}T23:59:00`; // Añadimos también los segundos para un formato completo
  };

  // Función para determinar si un horario está activo
  const isScheduleActive = (schedule) => {
    const now = new Date();
    const startDate = schedule.datetime_start
      ? new Date(schedule.datetime_start)
      : null;
    const endDate = schedule.datetime_end
      ? new Date(schedule.datetime_end)
      : null;

    if (!startDate) return false;
    if (!endDate) return startDate <= now;
    return startDate <= now && now <= endDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submissionData = {
        ...formData,
        datetime_start: addEndTime(formData.datetime_start),
        datetime_end: addEndTime(formData.datetime_end),
      };

      if (selectedSchedule) {
        await campaignScheduleService.updateSchedule(
          clientId,
          selectedCampaignId,
          selectedSchedule.id,
          submissionData
        );
        showNotification("Horario actualizado correctamente");
      } else {
        await campaignScheduleService.createSchedule(
          clientId,
          selectedCampaignId,
          submissionData
        );
        showNotification("Horario creado correctamente");
      }
      handleCloseDialog();
      fetchSchedules();
    } catch (error) {
      showNotification(
        error.response?.data?.error || "Error al guardar el horario",
        "error"
      );
    }
  };

  const handleDelete = async (scheduleId) => {
    try {
      await campaignScheduleService.deleteSchedule(
        clientId,
        selectedCampaignId,
        scheduleId
      );
      showNotification("Horario eliminado correctamente");
      fetchSchedules();
    } catch (error) {
      showNotification("Error al eliminar el horario", "error");
    }
  };

  // Función para formatear la fecha para mostrar
  const formatDate = (dateString) => {
    if (!dateString) return "No definido";
    return new Date(dateString).toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsData = await campaignService.getLocations();
        setAllLocations(locationsData);
      } catch (error) {
        console.error("Error al obtener ubicaciones:", error);
        showNotification("Error al obtener ubicaciones", "error");
      }
    };
    fetchLocations();
  }, []);

  const handleLocationSearch = async (searchTerm) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (!searchTerm || searchTerm.length < 2) {
      setLocationOptions([]);
      return;
    }

    searchTimeout.current = setTimeout(async () => {
      setIsLoading(true);
      try {
        const results = await campaignService.searchLocations(searchTerm);
        const filteredResults =
          selectedLocationType === "all"
            ? results
            : results.filter(
                (loc) => loc.type_location === selectedLocationType
              );
        setLocationOptions(filteredResults);
      } catch (error) {
        console.error("Error searching locations:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300);
  };

  const [selectedLocationType, setSelectedLocationType] = useState("all");

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Horarios de Campaña
      </Typography>

      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel>Seleccionar Campaña</InputLabel>
        <Select
          value={selectedCampaignId}
          onChange={handleCampaignChange}
          label="Seleccionar Campaña"
        >
          <MenuItem value="">
            <em>Seleccione una campaña</em>
          </MenuItem>
          {campaigns.map((campaign) => (
            <MenuItem key={campaign.id} value={campaign.id}>
              {campaign.name_campaign}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {selectedCampaignId ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog()}
                sx={{
                  mb: 2,
                  py: 0.5,
                  fontSize: "0.875rem",
                }}
              >
                Añadir Nuevo Horario
              </Button>

              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {schedules.length === 0 ? (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ p: 2, textAlign: "center" }}
                  >
                    No hay horarios configurados para esta campaña
                  </Typography>
                ) : (
                  schedules.map((schedule) => (
                    <ListItem
                      key={schedule.id}
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: 1,
                        mb: 1,
                        py: 1,
                        px: 2,
                        backgroundColor: "background.paper",
                        "&:hover": {
                          backgroundColor: "action.hover",
                        },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography sx={{ fontSize: "0.875rem" }}>
                              {schedule.id_locations.length > 0 ? (
                                <>
                                  {allLocations
                                    .filter((loc) =>
                                      schedule.id_locations.includes(loc.id)
                                    )
                                    .map((loc) => loc.name)
                                    .slice(0, 3)
                                    .join(", ")}
                                  {schedule.id_locations.length > 3 &&
                                    ` y ${
                                      schedule.id_locations.length - 3
                                    } más`}
                                </>
                              ) : (
                                "Sin localizaciones"
                              )}
                            </Typography>
                            <Chip
                              size="small"
                              label={
                                isScheduleActive(schedule)
                                  ? "Activo"
                                  : "Inactivo"
                              }
                              color={
                                isScheduleActive(schedule)
                                  ? "success"
                                  : "default"
                              }
                              sx={{
                                height: "20px",
                                fontSize: "0.75rem",
                                backgroundColor: isScheduleActive(schedule)
                                  ? "rgba(46, 125, 50, 0.1)"
                                  : "rgba(0, 0, 0, 0.08)",
                                color: isScheduleActive(schedule)
                                  ? "rgb(46, 125, 50)"
                                  : "rgba(0, 0, 0, 0.6)",
                              }}
                            />
                          </Box>
                        }
                        secondary={
                          <Box
                            component="span"
                            sx={{
                              "& .MuiTypography-root": { fontSize: "0.75rem" },
                            }}
                          >
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              Inicio: {formatDate(schedule.datetime_start)}
                              <br />
                              Fin: {formatDate(schedule.datetime_end)}
                            </Typography>
                          </Box>
                        }
                      />
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="small"
                          onClick={() => handleOpenDialog(schedule)}
                          sx={{ p: 0.5 }}
                        >
                          <EditIcon sx={{ fontSize: "1.25rem" }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(schedule.id)}
                          sx={{ p: 0.5 }}
                        >
                          <DeleteIcon sx={{ fontSize: "1.25rem" }} />
                        </IconButton>
                      </Box>
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ p: 2, textAlign: "center" }}
            >
              Seleccione una campaña para ver sus horarios
            </Typography>
          )}
        </>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedSchedule ? "Editar Horario" : "Nuevo Horario"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Localizaciones
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                <FormControl size="small" sx={{ minWidth: 200 }}>
                  <InputLabel>Tipo de Localización</InputLabel>
                  <Select
                    value={selectedLocationType}
                    onChange={(e) => setSelectedLocationType(e.target.value)}
                    label="Tipo de Localización"
                  >
                    <MenuItem value="all">Todas</MenuItem>
                    <MenuItem value="Comunidad Autonoma">
                      Comunidad Autónoma
                    </MenuItem>
                    <MenuItem value="Pais">País</MenuItem>
                    <MenuItem value="Provincia">Provincia</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ flexGrow: 1 }}>
                  <Autocomplete
                    options={locationOptions}
                    value={null}
                    inputValue={searchText}
                    onInputChange={(_, newInputValue, reason) => {
                      setSearchText(newInputValue);
                      if (reason === "input" && newInputValue.length >= 2) {
                        handleLocationSearch(newInputValue);
                      }
                    }}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        if (!formData.id_locations.includes(newValue.id)) {
                          setFormData({
                            ...formData,
                            id_locations: [
                              ...formData.id_locations,
                              newValue.id,
                            ],
                          });
                        }
                      }
                      setLocationOptions([]);
                      setSearchText("");
                    }}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.type_location})`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    filterOptions={(x) => x}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Buscar localización..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  size="small"
                  onClick={() => {
                    const filteredLocations =
                      selectedLocationType === "all"
                        ? allLocations
                        : allLocations.filter(
                            (loc) => loc.type_location === selectedLocationType
                          );
                    const newLocationIds = [
                      ...new Set([
                        ...formData.id_locations,
                        ...filteredLocations.map((loc) => loc.id),
                      ]),
                    ];
                    setFormData({
                      ...formData,
                      id_locations: newLocationIds,
                    });
                  }}
                >
                  Seleccionar Todas
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    if (selectedLocationType === "all") {
                      setFormData({
                        ...formData,
                        id_locations: [],
                      });
                    } else {
                      const locationIdsToKeep = formData.id_locations.filter(
                        (id) => {
                          const location = allLocations.find(
                            (loc) => loc.id === id
                          );
                          return (
                            location &&
                            location.type_location !== selectedLocationType
                          );
                        }
                      );
                      setFormData({
                        ...formData,
                        id_locations: locationIdsToKeep,
                      });
                    }
                  }}
                >
                  Desmarcar Todas
                </Button>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {formData.id_locations.map((locationId) => {
                const location = allLocations.find(
                  (loc) => loc.id === locationId
                );
                return (
                  location && (
                    <Chip
                      key={location.id}
                      label={`${location.name} (${location.type_location})`}
                      onDelete={() => {
                        setFormData({
                          ...formData,
                          id_locations: formData.id_locations.filter(
                            (id) => id !== location.id
                          ),
                        });
                      }}
                      size="small"
                    />
                  )
                );
              })}
            </Box>

            <TextField
              fullWidth
              label="Fecha de inicio"
              type="date"
              value={formData.datetime_start?.split("T")[0] || ""}
              onChange={(e) =>
                setFormData({ ...formData, datetime_start: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Fecha de fin"
              type="date"
              value={formData.datetime_end?.split("T")[0] || ""}
              onChange={(e) =>
                setFormData({ ...formData, datetime_end: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              {selectedSchedule ? "Actualizar" : "Crear"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CampaignScheduleList;
