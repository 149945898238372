import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box } from "@mui/material";
import clientService from "../../services/clientService";

const ClientForm = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState({ name: "" });

  useEffect(() => {
    if (clientId) {
      const fetchClient = async () => {
        try {
          const data = await clientService.getClient(clientId);
          setClient(data);
        } catch (error) {
          console.error("Error al obtener cliente:", error);
        }
      };
      fetchClient();
    }
  }, [clientId]);

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (clientId) {
        await clientService.updateClient(clientId, client);
      } else {
        await clientService.createClient(client);
      }
      navigate("/clients");
    } catch (error) {
      console.error("Error al guardar cliente:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 600, margin: "24px auto", p: 3 }}
    >
      <Typography variant="h4" gutterBottom>
        {clientId ? "Editar Cliente" : "Crear Nuevo Cliente"}
      </Typography>
      <TextField
        fullWidth
        label="Nombre del Cliente"
        name="name"
        value={client.name}
        onChange={handleChange}
        margin="normal"
        required
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Guardar Cliente
      </Button>
    </Box>
  );
};

export default ClientForm;
