import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import clientService from "../../services/clientService";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await clientService.getClients();
        setClients(data);
      } catch (error) {
        setError(
          error.response?.data?.message || "Error al obtener los clientes"
        );
        console.error("Error al obtener los clientes:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 1200, margin: "24px auto", p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Clientes
      </Typography>
      <Button
        component={Link}
        to="/clients/new"
        variant="contained"
        color="primary"
        sx={{ mb: 3 }}
      >
        Crear Nuevo Cliente
      </Button>
      {clients.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
          No se encontraron clientes. ¡Crea tu primer cliente!
        </Typography>
      ) : (
        <List>
          {clients
            .sort((a, b) => a.id - b.id)
            .map((client) => (
              <ListItem
                key={client.id}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  mb: 2,
                  p: 2,
                  backgroundColor: "background.paper",
                  "&:hover": {
                    backgroundColor: "action.hover",
                    transition: "background-color 0.3s",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography variant="h6" component="span">
                        #{client.id}
                      </Typography>
                      <Typography variant="h6" component="span">
                        {client.name}
                      </Typography>
                    </Box>
                  }
                  secondary={`Total de Correos Enviados: ${
                    client.emails_sent || 0
                  }`}
                />
                <Button
                  component={Link}
                  to={`/clients/${client.id}`}
                  variant="outlined"
                  color="primary"
                >
                  Ver Detalles
                </Button>
              </ListItem>
            ))}
        </List>
      )}
    </Box>
  );
};

export default ClientList;
