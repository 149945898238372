import axios from "axios";
//dev
//const API_URL = "http://localhost:7000/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";
const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const emailCredentialService = {
  getEmailCredentials: async (clientId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/email-credentials`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching email credentials:", error);
      throw error;
    }
  },

  getEmailCredential: async (clientId, credentialId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/email-credentials/${credentialId}`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching email credential:", error);
      throw error;
    }
  },

  createEmailCredential: async (clientId, credentialData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/email-credentials`,
        credentialData,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating email credential:", error);
      throw error;
    }
  },

  updateEmailCredential: async (clientId, credentialId, credentialData) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/email-credentials/${credentialId}`,
        credentialData,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating email credential:", error);
      throw error;
    }
  },
};

export default emailCredentialService;
