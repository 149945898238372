import React, { useRef, useEffect, useState, useCallback } from "react";
import { Editor } from "@tinymce/tinymce-react";
import processPreviewContent from "../../utils/previewContentProcessor";

const RichTextEditor = ({ value, onChange, clientId }) => {
  const editorRef = useRef(null);
  const [content, setContent] = useState(value || "");
  const isProcessing = useRef(false);

  const processImages = useCallback(
    async (htmlContent) => {
      if (isProcessing.current) return htmlContent;

      try {
        isProcessing.current = true;
        const processedContent = await processPreviewContent(
          htmlContent,
          clientId
        );
        return processedContent;
      } catch (error) {
        console.error("Error processing images:", error);
        return htmlContent;
      } finally {
        isProcessing.current = false;
      }
    },
    [clientId]
  );

  useEffect(() => {
    const loadContent = async () => {
      try {
        if (value !== content) {
          const processedContent = value ? await processImages(value) : "";
          setContent(processedContent);
          if (editorRef.current) {
            editorRef.current.setContent(processedContent);
          }
        }
      } catch (error) {
        console.error("Error in loadContent:", error);
      }
    };

    loadContent();
  }, [value, clientId, processImages, content]);

  return (
    <Editor
      apiKey="kpt3oowrhdgikx9wbpg086s2flaoo3dbmavsesiq0wfhdklz"
      onInit={(evt, editor) => {
        editorRef.current = editor;
        if (value) {
          editor.setContent(content);
        }
      }}
      value={content}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "help",
          "wordcount",
          "emoticons",
        ],
        toolbar: [
          "undo redo | formatselect | bold italic underline strikethrough | forecolor backcolor",
          "alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent",
          "link image media table emoticons | removeformat code fullscreen help",
        ],
        language: "es",
        paste_retain_style_properties: "all",
        paste_word_valid_elements: "p,strong,b,em,ul,ol,li,h1,h2,h3,h4,h5,h6",
        paste_enable_default_filters: true,
        paste_webkit_styles: "none",
        paste_merge_formats: true,
        paste_tab_spaces: 4,
        paste_data_images: true,
        formats: {
          bold: { inline: "strong" },
          italic: { inline: "em" },
        },
        content_style: `
          body { 
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
            font-size: 14px;
            line-height: 1.5;
          }
          p { 
            margin: 0 0 1em 0;
          }
          ul, ol { 
            margin: 0 0 1em 0;
            padding: 0 0 0 2em;
            list-style-position: outside;
          }
          li { 
            margin: 0.5em 0;
            padding: 0;
          }
          strong, b { 
            font-weight: bold;
          }
        `,
        paste_postprocess: function (plugin, args) {
          const formatLists = (node) => {
            // Formatear todas las listas
            node.querySelectorAll("ul, ol").forEach((list) => {
              // Limpiar todos los atributos y estilos de la lista
              Array.from(list.attributes).forEach((attr) =>
                list.removeAttribute(attr.name)
              );

              // Aplicar estilos base para listas
              list.style.margin = "0 0 1em 0";
              list.style.padding = "0 0 0 2em";
              list.style.listStylePosition = "outside";
            });

            // Formatear todos los elementos de lista
            node.querySelectorAll("li").forEach((li) => {
              // Preservar el contenido original incluyendo negritas
              const originalContent = li.innerHTML;

              // Limpiar todos los atributos y estilos del li
              Array.from(li.attributes).forEach((attr) =>
                li.removeAttribute(attr.name)
              );

              // Aplicar estilos base para elementos de lista
              li.style.margin = "0.5em 0";
              li.style.padding = "0";

              // Restaurar el contenido original
              li.innerHTML = originalContent;
            });

            // Asegurar que los párrafos dentro de las listas tengan el formato correcto
            node.querySelectorAll("li p").forEach((p) => {
              const originalContent = p.innerHTML;
              Array.from(p.attributes).forEach((attr) =>
                p.removeAttribute(attr.name)
              );
              p.style.margin = "0";
              p.style.padding = "0";
              p.innerHTML = originalContent;
            });
          };

          formatLists(args.node);
        },
        automatic_uploads: true,
        images_upload_handler: async (blobInfo, progress) => {
          try {
            const reader = new FileReader();
            return new Promise((resolve) => {
              reader.onload = () => resolve(reader.result);
              reader.readAsDataURL(blobInfo.blob());
            });
          } catch (error) {
            console.error("Error processing image:", error);
            return Promise.reject("Error processing image");
          }
        },
        file_picker_types: "image",
        convert_urls: false,
        setup: (editor) => {
          editor.on("init", () => {
            if (!value) {
              editor.setContent("");
            }
          });

          editor.on("BeforeSetContent", async (e) => {
            if (
              e.content &&
              e.content.includes("cid:") &&
              !isProcessing.current
            ) {
              try {
                const processed = await processImages(e.content);
                e.content = processed;
              } catch (error) {
                console.error("Error in BeforeSetContent:", error);
              }
            }
          });

          editor.on("LoadContent", async (e) => {
            const currentContent = editor.getContent();
            if (
              currentContent &&
              currentContent.includes("cid:") &&
              !isProcessing.current
            ) {
              try {
                const processed = await processImages(currentContent);
                editor.setContent(processed, { no_events: true });
              } catch (error) {
                console.error("Error in LoadContent:", error);
              }
            }
          });
        },
        paste_preprocess: function (plugin, args) {
          const div = document.createElement("div");
          div.innerHTML = args.content;

          const processBoldText = (node) => {
            if (node.nodeType === 3) return;

            const isBold = (element) => {
              const style = element.getAttribute("style") || "";
              const weight = element.style.fontWeight;
              return (
                element.tagName === "STRONG" ||
                element.tagName === "B" ||
                style.includes("font-weight: bold") ||
                style.includes("font-weight:bold") ||
                weight === "bold" ||
                parseInt(weight) >= 700
              );
            };

            if (isBold(node)) {
              const strong = document.createElement("strong");
              strong.innerHTML = node.innerHTML;
              if (node.parentNode) {
                node.parentNode.replaceChild(strong, node);
              }
              return;
            }

            if (node.tagName === "UL" || node.tagName === "OL") {
              node.removeAttribute("style");
              node.removeAttribute("class");
            } else if (node.tagName === "LI") {
              const content = node.innerHTML;
              node.removeAttribute("style");
              node.removeAttribute("class");
              node.innerHTML = content;
            }

            Array.from(node.children).forEach(processBoldText);
          };

          processBoldText(div);
          args.content = div.innerHTML;
        },
        paste_as_text: false,
        paste_remove_styles_if_webkit: false,
        paste_remove_styles: false,
        paste_strip_class_attributes: "none",
        valid_elements:
          "p,strong,em,span,ul,ol,li,h1,h2,h3,h4,h5,h6,a[href],img[src],br",
        extended_valid_elements: "strong,em,span[*],p[*],div[*]",
      }}
      onEditorChange={(newContent, editor) => {
        if (newContent !== content && !isProcessing.current) {
          setContent(newContent);
          onChange(newContent);
        }
      }}
    />
  );
};

export default RichTextEditor;
