import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAuth } from "../../contexts/AuthContext";
import KeyIcon from "@mui/icons-material/Key";
import { Link } from "react-router-dom";
import authService from "../../services/authService";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRotated, setIsRotated] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userData = authService.getUserData();
  const isAdmin = userData?.is_admin;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsRotated(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsRotated(false);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate("/login");
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#2c3e50",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "70px",
          px: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            color: "white",
            textDecoration: "none",
            fontWeight: 600,
            fontSize: "1.5rem",
            "&:hover": {
              color: "#ecf0f1",
            },
          }}
        >
          Gestor de Leads LemonAI
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {isAdmin && (
            <Button
              component={Link}
              to="/keywords"
              startIcon={<KeyIcon />}
              sx={{
                color: "white",
                textTransform: "none",
                fontSize: "1rem",
                padding: "8px 16px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              Subverticales
            </Button>
          )}

          <IconButton
            size="large"
            aria-label="ajustes de cuenta"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{
              color: "white",
              transition: "all 0.3s ease-in-out",
              transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
          >
            <SettingsIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                mt: 1,
              },
            }}
          >
            <MenuItem
              onClick={handleLogout}
              sx={{
                py: 1.5,
                px: 3,
                "&:hover": {
                  backgroundColor: "#f5f6fa",
                },
              }}
            >
              Cerrar Sesión
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
