import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Chip,
  Typography,
  Slider,
  Alert,
} from "@mui/material";
import keywordService from "../../services/keywordService";
import AddIcon from "@mui/icons-material/Add";

const VerticalForm = ({ open, onClose, onSubmit, initialData, clientId }) => {
  const [formData, setFormData] = useState({
    name_vertical: "",
    keywords: [],
    percentage_decissors: 0,
  });
  const [openKeywordSelector, setOpenKeywordSelector] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (initialData) {
      setFormData({
        name_vertical: initialData.name_vertical || "",
        keywords: initialData.keywords || [],
        percentage_decissors: initialData.percentage_decissors || 0,
      });
      setSelectedKeywords(initialData.keywords || []);
    } else {
      setFormData({
        name_vertical: "",
        keywords: [],
        percentage_decissors: 0,
      });
      setSelectedKeywords([]);
    }
  }, [initialData]);

  useEffect(() => {
    const fetchKeywords = async () => {
      if (open) {
        try {
          const keywords = await keywordService.getKeywords();
          setAvailableKeywords(keywords);
        } catch (error) {
          console.error("Error al obtener palabras clave:", error);
        }
      }
    };
    fetchKeywords();
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formData);
      setError(null);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCloseKeywordSelector = () => {
    setOpenKeywordSelector(false);
    setSearchText("");
  };

  const handleToggleKeyword = (keywordId) => {
    setSelectedKeywords((prev) => {
      const newSelection = prev.includes(keywordId)
        ? prev.filter((id) => id !== keywordId)
        : [...prev, keywordId];

      setFormData((prevData) => ({
        ...prevData,
        keywords: newSelection,
      }));

      return newSelection;
    });
  };

  const filteredKeywords = availableKeywords.filter((keyword) =>
    keyword.text_keyword.toLowerCase().includes(searchText.toLowerCase())
  );

  const selectedKeywordsData = availableKeywords.filter((keyword) =>
    selectedKeywords.includes(keyword.id)
  );

  const handlePercentageChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      percentage_decissors: newValue,
    }));
  };

  const handleInputChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    if (value === "" || (value >= 0 && value <= 100)) {
      setFormData((prev) => ({
        ...prev,
        percentage_decissors: value,
      }));
    }
  };

  const handleBlur = () => {
    if (formData.percentage_decissors === "") {
      setFormData((prev) => ({
        ...prev,
        percentage_decissors: 0,
      }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ py: 1, fontSize: "1rem" }}>
          {initialData ? "Editar Vertical" : "Nuevo Vertical"}
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Box>
            <TextField
              fullWidth
              label="Nombre del Vertical"
              name="name_vertical"
              value={formData.name_vertical}
              onChange={handleChange}
              required
              size="small"
              sx={{
                mb: 3,
                mt: 2,
                "& .MuiInputLabel-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem",
                },
              }}
            />

            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Porcentaje de decisores
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Slider
                  value={
                    typeof formData.percentage_decissors === "number"
                      ? formData.percentage_decissors
                      : 0
                  }
                  onChange={handlePercentageChange}
                  min={0}
                  max={100}
                  step={1}
                  sx={{
                    flexGrow: 1,
                    height: 4,
                    "& .MuiSlider-rail": {
                      opacity: 0.8,
                      backgroundColor: "#e0e0e0",
                    },
                    "& .MuiSlider-track": {
                      border: "none",
                      backgroundColor: "#1976d2",
                    },
                    "& .MuiSlider-thumb": {
                      height: 16,
                      width: 16,
                      backgroundColor: "#fff",
                      border: "2px solid #1976d2",
                      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                        boxShadow: "inherit",
                      },
                      "&:before": {
                        display: "none",
                      },
                    },
                    "& .MuiSlider-valueLabel": {
                      lineHeight: 1.2,
                      fontSize: 12,
                      background: "unset",
                      padding: 0,
                      width: 32,
                      height: 32,
                      borderRadius: "50% 50% 50% 0",
                      backgroundColor: "#1976d2",
                      transformOrigin: "bottom left",
                      transform:
                        "translate(50%, -100%) rotate(-45deg) scale(0)",
                      "&:before": { display: "none" },
                      "&.MuiSlider-valueLabelOpen": {
                        transform:
                          "translate(50%, -100%) rotate(-45deg) scale(1)",
                      },
                      "& > *": {
                        transform: "rotate(45deg)",
                      },
                    },
                  }}
                />
                <TextField
                  value={formData.percentage_decissors}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          ml: 0.5,
                          color: "text.secondary",
                          fontSize: "0.875rem",
                        }}
                      >
                        %
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 1,
                  }}
                  sx={{
                    width: "80px",
                    "& .MuiOutlinedInput-root": {
                      height: 32,
                    },
                    "& input": {
                      textAlign: "right",
                      paddingRight: "8px",
                      fontSize: "0.875rem",
                      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                        {
                          display: "none",
                        },
                    },
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Palabras clave asignadas
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                {selectedKeywordsData.map((keyword) => (
                  <Chip
                    key={keyword.id}
                    label={keyword.text_keyword}
                    onDelete={() => handleToggleKeyword(keyword.id)}
                    size="small"
                    sx={{
                      fontSize: "0.75rem",
                      "& .MuiChip-deleteIcon": {
                        fontSize: "1rem",
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            py: 2,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Button
            onClick={onClose}
            size="small"
            sx={{
              fontSize: "0.875rem",
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            sx={{
              fontSize: "0.875rem",
              textTransform: "none",
            }}
          >
            {initialData ? "Actualizar" : "Crear"}
          </Button>
        </DialogActions>
      </form>

      {/* Keywords Selector Modal */}
      <Dialog
        open={openKeywordSelector}
        onClose={handleCloseKeywordSelector}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ py: 1, fontSize: "1rem" }}>
          Seleccionar Palabras Clave
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            label="Buscar palabras clave"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              mb: 2,
              mt: 1,
              "& .MuiInputLabel-root": {
                fontSize: "0.875rem",
              },
              "& .MuiInputBase-input": {
                fontSize: "0.875rem",
              },
            }}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {filteredKeywords.map((keyword) => (
              <Chip
                key={keyword.id}
                label={keyword.text_keyword}
                onClick={() => handleToggleKeyword(keyword.id)}
                color={
                  selectedKeywords.includes(keyword.id) ? "primary" : "default"
                }
                variant={
                  selectedKeywords.includes(keyword.id) ? "filled" : "outlined"
                }
                size="small"
                sx={{
                  fontSize: "0.75rem",
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            py: 2,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Button
            onClick={handleCloseKeywordSelector}
            size="small"
            sx={{
              fontSize: "0.875rem",
              textTransform: "none",
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default VerticalForm;
