import axios from "axios";
//dev
//const API_URL = "http://localhost:7000/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";
const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const campaignService = {
  getCampaigns: async (clientId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  getCampaign: async (clientId, campaignId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  createCampaign: async (clientId, campaignData) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns`,
      campaignData,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  updateCampaign: async (clientId, campaignId, campaignData) => {
    const response = await axios.put(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}`,
      campaignData,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  getCampaignTemplates: async (clientId, campaignId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching templates:", error);
      throw error;
    }
  },

  getCampaignTemplate: async (clientId, campaignId, templateId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  createCampaignTemplate: async (clientId, campaignId, templateData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates`,
        templateData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating template:", error);
      throw error;
    }
  },

  updateCampaignTemplate: async (
    clientId,
    campaignId,
    templateId,
    templateData
  ) => {
    const response = await axios.put(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}`,
      templateData,
      { headers: getAuthHeader() }
    );
    return response.data;
  },
};

export default campaignService;
