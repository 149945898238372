import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box } from "@mui/material";
import clientService from "../../services/clientService";

const ClientForm = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState({ name: "" });

  useEffect(() => {
    if (clientId) {
      const fetchClient = async () => {
        try {
          const data = await clientService.getClient(clientId);
          setClient(data);
        } catch (error) {
          console.error("Error al obtener cliente:", error);
        }
      };
      fetchClient();
    }
  }, [clientId]);

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (clientId) {
        await clientService.updateClient(clientId, client);
      } else {
        await clientService.createClient(client);
      }
      navigate("/clients");
    } catch (error) {
      console.error("Error al guardar cliente:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        margin: "auto",
        p: 2,
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{
          fontSize: "1.15rem",
          fontWeight: 500,
        }}
      >
        {clientId ? "Editar Cliente" : "Crear Nuevo Cliente"}
      </Typography>

      <TextField
        fullWidth
        label="Nombre del Cliente"
        name="name"
        value={client.name}
        onChange={handleChange}
        margin="normal"
        required
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            height: "40px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, 8px) scale(0.85)",
            "&.Mui-focused, &.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.7)",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "8px 14px",
            fontSize: "0.875rem",
          },
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="small"
        sx={{
          py: 0.5,
          px: 2,
          fontSize: "0.875rem",
          minWidth: "auto",
        }}
      >
        Guardar Cliente
      </Button>
    </Box>
  );
};

export default ClientForm;
