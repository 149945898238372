import axios from "axios";
//dev
//const API_URL = "http://localhost:7000/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const authService = {
  login: async (username, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });
      if (response.data.access_token) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("tokenExpiration", expirationDate.toISOString());
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        return response.data;
      }
      throw new Error("No token received");
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  },

  getUserData: () => {
    const userData = localStorage.getItem("userData");
    return userData ? JSON.parse(userData) : null;
  },

  isAdmin: () => {
    const userData = authService.getUserData();
    return userData ? userData.is_admin : false;
  },

  isTokenValid: () => {
    const token = localStorage.getItem("token");
    const expiration = localStorage.getItem("tokenExpiration");

    if (!token || !expiration) return false;

    const expirationDate = new Date(expiration);
    return expirationDate > new Date();
  },

  verifyToken: async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/verify-token`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.user;
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        throw error;
      }
    }
    return null;
  },

  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
  },

  getProfile: async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching profile:", error);
        throw error;
      }
    }
    throw new Error("No token found");
  },
};

export default authService;
