import { useState, useEffect, useRef } from "react";
import clientService from "../services/clientService";
import campaignService from "../services/campaignService";

export const useBreadcrumbNames = (pathnames) => {
  const [names, setNames] = useState({
    clients: {},
    campaigns: {},
    templates: {},
  });
  const previousPathRef = useRef();

  useEffect(() => {
    const currentPathString = JSON.stringify(pathnames);
    if (previousPathRef.current === currentPathString) {
      return;
    }
    previousPathRef.current = currentPathString;

    const fetchNames = async () => {
      const newNames = {
        clients: {},
        campaigns: {},
        templates: {},
      };
      let clientId, campaignId;

      for (let i = 0; i < pathnames.length; i++) {
        const current = pathnames[i];
        const prevSegment = pathnames[i - 1];

        if (prevSegment === "clients" && !isNaN(current)) {
          clientId = current;
          try {
            const client = await clientService.getClient(current);
            newNames.clients[current] = client.name;
          } catch (error) {
            console.error("Error fetching client name:", error);
          }
        }

        if (prevSegment === "campaigns" && !isNaN(current)) {
          campaignId = current;
          try {
            const campaign = await campaignService.getCampaign(
              clientId,
              current
            );
            newNames.campaigns[current] = campaign.name_campaign;
          } catch (error) {
            console.error("Error fetching campaign name:", error);
          }
        }

        if (prevSegment === "templates" && !isNaN(current)) {
          try {
            const template = await campaignService.getCampaignTemplate(
              clientId,
              campaignId,
              current
            );
            newNames.templates[current] = template.subject;
          } catch (error) {
            console.error("Error fetching template name:", error);
          }
        }
      }

      setNames((prevNames) => ({
        clients: { ...prevNames.clients, ...newNames.clients },
        campaigns: { ...prevNames.campaigns, ...newNames.campaigns },
        templates: { ...prevNames.templates, ...newNames.templates },
      }));
    };

    fetchNames();
  }, [pathnames]);

  return names;
};
