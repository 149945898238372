import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import keywordService from "../../services/keywordService";

const KeywordExtraDialog = ({ open, onClose, onSubmit }) => {
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        setLoading(true);
        const data = await keywordService.getKeywords();
        setKeywords(data);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchKeywords();
    }
  }, [open]);

  const handleSubmit = () => {
    if (selectedKeyword) {
      onSubmit(selectedKeyword.id);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ py: 1.5, fontSize: "1rem" }}>
        Añadir Keyword Extra
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Autocomplete
          options={keywords}
          getOptionLabel={(option) => option.text_keyword}
          value={selectedKeyword}
          onChange={(_, newValue) => setSelectedKeyword(newValue)}
          loading={loading}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccionar keyword"
              variant="outlined"
              margin="normal"
              sx={{
                "& .MuiInputLabel-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem",
                },
              }}
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: "200px",
            },
          }}
          sx={{
            "& .MuiAutocomplete-listbox": {
              "& .MuiAutocomplete-option": {
                minHeight: "auto",
                padding: "4px 8px",
                fontSize: "0.875rem",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, borderTop: "1px solid #e0e0e0" }}>
        <Button
          onClick={onClose}
          size="small"
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!selectedKeyword}
          size="small"
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordExtraDialog;
