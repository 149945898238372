import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import clientService from "../../services/clientService";

const ClientList = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const fetchClients = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await clientService.getClients();
      setClients(data);
    } catch (error) {
      setError(
        error.response?.data?.message || "Error al obtener los clientes"
      );
      console.error("Error al obtener los clientes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleToggleStatus = (client, event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedClient(client);
    setIsDeactivating(!client.datetime_inactive);
    setDialogOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      if (isDeactivating) {
        await clientService.deactivateClient(selectedClient.id);
      } else {
        await clientService.activateClient(selectedClient.id);
      }
      await fetchClients();
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating client status:", error);
      setError("Error al actualizar el estado del cliente");
    }
  };

  const navigateToClient = (e, clientId) => {
    if (e.target.tagName !== "INPUT") {
      // No navegar si se hace click en el Switch
      navigate(`/clients/${clientId}`);
    }
  };

  const filteredClients = showActiveOnly
    ? clients.filter((client) => !client.datetime_inactive)
    : clients;

  const getLastEmailColor = (lastEmailDate) => {
    if (!lastEmailDate) return undefined;
    const lastEmail = new Date(lastEmailDate);
    const now = new Date();
    const diffDays = Math.floor((now - lastEmail) / (1000 * 60 * 60 * 24));
    return diffDays > 3 ? "red" : undefined;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 1200, margin: "24px auto", p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Clientes
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Button
          component={Link}
          to="/clients/new"
          variant="contained"
          color="primary"
          sx={{
            py: 0.5,
            fontSize: "0.875rem",
          }}
        >
          Crear Nuevo Cliente
        </Button>

        <FormControlLabel
          control={
            <Checkbox
              checked={showActiveOnly}
              onChange={(e) => setShowActiveOnly(e.target.checked)}
              size="small"
            />
          }
          label={
            <Typography sx={{ fontSize: "0.875rem" }}>
              Mostrar solo clientes activos
            </Typography>
          }
        />
      </Box>

      {filteredClients.length === 0 ? (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
          No se encontraron clientes. ¡Crea tu primer cliente!
        </Typography>
      ) : (
        <List sx={{ p: 0 }}>
          {filteredClients
            .sort((a, b) => a.id - b.id)
            .map((client) => (
              <ListItem
                key={client.id}
                onClick={(e) => navigateToClient(e, client.id)}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  mb: 1,
                  py: 1,
                  px: 2,
                  backgroundColor: "background.paper",
                  color: "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "action.hover",
                    transition: "background-color 0.3s",
                  },
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      "& > *": { flexShrink: 0 },
                    }}
                  >
                    <Typography component="span" sx={{ fontSize: "0.875rem" }}>
                      #{client.id}
                    </Typography>
                    <Typography component="span" sx={{ fontSize: "0.875rem" }}>
                      {client.name}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 0.5 }}>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "0.75rem",
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <span>
                        Total de Correos Enviados: {client.emails_sent || 0}
                      </span>
                      <span>•</span>
                      <span style={{ color: "blue" }}>
                        Últimas 24h: {client.emails_sent_24h || 0}
                      </span>
                      <span>•</span>
                      <span style={{ color: "green" }}>
                        Respuestas Interesadas: {client.interested_count || 0}
                      </span>
                      <span>•</span>
                      {client.last_email_date && (
                        <>
                          <span
                            style={{
                              color: getLastEmailColor(client.last_email_date),
                            }}
                          >
                            Último email:{" "}
                            {new Date(
                              client.last_email_date
                            ).toLocaleDateString()}
                          </span>
                          <span>•</span>
                        </>
                      )}
                      <span
                        style={{
                          backgroundColor: client.datetime_inactive
                            ? "#ffebee"
                            : "#e8f5e9",
                          color: client.datetime_inactive
                            ? "#c62828"
                            : "#2e7d32",
                          padding: "2px 8px",
                          borderRadius: "12px",
                          fontSize: "0.75rem",
                          fontWeight: 500,
                          display: "inline-block",
                          border: `1px solid ${
                            client.datetime_inactive ? "#ef9a9a" : "#a5d6a7"
                          }`,
                        }}
                      >
                        {client.datetime_inactive ? "Inactivo" : "Activo"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    mt: { xs: 1, sm: 0 },
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                  }}
                >
                  <Switch
                    checked={!client.datetime_inactive}
                    onChange={(e) => handleToggleStatus(client, e)}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  <Button
                    component={Link}
                    to={`/clients/${client.id}`}
                    variant="outlined"
                    size="small"
                    sx={{
                      py: 0.25,
                      px: 1,
                      fontSize: "0.75rem",
                      minWidth: "auto",
                    }}
                  >
                    Ver Detalles
                  </Button>
                  <Button
                    component={Link}
                    to={`/clients/${client.id}/stats`}
                    variant="outlined"
                    size="small"
                    color="info"
                    sx={{
                      py: 0.25,
                      px: 1,
                      fontSize: "0.75rem",
                      minWidth: "auto",
                    }}
                  >
                    Estadísticas
                  </Button>
                </Box>
              </ListItem>
            ))}
        </List>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ py: 1, fontSize: "1rem" }}>
          Confirmar acción
        </DialogTitle>
        <DialogContent>
          {selectedClient && (
            <Typography sx={{ fontSize: "0.875rem" }}>
              {isDeactivating
                ? `¿Quieres desactivar el cliente "${selectedClient.name}"?`
                : `¿Quieres activar el cliente "${selectedClient.name}"?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            size="small"
            sx={{ fontSize: "0.75rem" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmStatusChange}
            color="primary"
            size="small"
            sx={{ fontSize: "0.75rem" }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClientList;
