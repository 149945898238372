import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import RichTextEditor from "../common/RichTextEditor";
import positionService from "../../services/positionService";
import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import emailCredentialService from "../../services/emailCredentialService";

const CampaignForm = () => {
  const { clientId, campaignId } = useParams();
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [campaign, setCampaign] = useState({
    name_campaign: "",
    datetime_start: "",
    num_emails_per_day: 0,
    days_week_send_email: [],
    hours_day_send_email: [],
    keywords_to_exclude: [],
    text_closing: "",
    location_ids: [],
    id_email: "",
  });
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [openPositionSelector, setOpenPositionSelector] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [locations, setLocations] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchTimeout = useRef(null);
  const [emails, setEmails] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState("all");
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const positionsData = await positionService.getPositions();
        setPositions(positionsData);
      } catch (error) {
        console.error("Error al obtener posiciones:", error);
      }
    };
    fetchPositions();
  }, []);

  useEffect(() => {
    if (campaignId) {
      const fetchCampaign = async () => {
        try {
          const campaignData = await campaignService.getCampaign(
            clientId,
            campaignId
          );
          setCampaign({
            ...campaignData,
            id_email: campaignData.id_email || "",
            position_ids: campaignData.position_ids || [],
            location_ids: campaignData.location_ids || [],
          });
          setSelectedPositions(campaignData.position_ids || []);
        } catch (error) {
          console.error("Error al obtener la campaña:", error);
          setNotification({
            open: true,
            message: "Error al obtener la campaña",
            severity: "error",
          });
        }
      };
      fetchCampaign();
    }
  }, [campaignId, clientId]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsData = await campaignService.getLocations();
        setLocations(locationsData);
        setAllLocations(locationsData);
      } catch (error) {
        console.error("Error al obtener ubicaciones:", error);
        setNotification({
          open: true,
          message: "Error al obtener ubicaciones",
          severity: "error",
        });
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const emailsData = await emailCredentialService.getEmailCredentials(
          clientId
        );
        setEmails(emailsData);

        if (emailsData.length === 0) {
          navigate(`/clients/${clientId}/email-credentials`);
        }
      } catch (error) {
        console.error("Error al obtener emails:", error);
      }
    };
    fetchEmails();
  }, [clientId, navigate]);

  const handleChange = (field, value) => {
    setCampaign((prev) => ({ ...prev, [field]: value }));
  };

  const handleMultiSelectChange = (e, field) => {
    setCampaign((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const campaignData = {
        ...campaign,
        position_ids: selectedPositions,
        location_ids: campaign.location_ids,
        id_email: campaign.id_email === "" ? null : campaign.id_email,
      };

      if (campaignId) {
        await campaignService.updateCampaign(
          clientId,
          campaignId,
          campaignData
        );
        setNotification({
          open: true,
          message: "Campaña actualizada correctamente",
          severity: "success",
        });
        navigate(`/clients/${clientId}`);
      } else {
        await campaignService.createCampaign(clientId, campaignData);
        setNotification({
          open: true,
          message: "Campaña creada correctamente",
          severity: "success",
        });
        navigate(`/clients/${clientId}/campaigns`);
      }
    } catch (error) {
      console.error("Error al guardar la campaña:", error);
      setNotification({
        open: true,
        message: error.response?.data?.error || "Error al guardar la campaña",
        severity: "error",
      });
    }
  };

  const handleTogglePosition = (positionId) => {
    setSelectedPositions((prev) =>
      prev.includes(positionId)
        ? prev.filter((id) => id !== positionId)
        : [...prev, positionId]
    );
  };

  const filteredPositions = positions.filter((position) =>
    position.name_position.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleLocationSearch = async (searchTerm) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (!searchTerm || searchTerm.length < 2) {
      setLocationOptions([]);
      return;
    }

    searchTimeout.current = setTimeout(async () => {
      setIsLoading(true);
      try {
        const results = await campaignService.searchLocations(searchTerm);
        const filteredResults =
          selectedLocationType === "all"
            ? results
            : results.filter(
                (loc) => loc.type_location === selectedLocationType
              );
        setLocationOptions(filteredResults);
      } catch (error) {
        console.error("Error searching locations:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, margin: "auto", p: 2 }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {campaignId ? "Editar Campaña" : "Crear Nueva Campaña"}
      </Typography>

      {/* Campos básicos */}
      <TextField
        fullWidth
        label="Nombre de la Campaña"
        value={campaign.name_campaign}
        onChange={(e) => handleChange("name_campaign", e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        type="number"
        label="Emails por día"
        value={campaign.num_emails_per_day}
        onChange={(e) =>
          handleChange("num_emails_per_day", parseInt(e.target.value))
        }
        margin="normal"
        required
      />

      {/* Selectores múltiples */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Días de la semana para enviar</InputLabel>
        <Select
          multiple
          value={campaign.days_week_send_email}
          onChange={(e) => handleMultiSelectChange(e, "days_week_send_email")}
          renderValue={(selected) => selected.join(", ")}
        >
          {[1, 2, 3, 4, 5, 6, 7].map((day) => (
            <MenuItem key={day} value={day}>
              {
                [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                  "Domingo",
                ][day - 1]
              }
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Mover la sección de posiciones aquí, antes de los editores de texto */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Posiciones Objetivo
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1.5 }}>
          {selectedPositions.map((positionId) => {
            const position = positions.find((p) => p.id === positionId);
            return (
              position && (
                <Chip
                  key={position.id}
                  label={position.name_position}
                  onDelete={() => handleTogglePosition(position.id)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              )
            );
          })}
        </Box>
        <Button
          variant="outlined"
          onClick={() => setOpenPositionSelector(true)}
          startIcon={<AddIcon />}
          size="small"
        >
          Añadir Posiciones
        </Button>
      </Box>

      {/* Move the Autocomplete here, just after the positions */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Localizaciones
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Tipo de Localización</InputLabel>
            <Select
              value={selectedLocationType}
              onChange={(e) => setSelectedLocationType(e.target.value)}
              label="Tipo de Localización"
            >
              <MenuItem value="all">Todas</MenuItem>
              <MenuItem value="Comunidad Autonoma">Comunidad Autónoma</MenuItem>
              <MenuItem value="Pais">País</MenuItem>
              <MenuItem value="Provincia">Provincia</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ flexGrow: 1 }}>
            <Autocomplete
              multiple
              options={locationOptions}
              value={[]}
              inputValue={searchText}
              onInputChange={(_, newInputValue, reason) => {
                setSearchText(newInputValue);
                if (reason === "input" && newInputValue.length >= 2) {
                  handleLocationSearch(newInputValue);
                }
              }}
              onChange={(_, newValue) => {
                const newLocationIds = [...campaign.location_ids];
                const selectedId = newValue[newValue.length - 1]?.id;
                if (selectedId && !newLocationIds.includes(selectedId)) {
                  newLocationIds.push(selectedId);
                }
                setCampaign((prev) => ({
                  ...prev,
                  location_ids: newLocationIds,
                }));
                setLocationOptions([]);
                setSearchText("");
              }}
              onBlur={() => {
                setLocationOptions([]);
                setSearchText("");
              }}
              getOptionLabel={(option) =>
                `${option.name} (${option.type_location})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={(x) => x}
              loading={isLoading}
              freeSolo={false}
              selectOnFocus
              clearOnBlur={false}
              disableCloseOnSelect={false}
              noOptionsText="Escribe para buscar..."
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Buscar localización..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => {
                const { key, ...otherProps } = props;
                return (
                  <li key={key} {...otherProps}>
                    <Box>
                      <Typography variant="body2">{option.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {option.type_location}
                      </Typography>
                    </Box>
                  </li>
                );
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              size="small"
              onClick={() => {
                const filteredLocations =
                  selectedLocationType === "all"
                    ? allLocations
                    : allLocations.filter(
                        (loc) => loc.type_location === selectedLocationType
                      );
                const newLocationIds = [
                  ...new Set([
                    ...campaign.location_ids,
                    ...filteredLocations.map((loc) => loc.id),
                  ]),
                ];
                setCampaign((prev) => ({
                  ...prev,
                  location_ids: newLocationIds,
                }));
              }}
            >
              Seleccionar Todas
            </Button>
            <Button
              size="small"
              onClick={() => {
                if (selectedLocationType === "all") {
                  setCampaign((prev) => ({
                    ...prev,
                    location_ids: [],
                  }));
                } else {
                  const locationIdsToKeep = campaign.location_ids.filter(
                    (id) => {
                      const location = allLocations.find(
                        (loc) => loc.id === id
                      );
                      return (
                        location &&
                        location.type_location !== selectedLocationType
                      );
                    }
                  );
                  setCampaign((prev) => ({
                    ...prev,
                    location_ids: locationIdsToKeep,
                  }));
                }
              }}
            >
              Desmarcar Todas
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.75,
            mt: 1.5,
            minHeight: "48px",
            p: 1,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
            backgroundColor: "#fafafa",
          }}
        >
          {locations
            .filter((loc) => campaign.location_ids.includes(loc.id))
            .map((location) => (
              <Chip
                key={location.id}
                label={`${location.name} (${location.type_location})`}
                onDelete={() => {
                  setCampaign((prev) => ({
                    ...prev,
                    location_ids: prev.location_ids.filter(
                      (id) => id !== location.id
                    ),
                  }));
                }}
                size="small"
                sx={{
                  fontSize: "0.75rem",
                  "& .MuiChip-deleteIcon": {
                    fontSize: "1rem",
                  },
                }}
              />
            ))}
        </Box>
      </Box>

      {/* Move email selector here, before the rich text editor */}
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel>Email para envío</InputLabel>
        <Select
          value={campaign.id_email}
          onChange={(e) =>
            setCampaign({ ...campaign, id_email: e.target.value })
          }
          label="Email para envío"
        >
          {emails.map((email) => (
            <MenuItem key={email.id} value={email.id}>
              {email.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Editores de texto enriquecido */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Textos de la Campaña
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Texto de Cierre
        </Typography>
        <RichTextEditor
          value={campaign.text_closing}
          onChange={(content) => handleChange("text_closing", content)}
          clientId={clientId}
        />
      </Box>

      {/* Modal selector de posiciones */}
      <Dialog
        open={openPositionSelector}
        onClose={() => setOpenPositionSelector(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Seleccionar Posiciones</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder="Buscar posiciones..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {filteredPositions.map((position) => (
              <Chip
                key={position.id}
                label={position.name_position}
                onClick={() => handleTogglePosition(position.id)}
                color={
                  selectedPositions.includes(position.id)
                    ? "primary"
                    : "default"
                }
                variant={
                  selectedPositions.includes(position.id)
                    ? "filled"
                    : "outlined"
                }
                sx={{ cursor: "pointer" }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPositionSelector(false)}
            color="primary"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 2 }}
      >
        {campaignId ? "Actualizar Campaña" : "Crear Campaña"}
      </Button>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CampaignForm;
