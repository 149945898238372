import axios from "axios";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const campaignScheduleService = {
  getSchedules: async (clientId, campaignId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching schedules:", error);
      throw error;
    }
  },

  getSchedule: async (clientId, campaignId, scheduleId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules/${scheduleId}`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching schedule:", error);
      throw error;
    }
  },

  createSchedule: async (clientId, campaignId, scheduleData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules`,
        scheduleData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating schedule:", error);
      throw error;
    }
  },

  updateSchedule: async (clientId, campaignId, scheduleId, scheduleData) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules/${scheduleId}`,
        scheduleData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating schedule:", error);
      throw error;
    }
  },

  deleteSchedule: async (clientId, campaignId, scheduleId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules/${scheduleId}`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting schedule:", error);
      throw error;
    }
  },

  createBulkSchedules: async (clientId, campaignId, schedulesData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/schedules/bulk`,
        schedulesData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating bulk schedules:", error);
      throw error;
    }
  },
};

export default campaignScheduleService;
