import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Chip,
  Typography,
  InputAdornment,
} from "@mui/material";
import keywordService from "../../services/keywordService";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
const VerticalForm = ({ open, onClose, onSubmit, initialData, clientId }) => {
  const [formData, setFormData] = useState({
    name_vertical: "",
    keywords: [],
  });
  const [openKeywordSelector, setOpenKeywordSelector] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  useEffect(() => {
    if (initialData) {
      setFormData({
        name_vertical: initialData.name_vertical || "",
        keywords: initialData.keywords || [],
      });
      setSelectedKeywords(initialData.keywords || []);
    } else {
      setFormData({
        name_vertical: "",
        keywords: [],
      });
      setSelectedKeywords([]);
    }
  }, [initialData]);

  useEffect(() => {
    const fetchKeywords = async () => {
      if (open) {
        try {
          const keywords = await keywordService.getKeywords();
          setAvailableKeywords(keywords);
        } catch (error) {
          console.error("Error al obtener palabras clave:", error);
        }
      }
    };
    fetchKeywords();
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleOpenKeywordSelector = () => {
    setOpenKeywordSelector(true);
    setSearchText("");
  };

  const handleCloseKeywordSelector = () => {
    setOpenKeywordSelector(false);
    setSearchText("");
  };

  const handleToggleKeyword = (keywordId) => {
    setSelectedKeywords((prev) => {
      const newSelection = prev.includes(keywordId)
        ? prev.filter((id) => id !== keywordId)
        : [...prev, keywordId];

      setFormData((prevData) => ({
        ...prevData,
        keywords: newSelection,
      }));

      return newSelection;
    });
  };

  const filteredKeywords = availableKeywords.filter((keyword) =>
    keyword.text_keyword.toLowerCase().includes(searchText.toLowerCase())
  );

  const selectedKeywordsData = availableKeywords.filter((keyword) =>
    selectedKeywords.includes(keyword.id)
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {initialData?.id ? "Editar Vertical" : "Nuevo Vertical"}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Nombre del Vertical"
            name="name_vertical"
            value={formData.name_vertical}
            onChange={handleChange}
            margin="normal"
            required
          />

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Palabras Clave Seleccionadas
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {selectedKeywordsData.map((keyword) => (
                <Chip
                  key={keyword.id}
                  label={keyword.text_keyword}
                  onDelete={() => handleToggleKeyword(keyword.id)}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
            <Button
              variant="outlined"
              onClick={handleOpenKeywordSelector}
              startIcon={<AddIcon />}
            >
              Añadir Palabras Clave
            </Button>
          </Box>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained">
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>

      {/* Keywords Selector Modal */}
      <Dialog
        open={openKeywordSelector}
        onClose={handleCloseKeywordSelector}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Seleccionar Palabras Clave</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder="Buscar palabras clave..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ mb: 2, mt: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {filteredKeywords.map((keyword) => (
              <Chip
                key={keyword.id}
                label={keyword.text_keyword}
                onClick={() => handleToggleKeyword(keyword.id)}
                color={
                  selectedKeywords.includes(keyword.id) ? "primary" : "default"
                }
                variant={
                  selectedKeywords.includes(keyword.id) ? "filled" : "outlined"
                }
                sx={{ cursor: "pointer" }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseKeywordSelector} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default VerticalForm;
