import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import RichTextEditor from "../common/RichTextEditor";
import { processContent } from "../../utils/contentProcessor";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import processPreviewContent from "../../utils/previewContentProcessor";

const CampaignTemplateEditor = () => {
  const { clientId, campaignId, templateId } = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({
    subject: "",
    body: "",
    vertical_ids: [],
    templateType: "presentation",
    num_days_without_response: 0,
    after_rejection: false,
    datetime_start: null,
    attach_presentation_email: false,
    text_personal_connection: "<p>Hola [NOMBRE_DESTINATARIO],</p>",
    text_company_connection: "<p>Hola,</p>",
  });
  const [verticals, setVerticals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newVertical, setNewVertical] = useState({ name_vertical: "" });
  const [openPreview, setOpenPreview] = useState(false);
  const [emailCredential, setEmailCredential] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "error",
    persistent: false,
  });
  const [hasInvalidTags, setHasInvalidTags] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [connectionTab, setConnectionTab] = useState(0);
  const [processedSignature, setProcessedSignature] = useState("");
  const [processedClosing, setProcessedClosing] = useState("");

  const validDirectives = [
    "[NOMBRE_EMPRESA]",
    "[NOMBRE_DESTINATARIO]",
    "[SUBVERTICAL]",
    "[SUBVERTICAL(s)]",
  ];

  // Función para validar directivas en cualquier texto
  const validateDirectives = (text) => {
    const matches = text.match(/\[.*?\]/g) || [];
    return matches.every((match) => validDirectives.includes(match));
  };

  // Función para obtener las directivas inválidas
  const getInvalidDirectives = (text) => {
    const matches = text.match(/\[.*?\]/g) || [];
    return matches.filter((match) => !validDirectives.includes(match));
  };

  const validateContent = (content) => {
    const isBodyValid = validateDirectives(content);
    const isSubjectValid = validateDirectives(template.subject);

    if (!isBodyValid || !isSubjectValid) {
      const invalidInBody = getInvalidDirectives(content);
      const invalidInSubject = getInvalidDirectives(template.subject);

      let errorMessage = "Se han detectado directivas inválidas:\n\n";
      if (invalidInSubject.length > 0) {
        errorMessage += `En el asunto: ${invalidInSubject.join(", ")}\n`;
      }
      if (invalidInBody.length > 0) {
        errorMessage += `En el cuerpo: ${invalidInBody.join(", ")}\n`;
      }
      errorMessage += `\nDirectivas permitidas:\n${validDirectives.join("\n")}`;

      setNotification({
        open: true,
        message: errorMessage,
        severity: "error",
        persistent: true,
      });
    }

    setHasInvalidTags(!isBodyValid || !isSubjectValid);
    return isBodyValid && isSubjectValid;
  };

  const handleChange = (e) => {
    // Para inputs normales
    if (e?.target) {
      const { name, value } = e.target;
      setTemplate((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRichTextChange = (fieldName, value) => {
    setTemplate((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [verticalsData, campaignData] = await Promise.all([
          verticalService.getClientVerticals(clientId),
          campaignService.getCampaign(clientId, campaignId),
        ]);

        setVerticals(verticalsData);
        setCampaign(campaignData);

        if (templateId) {
          const templateData = await campaignService.getCampaignTemplate(
            clientId,
            campaignId,
            templateId
          );
          console.log("Template data received:", templateData);

          // Actualizar el estado con todos los campos
          setTemplate((prevTemplate) => ({
            ...prevTemplate,
            subject: templateData.subject || "",
            body: templateData.body || "",
            vertical_ids: templateData.vertical_ids
              ? templateData.vertical_ids.map((id) => id.toString())
              : [],
            templateType: templateData.after_rejection
              ? "rejection"
              : templateData.num_days_without_response
              ? "followup"
              : "presentation",
            num_days_without_response:
              templateData.num_days_without_response || 0,
            after_rejection: templateData.after_rejection || false,
            datetime_start: templateData.datetime_start
              ? new Date(templateData.datetime_start)
              : null,
            attach_presentation_email:
              templateData.attach_presentation_email || false,
            text_personal_connection:
              templateData.text_personal_connection ||
              "<p>Hola [NOMBRE_DESTINATARIO],</p>",
            text_company_connection:
              templateData.text_company_connection || "<p>Hola,</p>",
          }));

          // Para debug
          console.log("Template state updated with:", {
            body: templateData.body,
            text_personal_connection: templateData.text_personal_connection,
            text_company_connection: templateData.text_company_connection,
          });
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
        setNotification({
          open: true,
          message: "Error al cargar los datos de la plantilla",
          severity: "error",
        });
      }
    };

    fetchData();
  }, [clientId, campaignId, templateId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al obtener credencial de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  useEffect(() => {
    const processAllContent = async () => {
      try {
        // Procesar firma
        if (emailCredential?.signature) {
          const processedSig = await processPreviewContent(
            emailCredential.signature,
            clientId
          );
          setProcessedSignature(processedSig);
        }

        // Procesar texto de cierre
        if (campaign?.text_closing) {
          const processedClose = await processPreviewContent(
            campaign.text_closing,
            clientId
          );
          setProcessedClosing(processedClose);
        }
      } catch (error) {
        console.error("Error processing content:", error);
      }
    };

    processAllContent();
  }, [emailCredential?.signature, campaign?.text_closing, clientId]);

  const handleTemplateTypeChange = (e) => {
    const type = e.target.value;
    setTemplate({
      ...template,
      templateType: type,
      num_days_without_response: type === "followup" ? 0 : null,
      after_rejection: type === "rejection",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateContent(template.body)) {
      setNotification({
        open: true,
        message:
          "No se puede guardar la plantilla mientras existan directivas inválidas en el asunto o cuerpo del email",
        severity: "error",
      });
      return;
    }

    try {
      const processedBody = await processContent(template.body, clientId);

      const templateData = {
        subject: template.subject,
        body: processedBody,
        vertical_ids: template.vertical_ids,
        num_days_without_response:
          template.templateType === "followup"
            ? parseInt(template.num_days_without_response)
            : null,
        after_rejection: template.templateType === "rejection",
        datetime_start: null,
        datetime_end: null,
        attach_presentation_email: template.attach_presentation_email,
        text_personal_connection:
          template.text_personal_connection ||
          "<p>Hola [NOMBRE_DESTINATARIO],</p>",
        text_company_connection:
          template.text_company_connection || "<p>Hola,</p>",
      };

      if (templateId) {
        await campaignService.updateCampaignTemplate(
          clientId,
          campaignId,
          templateId,
          templateData
        );
      } else {
        await campaignService.createCampaignTemplate(
          clientId,
          campaignId,
          templateData
        );
      }
      navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
    } catch (error) {
      console.error("Error al guardar plantilla:", error);
    }
  };

  const handleCreateVertical = async () => {
    try {
      const createdVertical = await verticalService.createClientVertical(
        clientId,
        { name_vertical: newVertical.name_vertical }
      );
      setVerticals([...verticals, createdVertical]);
      setTemplate({
        ...template,
        vertical_ids: [...template.vertical_ids, createdVertical.id],
      });
      setOpenModal(false);
      setNewVertical({ name_vertical: "" });
    } catch (error) {
      console.error("Error al crear vertical:", error);
    }
  };

  const handlePreview = () => {
    if (
      !template?.text_personal_connection &&
      !template?.text_company_connection
    ) {
      setNotification({
        open: true,
        message: "La campaña no tiene textos de conexión configurados",
        severity: "warning",
      });
    }

    console.log("Preview data:", {
      signature: emailCredential?.signature,
      body: template.body,
      textClosing: campaign?.text_closing,
      textPersonalConnection: template?.text_personal_connection,
      textCompanyConnection: template?.text_company_connection,
      subject: template.subject,
      templateId,
    });

    setOpenPreview(true);
  };

  const handleConnectionTabChange = (event, newValue) => {
    setConnectionTab(newValue);
  };

  const previewStyles = {
    whiteSpace: "pre-wrap",
    color: "text.secondary",
    fontStyle: "italic",
  };

  const renderSignaturePreview = () => {
    if (!emailCredential?.signature) return null;

    return (
      <Box sx={{ mb: 1.5 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          Preview de la firma:
        </Typography>
        <Paper variant="outlined">
          <Box sx={{ p: 1.5, maxHeight: "150px", overflow: "auto" }}>
            <Box sx={previewStyles}>
              <div dangerouslySetInnerHTML={{ __html: processedSignature }} />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderClosingPreview = () => {
    if (!campaign?.text_closing) return null;

    return (
      <Box sx={{ mb: 1.5 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          Preview del texto de cierre:
        </Typography>
        <Paper variant="outlined">
          <Box sx={{ p: 1.5, maxHeight: "150px", overflow: "auto" }}>
            <Box sx={previewStyles}>
              <div dangerouslySetInnerHTML={{ __html: processedClosing }} />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 800, margin: "auto", p: 2 }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {templateId ? "Editar Plantilla" : "Crear Plantilla"}
        </Typography>

        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>Tipo de Plantilla</InputLabel>
          <Select
            name="templateType"
            value={template.templateType}
            onChange={handleTemplateTypeChange}
            required
          >
            <MenuItem value="presentation">Presentación</MenuItem>
            <MenuItem value="followup">Seguimiento</MenuItem>
            <MenuItem value="rejection">Rechazo</MenuItem>
          </Select>
        </FormControl>

        {template.templateType === "followup" && (
          <TextField
            fullWidth
            label="Días de espera para seguimiento"
            name="num_days_without_response"
            type="number"
            value={template.num_days_without_response}
            onChange={handleChange}
            margin="dense"
            required
            size="small"
          />
        )}

        <TextField
          fullWidth
          label="Asunto"
          name="subject"
          value={template.subject}
          onChange={handleChange}
          margin="dense"
          required
          size="small"
          error={!validateDirectives(template.subject)}
          helperText={
            !validateDirectives(template.subject)
              ? `Directivas inválidas detectadas. Las directivas permitidas son: ${validDirectives.join(
                  ", "
                )}`
              : ""
          }
        />

        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>Verticales</InputLabel>
          <Select
            multiple
            name="vertical_ids"
            value={template.vertical_ids.map((id) => id.toString())}
            onChange={(e) => {
              const selectedIds = e.target.value;
              setTemplate((prev) => ({
                ...prev,
                vertical_ids: selectedIds,
              }));
            }}
            required
          >
            {verticals.map((vertical) => (
              <MenuItem key={vertical.id} value={vertical.id.toString()}>
                {vertical.name_vertical}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={template.attach_presentation_email}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  attach_presentation_email: e.target.checked,
                })
              }
              size="small"
            />
          }
          label="Adjuntar email de presentación"
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, mb: 1 }}>
            Textos de conexión
          </Typography>

          <Paper variant="outlined" sx={{ mb: 2 }}>
            <Tabs
              value={connectionTab}
              onChange={handleConnectionTabChange}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                fontSize: "0.75rem",
              }}
              variant="fullWidth"
              size="small"
            >
              <Tab
                label="Conexión personal"
                icon={<PersonIcon fontSize="small" />}
                iconPosition="start"
                sx={{ py: 0.5 }}
              />
              <Tab
                label="Conexión empresa"
                icon={<BusinessIcon fontSize="small" />}
                iconPosition="start"
                sx={{ py: 0.5 }}
              />
            </Tabs>

            <Box sx={{ p: 1.5 }}>
              {connectionTab === 0 && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    sx={{ mb: 1 }}
                  >
                    Texto que se utilizará cuando el contacto tiene un nombre
                    específico.
                  </Typography>
                  <RichTextEditor
                    key={`personal-${templateId}`}
                    value={template.text_personal_connection}
                    onChange={(value) =>
                      handleRichTextChange("text_personal_connection", value)
                    }
                    clientId={clientId}
                    height={300}
                  />
                </>
              )}

              {connectionTab === 1 && (
                <>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    sx={{ mb: 1 }}
                  >
                    Texto que se utilizará cuando el contacto es un
                    departamento.
                  </Typography>
                  <RichTextEditor
                    key={`company-${templateId}`}
                    value={template.text_company_connection}
                    onChange={(value) =>
                      handleRichTextChange("text_company_connection", value)
                    }
                    clientId={clientId}
                    height={300}
                  />
                </>
              )}
            </Box>
          </Paper>

          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography variant="subtitle1">Cuerpo del Email</Typography>
            <IconButton
              size="small"
              onClick={() => setOpenHelpModal(true)}
              sx={{ ml: 1 }}
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box sx={{ mb: 3 }}>
            <RichTextEditor
              key={`body-${templateId}`}
              value={template.body}
              onChange={(value) => handleRichTextChange("body", value)}
              clientId={clientId}
            />
          </Box>

          <Divider sx={{ my: 3 }} />

          {renderClosingPreview()}
          {renderSignaturePreview()}
        </Box>

        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            disabled={hasInvalidTags}
          >
            {templateId ? "Actualizar Plantilla" : "Crear Plantilla"}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlePreview}
            size="small"
          >
            Vista Previa
          </Button>
        </Box>

        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Crear Nuevo Vertical</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Nombre del Vertical"
              value={newVertical.name_vertical}
              onChange={(e) =>
                setNewVertical({ name_vertical: e.target.value })
              }
              margin="dense"
              size="small"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} size="small">
              Cancelar
            </Button>
            <Button
              onClick={handleCreateVertical}
              variant="contained"
              size="small"
            >
              Crear
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openHelpModal}
          onClose={() => setOpenHelpModal(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Guía de Directivas para Plantillas de Email</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              Las directivas son etiquetas especiales que se reemplazan
              automáticamente con datos reales cuando se envía el email. Hay dos
              tipos de vista previa disponibles:
            </Typography>

            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Vista de Persona
            </Typography>
            <Typography variant="body2" paragraph>
              Se usa cuando el contacto tiene un nombre específico. Las
              directivas se reemplazan con:
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
              <li>
                [NOMBRE_EMPRESA] → Nombre de la empresa del contacto personal
              </li>
              <li>[NOMBRE_DESTINATARIO] → Nombre del contacto</li>
              <li>[SUBVERTICAL] → Keyword en singular</li>
              <li>
                [SUBVERTICAL(s)] → Keyword en plural o con prefijo (ej: "Empresa
                de...")
              </li>
            </Box>

            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Vista de Empresa
            </Typography>
            <Typography variant="body2" paragraph>
              Se usa cuando el contacto es un departamento. Las directivas se
              reemplazan con:
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
              <li>[NOMBRE_EMPRESA] → Nombre de la empresa del departamento</li>
              <li>[NOMBRE_DESTINATARIO] → Se deja vacío</li>
              <li>[SUBVERTICAL] → Mismo keyword en singular</li>
              <li>[SUBVERTICAL(s)] → Mismo keyword en plural o con prefijo</li>
            </Box>

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
              Nota: Los datos se obtienen de las verticales seleccionadas para
              la plantilla.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHelpModal(false)} size="small">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={template.body || ""}
        textClosing={campaign?.text_closing || ""}
        textPersonalConnection={template.text_personal_connection || ""}
        textCompanyConnection={template.text_company_connection || ""}
        clientId={clientId}
        subject={template.subject}
        templateId={templateId}
      />
      <Snackbar
        open={notification.open}
        autoHideDuration={notification.persistent ? null : 6000}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default CampaignTemplateEditor;
