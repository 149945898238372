import axios from "axios";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const campaignService = {
  getCampaigns: async (clientId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },
  finishCampaign: async (clientId, campaignId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/finish`,
      {},
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  resumeCampaign: async (clientId, campaignId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/resume`,
      {},
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  getCampaign: async (clientId, campaignId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  createCampaign: async (clientId, campaignData) => {
    try {
      // Asegurarse de que id_email sea null si está vacío
      if (campaignData.id_email === "") {
        campaignData.id_email = null;
      }

      const response = await axios.post(
        `${API_URL}/clients/${clientId}/campaigns`,
        campaignData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating campaign:", error);
      throw error;
    }
  },

  updateCampaign: async (clientId, campaignId, campaignData) => {
    try {
      // Asegurarse de que id_email sea null si está vacío
      if (campaignData.id_email === "") {
        campaignData.id_email = null;
      }

      const response = await axios.put(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}`,
        campaignData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating campaign:", error);
      throw error;
    }
  },

  getCampaignTemplates: async (clientId, campaignId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching templates:", error);
      throw error;
    }
  },

  getCampaignTemplate: async (clientId, campaignId, templateId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}`,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  createCampaignTemplate: async (clientId, campaignId, templateData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates`,
        templateData,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating template:", error);
      throw error;
    }
  },

  updateCampaignTemplate: async (
    clientId,
    campaignId,
    templateId,
    templateData
  ) => {
    const response = await axios.put(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}`,
      templateData,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  getCampaignEmailStats: async (clientId, campaignId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/stats`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching campaign email stats:", error);
      throw error;
    }
  },
  getClientActiveCampaignsStats: async (clientId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/active-campaigns/stats`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching client active campaigns stats:", error);
      throw error;
    }
  },
  generateCredentialsReport: async (clientId, startDate, endDate) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/credentials-report`,
        {
          headers: {
            ...getAuthHeader(),
            Accept: "application/pdf",
          },
          params: {
            start_date: startDate,
            end_date: endDate,
          },
          responseType: "blob",
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);

      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = `LemonAI_report_${startDate}_${endDate}.pdf`;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(pdfUrl);

      return true;
    } catch (error) {
      console.error("Error generating credentials report:", error);
      throw error;
    }
  },
  deactivateTemplate: async (clientId, campaignId, templateId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}/deactivate`,
      null,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  activateTemplate: async (clientId, campaignId, templateId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}/activate`,
      null,
      { headers: getAuthHeader() }
    );
    return response.data;
  },

  getLocations: async () => {
    try {
      const response = await axios.get(`${API_URL}/locations`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching locations:", error);
      throw error;
    }
  },

  searchLocations: async (searchTerm) => {
    try {
      const response = await axios.get(`${API_URL}/locations/search`, {
        headers: getAuthHeader(),
        params: { q: searchTerm },
      });
      return response.data;
    } catch (error) {
      console.error("Error searching locations:", error);
      throw error;
    }
  },
  sendTestEmail: async (clientId, campaignId, templateId, data) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}/test-email`,
      data,
      { headers: getAuthHeader() }
    );
    return response.data;
  },
  updateTemplateAttachPresentationEmail: async (
    clientId,
    campaignId,
    templateId,
    attachPresentationEmail
  ) => {
    try {
      // Now we can just send the attach_presentation_email field since the backend handles partial updates
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/campaigns/${campaignId}/templates/${templateId}`,
        { attach_presentation_email: attachPresentationEmail },
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error updating template attach presentation email:",
        error
      );
      throw error;
    }
  },
};

export default campaignService;
