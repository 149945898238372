import axios from "axios";
import { getAuthHeader } from "./clientService";

//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const exclusionService = {
  uploadFile: async (clientId, formData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/exclusions/upload`,
        formData,
        {
          headers: {
            ...getAuthHeader(),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },

  getPreview: async (clientId, config, fileId) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/exclusions/preview`,
        { ...config, file_id: fileId },
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting preview:", error);
      throw error;
    }
  },

  saveExclusions: async (clientId, exclusions) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/exclusions`,
        { exclusions },
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error saving exclusions:", error);
      throw error;
    }
  },

  getExclusions: async (clientId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/exclusions`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching exclusions:", error);
      throw error;
    }
  },

  cleanup: async (clientId, fileId) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/exclusions/cleanup`,
        { file_id: fileId },
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error cleaning up:", error);
      throw error;
    }
  },
};

export default exclusionService;
