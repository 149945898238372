import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const CampaignList = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isFinishing, setIsFinishing] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const fetchCampaigns = useCallback(async () => {
    try {
      setLoading(true);
      const campaignsData = await campaignService.getCampaigns(clientId);
      const sortedCampaigns = campaignsData.sort((a, b) => {
        return new Date(b.datetime_created) - new Date(a.datetime_created);
      });
      setCampaigns(sortedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  const handleCampaignClick = (campaignId) => {
    navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
  };

  const handleToggleStatus = async (campaign, e) => {
    e.preventDefault();
    setSelectedCampaign(campaign);
    setIsFinishing(!campaign.datetime_end);
    setDialogOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      let updatedCampaign;
      if (isFinishing) {
        updatedCampaign = await campaignService.finishCampaign(
          clientId,
          selectedCampaign.id
        );
      } else {
        updatedCampaign = await campaignService.resumeCampaign(
          clientId,
          selectedCampaign.id
        );
      }

      // Actualizar la campaña localmente sin recargar toda la lista
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((campaign) =>
          campaign.id === selectedCampaign.id
            ? { ...campaign, datetime_end: updatedCampaign.datetime_end }
            : campaign
        )
      );

      setDialogOpen(false);

      // Mostrar mensaje de éxito
      setNotification({
        open: true,
        message: `Campaña ${isFinishing ? "finalizada" : "reanudada"}`,
        severity: "success",
      });
    } catch (error) {
      setDialogOpen(false);
      // Mostrar mensaje de error desde el backend
      const errorMessage =
        error.response?.data?.error ||
        "Error al actualizar el estado de la campaña";
      setNotification({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const filteredCampaigns = campaigns.filter((campaign) => {
    const matchesSearch = campaign.name_campaign
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    if (showActiveOnly) {
      return matchesSearch && !campaign.datetime_end;
    }
    return matchesSearch;
  });

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Campañas
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
            <TextField
              size="small"
              placeholder="Buscar campañas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: "text.secondary", fontSize: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                maxWidth: 300,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: "background.paper",
                  "& fieldset": {
                    borderColor: "divider",
                  },
                  "&:hover fieldset": {
                    borderColor: "primary.main",
                  },
                },
                "& .MuiInputBase-input": {
                  py: 1,
                },
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                component={Link}
                to={`/clients/${clientId}/campaigns/new`}
                variant="contained"
                color="primary"
                sx={{
                  py: 0.5,
                  fontSize: "0.875rem",
                }}
              >
                Crear Nueva Campaña
              </Button>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showActiveOnly}
                    onChange={(e) => setShowActiveOnly(e.target.checked)}
                    size="small"
                  />
                }
                label="Mostrar solo campañas activas"
                sx={{ "& .MuiTypography-root": { fontSize: "0.875rem" } }}
              />
            </Box>
          </Box>

          <List sx={{ p: 0 }}>
            {filteredCampaigns.map((campaign) => (
              <ListItem
                key={campaign.id}
                sx={{
                  cursor: "pointer",
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  mb: 1,
                  py: 1,
                  px: 2,
                  backgroundColor: "background.paper",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "stretch", sm: "center" },
                }}
                onClick={() => handleCampaignClick(campaign.id)}
              >
                <ListItemText
                  primary={
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                        },
                      }}
                    >
                      {campaign.name_campaign}
                      <Switch
                        checked={!campaign.datetime_end}
                        onChange={(e) => handleToggleStatus(campaign, e)}
                        onClick={(e) => e.stopPropagation()}
                        size="small"
                      />
                    </Box>
                  }
                  secondary={
                    <Box
                      component="span"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.75rem",
                        },
                      }}
                    >
                      <Typography component="span" variant="body2">
                        Fecha de inicio:{" "}
                        {campaign.datetime_start || "No iniciada"}
                        <br />
                        Correos enviados: {campaign.emails_sent} (
                        {campaign.unique_contacts} contactos únicos,{" "}
                        {campaign.total_companies} empresas)
                        <br />
                        Estado:{" "}
                        <span
                          style={{
                            backgroundColor: campaign.datetime_end
                              ? "#ffebee"
                              : "#e8f5e9",
                            color: campaign.datetime_end
                              ? "#c62828"
                              : "#2e7d32",
                            padding: "2px 8px",
                            borderRadius: "12px",
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            display: "inline-block",
                            width: "fit-content",
                            border: `1px solid ${
                              campaign.datetime_end ? "#ef9a9a" : "#a5d6a7"
                            }`,
                          }}
                        >
                          {campaign.datetime_end ? "Finalizada" : "Activa"}
                        </span>
                      </Typography>
                    </Box>
                  }
                  sx={{
                    m: 0,
                    "& .MuiListItemText-primary": {
                      fontSize: "0.875rem",
                    },
                    "& .MuiListItemText-secondary": {
                      fontSize: "0.75rem",
                    },
                  }}
                />
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    mt: { xs: 1, sm: 0 },
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Button
                    component={Link}
                    to={`/clients/${clientId}/campaigns/${campaign.id}`}
                    variant="outlined"
                    size="small"
                    sx={{
                      py: 0.25,
                      px: 1,
                      fontSize: "0.75rem",
                      minWidth: "auto",
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    component={Link}
                    to={`/clients/${clientId}/campaigns/${campaign.id}/templates`}
                    variant="outlined"
                    size="small"
                    sx={{
                      py: 0.25,
                      px: 1,
                      fontSize: "0.75rem",
                      minWidth: "auto",
                    }}
                  >
                    Ver Plantillas
                  </Button>
                </Box>
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ py: 1, fontSize: "1rem" }}>
          Confirmar acción
        </DialogTitle>
        <DialogContent>
          {selectedCampaign && (
            <Typography sx={{ fontSize: "0.875rem" }}>
              {isFinishing
                ? `¿Quieres finalizar la campaña "${selectedCampaign.name_campaign}"?`
                : `¿Quieres reanudar la campaña "${selectedCampaign.name_campaign}"?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            size="small"
            sx={{ fontSize: "0.75rem" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmStatusChange}
            color="primary"
            size="small"
            sx={{ fontSize: "0.75rem" }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CampaignList;
