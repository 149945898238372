import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import campaignService from "../../services/campaignService";

const CampaignForm = () => {
  const { clientId, campaignId } = useParams();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState({
    name_campaign: "",
    datetime_start: "",
    num_emails_per_day: 0,
    days_week_send_email: [],
    hours_day_send_email: [],
    keywords_to_exclude: [],
  });

  useEffect(() => {
    if (campaignId) {
      const fetchCampaign = async () => {
        try {
          const campaignData = await campaignService.getCampaign(
            clientId,
            campaignId
          );
          setCampaign(campaignData);
        } catch (error) {
          console.error("Error al obtener la campaña:", error);
        }
      };
      fetchCampaign();
    }
  }, [clientId, campaignId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prev) => ({ ...prev, [name]: value }));
  };

  const handleMultiSelectChange = (e, field) => {
    setCampaign((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (campaignId) {
        await campaignService.updateCampaign(clientId, campaignId, campaign);
      } else {
        await campaignService.createCampaign(clientId, campaign);
      }
      navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
    } catch (error) {
      console.error("Error al guardar la campaña:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 600, margin: "auto" }}
    >
      <Typography variant="h4" gutterBottom>
        {campaignId ? "Editar Campaña" : "Crear Nueva Campaña"}
      </Typography>
      <TextField
        fullWidth
        label="Nombre de la Campaña"
        name="name_campaign"
        value={campaign.name_campaign}
        onChange={handleChange}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Fecha de Inicio"
        name="datetime_start"
        type="datetime-local"
        value={campaign.datetime_start}
        onChange={handleChange}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        label="Correos por día"
        name="num_emails_per_day"
        type="number"
        value={campaign.num_emails_per_day}
        onChange={handleChange}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Días de la semana para enviar</InputLabel>
        <Select
          multiple
          value={campaign.days_week_send_email}
          onChange={(e) => handleMultiSelectChange(e, "days_week_send_email")}
          renderValue={(selected) => selected.join(", ")}
        >
          {[1, 2, 3, 4, 5, 6, 7].map((day) => (
            <MenuItem key={day} value={day}>
              {
                [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                  "Domingo",
                ][day - 1]
              }
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Horas del día para enviar</InputLabel>
        <Select
          multiple
          value={campaign.hours_day_send_email}
          onChange={(e) => handleMultiSelectChange(e, "hours_day_send_email")}
          renderValue={(selected) => selected.join(", ")}
        >
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <MenuItem key={hour} value={hour}>
              {hour}:00
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Palabras clave a excluir"
        name="keywords_to_exclude"
        value={(campaign.keywords_to_exclude || []).join(", ")}
        onChange={(e) =>
          setCampaign((prev) => ({
            ...prev,
            keywords_to_exclude: e.target.value.split(", "),
          }))
        }
        margin="normal"
        helperText="Separa las palabras clave con comas"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Guardar Campaña
      </Button>
    </Box>
  );
};

export default CampaignForm;
