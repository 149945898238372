import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import keywordService from "../../services/keywordService";
import KeywordForm from "./KeywordForm";

const KeywordList = () => {
  const [keywords, setKeywords] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [openCompaniesModal, setOpenCompaniesModal] = useState(false);
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companySearchText, setCompanySearchText] = useState("");

  const fetchKeywordCompanies = async (keywordId) => {
    try {
      setLoading(true);
      const response = await keywordService.getKeywordCompanies(keywordId);
      setRelatedCompanies(response);
      setOpenCompaniesModal(true);
    } catch (error) {
      console.error("Error al obtener empresas de la palabra clave:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchKeywords = async () => {
    try {
      const data = await keywordService.getKeywords();
      setKeywords(data);
    } catch (error) {
      console.error("Error al obtener palabras clave:", error);
    }
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  const handleOpenForm = (keyword = null) => {
    setSelectedKeyword(keyword);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedKeyword(null);
  };

  const handleSave = async (keywordData) => {
    try {
      let savedKeyword;
      if (selectedKeyword) {
        savedKeyword = await keywordService.updateKeyword(
          selectedKeyword.id,
          keywordData
        );
      } else {
        savedKeyword = await keywordService.createKeyword(keywordData);
      }
      await keywordService.processKeyword(savedKeyword.id);
      fetchKeywords();
      handleCloseForm();
    } catch (error) {
      console.error("Error al guardar palabra clave:", error);
    }
  };

  const filteredKeywords = keywords.filter((keyword) =>
    keyword.text_keyword.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredCompanies = relatedCompanies.filter((company) =>
    company.name.toLowerCase().includes(companySearchText.toLowerCase())
  );

  return (
    <Box sx={{ margin: "auto", p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gestión de Palabras Clave
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm()}
        sx={{ mb: 3 }}
      >
        Agregar Nueva Palabra Clave
      </Button>

      <TextField
        fullWidth
        label="Buscar palabras clave"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        sx={{ mb: 2 }}
      />

      <List>
        {filteredKeywords.map((keyword) => (
          <ListItem
            key={keyword.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 2,
              backgroundColor: "background.paper",
            }}
            secondaryAction={
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => fetchKeywordCompanies(keyword.id)}
                >
                  Ver Empresas
                </Button>
                <IconButton onClick={() => handleOpenForm(keyword)}>
                  <EditIcon />
                </IconButton>
              </Box>
            }
          >
            <ListItemText
              primary={keyword.text_keyword}
              secondary={`Empresas Relacionadas: ${keyword.company_count}`}
            />
          </ListItem>
        ))}
      </List>

      <Dialog
        open={openCompaniesModal}
        onClose={() => setOpenCompaniesModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Empresas Relacionadas</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                fullWidth
                label="Buscar empresas"
                variant="outlined"
                value={companySearchText}
                onChange={(e) => setCompanySearchText(e.target.value)}
                sx={{ mb: 2, mt: 1 }}
              />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nombre de la Empresa</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCompanies.map((company) => (
                      <TableRow key={company.id}>
                        <TableCell>{company.id}</TableCell>
                        <TableCell>{company.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCompaniesModal(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <KeywordForm
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSave}
        initialData={selectedKeyword}
      />
    </Box>
  );
};

export default KeywordList;
