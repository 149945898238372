import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import RichTextEditor from "../common/RichTextEditor";
import { processContent } from "../../utils/contentProcessor";

const EmailCredentialForm = ({
  open,
  onClose,
  initialData,
  onSubmit,
  clientId,
}) => {
  const [formData, setFormData] = useState({
    name_sender: "",
    email: "",
    password: "",
    server_smtp: "",
    port_smtp: "",
    server_imap: "",
    port_imap: "",
    signature: "",
    link_email: "",
  });

  const [errors, setErrors] = useState({
    name_sender: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      // Validate initial data if it exists
      if (initialData.name_sender) {
        validateSenderName(initialData.name_sender);
      }
    } else {
      setFormData({
        name_sender: "",
        email: "",
        password: "",
        server_smtp: "",
        port_smtp: "",
        server_imap: "",
        port_imap: "",
        signature: "",
        link_email: "",
      });
      setErrors({
        name_sender: "",
      });
    }
  }, [initialData]);

  const validateSenderName = (name) => {
    // Check if the name has at least two words (separated by spaces)
    const words = name.trim().split(/\s+/);
    if (words.length < 2) {
      setErrors((prev) => ({
        ...prev,
        name_sender:
          "El nombre debe contener al menos dos palabras (nombre y apellido o nombre y designación)",
      }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, name_sender: "" }));
      return true;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validate sender name when it changes
    if (name === "name_sender") {
      validateSenderName(value);
    }
  };

  const handleSubmit = async () => {
    // Validate before submitting
    const isNameValid = validateSenderName(formData.name_sender);

    if (!isNameValid) {
      return; // Don't submit if validation fails
    }

    try {
      const processedSignature = await processContent(
        formData.signature,
        clientId
      );

      const processedData = {
        ...formData,
        signature: processedSignature,
      };

      await onSubmit(processedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar la credencial de email:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ py: 1, fontSize: "1rem" }}>
        {initialData?.id
          ? "Editar Credencial de Email"
          : "Crear Credencial de Email"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1.5 }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{ fontSize: "0.875rem", fontWeight: 500 }}
              gutterBottom
            >
              Información Básica
            </Typography>
            <TextField
              fullWidth
              label="Nombre del Remitente"
              name="name_sender"
              value={formData.name_sender}
              onChange={handleChange}
              margin="normal"
              required
              error={!!errors.name_sender}
              helperText={errors.name_sender}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: errors.name_sender ? "auto" : "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "0.75rem",
                  marginTop: "2px",
                },
              }}
            />
            <TextField
              fullWidth
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              required
              type="password"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Configuración del Servidor
            </Typography>
            <TextField
              fullWidth
              label="Servidor SMTP"
              name="server_smtp"
              value={formData.server_smtp}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
            <TextField
              fullWidth
              label="Puerto SMTP"
              name="port_smtp"
              value={formData.port_smtp}
              onChange={handleChange}
              margin="normal"
              required
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
            <TextField
              fullWidth
              label="Servidor IMAP"
              name="server_imap"
              value={formData.server_imap}
              onChange={handleChange}
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
            <TextField
              fullWidth
              label="Puerto IMAP"
              name="port_imap"
              value={formData.port_imap}
              onChange={handleChange}
              margin="normal"
              required
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  transform: "translate(14px, 8px) scale(0.85)",
                  "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(14px, -9px) scale(0.7)",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 14px",
                  fontSize: "0.875rem",
                },
              }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Firma del Correo
            </Typography>
            <RichTextEditor
              value={formData.signature}
              onChange={(content) =>
                setFormData((prev) => ({ ...prev, signature: content }))
              }
              clientId={clientId}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size="small" sx={{ fontSize: "0.75rem" }}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          size="small"
          sx={{ fontSize: "0.75rem" }}
        >
          {initialData?.id ? "Actualizar" : "Crear"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailCredentialForm;
