// TestEmailModal.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";

const TestEmailModal = ({ open, onClose, onSend, template }) => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("personal"); // 'personal' o 'company'

  const handleSend = () => {
    onSend({
      email,
      type,
    });
    setEmail("");
    setType("personal");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Enviar email de prueba</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Email destinatario"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de correo</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="personal">Personal</MenuItem>
              <MenuItem value="company">Empresarial</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSend} variant="contained" disabled={!email}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestEmailModal;
