import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import RichTextEditor from "../common/RichTextEditor";
import { processContent } from "../../utils/contentProcessor";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";

const CampaignTemplateEditor = () => {
  const { clientId, campaignId, templateId } = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({
    subject: "",
    body: "",
    vertical_ids: [],
    templateType: "presentation",
    num_days_without_response: "",
    after_rejection: false,
  });
  const [verticals, setVerticals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newVertical, setNewVertical] = useState({ name_vertical: "" });
  const [openPreview, setOpenPreview] = useState(false);
  const [emailCredential, setEmailCredential] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verticalsData = await verticalService.getClientVerticals(
          clientId
        );
        setVerticals(verticalsData);

        if (templateId) {
          const templateData = await campaignService.getCampaignTemplate(
            clientId,
            campaignId,
            templateId
          );
          setTemplate({
            subject: templateData.subject || "",
            body: templateData.body || "",
            vertical_ids: templateData.vertical_ids
              ? templateData.vertical_ids.map((id) => id.toString())
              : [],
            templateType: templateData.after_rejection
              ? "rejection"
              : templateData.num_days_without_response
              ? "followup"
              : "presentation",
            num_days_without_response:
              templateData.num_days_without_response || "",
            after_rejection: templateData.after_rejection || false,
          });
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, [clientId, campaignId, templateId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al obtener credencial de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const handleTemplateTypeChange = (e) => {
    const type = e.target.value;
    setTemplate({
      ...template,
      templateType: type,
      num_days_without_response: type === "followup" ? "" : null,
      after_rejection: type === "rejection",
    });
  };

  const handleBodyChange = (content) => {
    setTemplate({ ...template, body: content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const processedBody = await processContent(template.body, clientId);

      const templateData = {
        subject: template.subject,
        body: processedBody,
        vertical_ids: template.vertical_ids,
        num_days_without_response:
          template.templateType === "followup"
            ? parseInt(template.num_days_without_response)
            : null,
        after_rejection: template.templateType === "rejection",
      };

      if (templateId) {
        await campaignService.updateCampaignTemplate(
          clientId,
          campaignId,
          templateId,
          templateData
        );
      } else {
        await campaignService.createCampaignTemplate(
          clientId,
          campaignId,
          templateData
        );
      }
      navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
    } catch (error) {
      console.error("Error al guardar plantilla:", error);
    }
  };

  const handleCreateVertical = async () => {
    try {
      const createdVertical = await verticalService.createClientVertical(
        clientId,
        { name_vertical: newVertical.name_vertical }
      );
      setVerticals([...verticals, createdVertical]);
      setTemplate({
        ...template,
        vertical_ids: [...template.vertical_ids, createdVertical.id],
      });
      setOpenModal(false);
      setNewVertical({ name_vertical: "" });
    } catch (error) {
      console.error("Error al crear vertical:", error);
    }
  };

  const handlePreview = () => {
    setOpenPreview(true);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 800, margin: "auto", p: 2 }}
      >
        <Typography variant="h4" gutterBottom>
          {templateId ? "Editar Plantilla" : "Crear Plantilla"}
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Tipo de Plantilla</InputLabel>
          <Select
            name="templateType"
            value={template.templateType}
            onChange={handleTemplateTypeChange}
            required
          >
            <MenuItem value="presentation">Presentación</MenuItem>
            <MenuItem value="followup">Seguimiento</MenuItem>
            <MenuItem value="rejection">Rechazo</MenuItem>
          </Select>
        </FormControl>

        {template.templateType === "followup" && (
          <TextField
            fullWidth
            label="Días de espera para seguimiento"
            name="num_days_without_response"
            type="number"
            value={template.num_days_without_response}
            onChange={handleChange}
            margin="normal"
            required
          />
        )}

        <TextField
          fullWidth
          label="Asunto"
          name="subject"
          value={template.subject}
          onChange={handleChange}
          margin="normal"
          required
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Verticales</InputLabel>
          <Select
            multiple
            name="vertical_ids"
            value={template.vertical_ids.map((id) => id.toString())}
            onChange={(e) => {
              const selectedIds = e.target.value;
              setTemplate((prev) => ({
                ...prev,
                vertical_ids: selectedIds,
              }));
            }}
            required
          >
            {verticals.map((vertical) => (
              <MenuItem key={vertical.id} value={vertical.id.toString()}>
                {vertical.name_vertical}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Cuerpo del Email
          </Typography>
          <RichTextEditor
            value={template.body}
            onChange={handleBodyChange}
            clientId={clientId}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          {templateId ? "Actualizar Plantilla" : "Crear Plantilla"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePreview}
          sx={{ mt: 2, ml: 2 }}
        >
          Vista Previa
        </Button>

        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Crear Nuevo Vertical</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Nombre del Vertical"
              value={newVertical.name_vertical}
              onChange={(e) =>
                setNewVertical({ name_vertical: e.target.value })
              }
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
            <Button onClick={handleCreateVertical} variant="contained">
              Crear
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={template.body}
        textClosing={emailCredential?.text_closing || ""}
        clientId={clientId}
      />
    </>
  );
};

export default CampaignTemplateEditor;
