import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import verticalService from "../../services/verticalService";
import VerticalForm from "./VerticalForm";

const VerticalList = ({ clientId }) => {
  const [verticals, setVerticals] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const fetchVerticals = useCallback(async () => {
    try {
      const data = await verticalService.getClientVerticals(clientId);
      setVerticals(data);
    } catch (error) {
      console.error("Error al obtener verticales:", error);
    }
  }, [clientId]);

  useEffect(() => {
    fetchVerticals();
  }, [fetchVerticals]);

  const handleOpenForm = async (vertical = null) => {
    setLoading(true);
    try {
      if (vertical) {
        setSelectedVertical(vertical);
        const verticalWithKeywords =
          await verticalService.getVerticalWithKeywords(clientId, vertical.id);
        setSelectedVertical(verticalWithKeywords);
      } else {
        setSelectedVertical(null);
      }
      setOpenForm(true);
    } catch (error) {
      console.error("Error al abrir formulario de vertical:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedVertical(null);
  };

  const handleSave = async (verticalData) => {
    try {
      if (selectedVertical) {
        await verticalService.updateClientVertical(
          clientId,
          selectedVertical.id,
          verticalData
        );
      } else {
        await verticalService.createClientVertical(clientId, verticalData);
      }
      await fetchVerticals();
      handleCloseForm();
    } catch (error) {
      console.error("Error al guardar vertical:", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Verticales
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm()}
        style={{ marginBottom: "20px" }}
      >
        Añadir Nuevo Vertical
      </Button>
      <List>
        {verticals.map((vertical) => (
          <ListItem
            key={vertical.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 2,
              p: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
                transition: "background-color 0.3s",
              },
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography variant="h6" component="div">
                    {vertical.name_vertical}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                      padding: "4px 8px",
                      borderRadius: "12px",
                    }}
                  >
                    {vertical.company_count} empresas
                  </Typography>
                </Box>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenForm(vertical)}
            >
              Editar
            </Button>
          </ListItem>
        ))}
      </List>

      {loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : (
        <VerticalForm
          open={openForm}
          onClose={() => setOpenForm(false)}
          onSubmit={handleSave}
          initialData={selectedVertical}
          clientId={clientId}
          cachedKeywords={keywords}
          setCachedKeywords={setKeywords}
        />
      )}
    </div>
  );
};

export default VerticalList;
