import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const KeywordForm = ({ open, onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    text_keyword: "",
    text_before_to_add: null,
    add_s_for_plural: false,
    text_keyword_plural: "",
    id_last_company_processed: 0,
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        text_keyword: initialData.text_keyword || "",
        text_before_to_add: initialData.text_before_to_add || null,
        add_s_for_plural: initialData.add_s_for_plural || false,
        text_keyword_plural: initialData.text_keyword_plural || "",
        id_last_company_processed: 0,
      });
    } else {
      setFormData({
        text_keyword: "",
        text_before_to_add: null,
        add_s_for_plural: false,
        text_keyword_plural: "",
        id_last_company_processed: 0,
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "add_s_for_plural") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
        text_keyword_plural: checked ? "" : prev.text_keyword_plural,
      }));
    } else if (name === "text_keyword_plural" && value) {
      setFormData((prev) => ({
        ...prev,
        text_keyword_plural: value,
        add_s_for_plural: false,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = {
      ...formData,
      text_before_to_add: formData.text_before_to_add || null,
      text_keyword_plural: formData.add_s_for_plural
        ? null
        : formData.text_keyword_plural || null,
    };

    onSubmit(submitData);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {initialData ? "Editar Palabra Clave" : "Nueva Palabra Clave"}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
            <FormControl sx={{ width: "30%" }}>
              <InputLabel>Prefijo</InputLabel>
              <Select
                value={formData.text_before_to_add || ""}
                name="text_before_to_add"
                onChange={handleChange}
                label="Prefijo"
              >
                <MenuItem value="">Nada</MenuItem>
                <MenuItem value="EMPRESA DE">Empresa de</MenuItem>
                <MenuItem value="NEGOCIO DE">Negocio de</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "70%" }}
              label="Texto de la Palabra Clave"
              name="text_keyword"
              value={formData.text_keyword}
              onChange={handleChange}
              required
            />
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.add_s_for_plural}
                onChange={handleChange}
                name="add_s_for_plural"
                disabled={!!formData.text_keyword_plural}
              />
            }
            label="Agregar 's' para el plural"
          />

          <TextField
            fullWidth
            label="Texto en Plural"
            name="text_keyword_plural"
            value={formData.text_keyword_plural}
            onChange={handleChange}
            disabled={formData.add_s_for_plural}
            sx={{ mt: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {initialData ? "Actualizar" : "Crear"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeywordForm;
