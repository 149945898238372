import axios from "axios";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const keywordService = {
  getKeywords: async () => {
    try {
      const response = await axios.get(`${API_URL}/keywords`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching keywords:", error);
      throw error;
    }
  },
  getKeywordCompanies: async (keywordId) => {
    try {
      const response = await axios.get(
        `${API_URL}/keywords/${keywordId}/companies`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching keyword companies:", error);
      throw error;
    }
  },
  updateKeyword: async (keywordId, keywordData) => {
    try {
      const response = await axios.put(
        `${API_URL}/keywords/${keywordId}`,
        {
          text_keyword: keywordData.text_keyword,
          expressions_to_search: keywordData.expressions_to_search || [],
        },
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating keyword:", error);
      throw error;
    }
  },

  processKeyword: async (keywordId) => {
    try {
      const response = await axios.post(
        `${API_URL}/keywords/${keywordId}/process`,
        {},
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error processing keyword:", error);
      throw error;
    }
  },
  createKeyword: async (keywordData) => {
    try {
      const response = await axios.post(
        `${API_URL}/keywords`,
        {
          text_keyword: keywordData.text_keyword,
          expressions_to_search: keywordData.expressions_to_search || [],
        },
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating keyword:", error);
      throw error;
    }
  },

  assignKeywordsToVertical: async (clientId, verticalId, keywordIds) => {
    try {
      console.log(
        `Llamando a assignKeywordsToVertical: clientId=${clientId}, verticalId=${verticalId}, keywordIds=${JSON.stringify(
          keywordIds
        )}`
      );

      // Asegurarse de que todos los IDs sean números
      const numericKeywordIds = keywordIds.map((id) => Number(id));
      console.log(
        `keywordIds convertidos a números: ${JSON.stringify(numericKeywordIds)}`
      );

      // Crear el objeto de datos con la estructura correcta
      const data = { keyword_ids: numericKeywordIds };
      console.log("Datos a enviar:", JSON.stringify(data));

      const response = await axios.post(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords`,
        data,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error assigning keywords to vertical:", error);
      throw error;
    }
  },
  getVerticalKeywords: async (clientId, verticalId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching vertical keywords:", error);
      throw error;
    }
  },
  getCompanyDescription: async (companyId) => {
    try {
      const response = await axios.get(
        `${API_URL}/companies/${companyId}/description`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching company description:", error);
      throw error;
    }
  },
};

export default keywordService;
