import React from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useBreadcrumbNames } from "../../hooks/useBreadcrumbNames";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const names = useBreadcrumbNames(pathnames);

  const breadcrumbNameMap = {
    clients: "Clientes",
    keywords: "Palabras Clave",
    campaigns: "Campañas",
    templates: "Plantillas",
    new: "Nuevo",
    edit: "Editar",
  };

  const getBreadcrumbTo = (index) => {
    return `/${pathnames.slice(0, index + 1).join("/")}`;
  };

  const getBreadcrumbText = (value, index) => {
    const prevSegment = pathnames[index - 1];

    if (prevSegment === "clients" && !isNaN(value)) {
      return names.clients[value] || value;
    }

    if (prevSegment === "campaigns" && !isNaN(value)) {
      return names.campaigns[value] || value;
    }

    if (prevSegment === "templates" && !isNaN(value)) {
      return names.templates[value] || value;
    }

    return breadcrumbNameMap[value] || value;
  };

  return (
    <Box
      sx={{
        padding: "16px 24px",
        backgroundColor: "background.paper",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <MuiBreadcrumbs aria-label="migas de pan">
        <Link
          component={RouterLink}
          to="/clients"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "primary.main",
            textDecoration: "none",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
          Inicio
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = getBreadcrumbTo(index);
          const breadcrumbText = getBreadcrumbText(value, index);

          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbText}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              color="inherit"
              to={to}
              key={to}
              sx={{
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {breadcrumbText}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
