import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  FormControlLabel,
  Switch,
  Checkbox,
  Snackbar,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";
import MultiSelect from "../common/MultiSelect";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TestEmailModal from "../common/TestEmailModal";
import EmailIcon from "@mui/icons-material/Email";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const CampaignTemplateList = () => {
  const { clientId, campaignId } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailCredential, setEmailCredential] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
  const [selectedTemplateForTest, setSelectedTemplateForTest] = useState(null);
  const [sendingTestEmail, setSendingTestEmail] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const [templatesData, verticalsData, campaignData] = await Promise.all([
          campaignService.getCampaignTemplates(clientId, campaignId),
          verticalService.getClientVerticals(clientId),
          campaignService.getCampaign(clientId, campaignId),
        ]);
        setTemplates(
          templatesData.map((template) => ({
            ...template,
            text_personal_connection: template.text_personal_connection,
            text_company_connection: template.text_company_connection,
          }))
        );
        setVerticals(verticalsData);
        setCampaign(campaignData);
      } catch (error) {
        setError(error.response?.data?.message || "Error al cargar los datos");
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [clientId, campaignId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al cargar las credenciales de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const isTemplateActive = (template) => {
    return template.datetime_start && !template.datetime_end;
  };

  const getFilteredTemplates = () => {
    return templates.filter((template) => {
      const matchesVerticals =
        selectedVerticals.length === 0 ||
        selectedVerticals.some((verticalId) =>
          template.vertical_ids?.includes(parseInt(verticalId))
        );

      const matchesTypes =
        selectedTypes.length === 0 ||
        selectedTypes.includes(getTemplateType(template));

      const matchesActive = !showActiveOnly || isTemplateActive(template);

      return matchesVerticals && matchesTypes && matchesActive;
    });
  };

  const getTemplateType = (template) => {
    if (template.after_rejection) return "rejection";
    if (template.num_days_without_response > 0) return "followup";
    return "presentation";
  };

  const getTemplateBorderStyle = (type) => {
    switch (type) {
      case "followup":
        return "2px solid #FFD700"; // Yellow border for followup
      case "rejection":
        return "2px solid #FF6B6B"; // Red border for rejection
      default:
        return "1px solid #ddd"; // Default border for presentation
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 800, margin: "auto", mt: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const typeOptions = [
    {
      value: "presentation",
      label: "Presentación",
      color: "#ddd",
    },
    {
      value: "followup",
      label: "Seguimiento",
      color: "#FFD700",
    },
    {
      value: "rejection",
      label: "Rechazo",
      color: "#FF6B6B",
    },
  ];

  const handleToggleStatus = async (template, event) => {
    event.stopPropagation();
    event.preventDefault();
    const isCurrentlyActive = isTemplateActive(template);
    try {
      // Actualizar el estado inmediatamente para una respuesta UI más rápida
      const updatedTemplates = templates.map((t) => {
        if (t.id === template.id) {
          return {
            ...t,
            datetime_start: isCurrentlyActive
              ? t.datetime_start
              : new Date().toISOString(),
            datetime_end: isCurrentlyActive ? new Date().toISOString() : null,
          };
        }
        return t;
      });
      setTemplates(updatedTemplates);

      // Hacer la llamada API en segundo plano
      if (isCurrentlyActive) {
        await campaignService.deactivateTemplate(
          clientId,
          campaignId,
          template.id
        );
      } else {
        await campaignService.activateTemplate(
          clientId,
          campaignId,
          template.id
        );
      }
    } catch (error) {
      console.error("Error toggling template status:", error);
      // Revertir el cambio en caso de error
      const revertedTemplates = templates.map((t) => {
        if (t.id === template.id) {
          return {
            ...t,
            datetime_start: !isCurrentlyActive
              ? t.datetime_start
              : new Date().toISOString(),
            datetime_end: !isCurrentlyActive ? new Date().toISOString() : null,
          };
        }
        return t;
      });
      setTemplates(revertedTemplates);
      setError("Error al cambiar el estado de la plantilla");
    }
  };

  const handleTestEmail = (template) => {
    setSelectedTemplateForTest(template);
    setTestEmailModalOpen(true);
  };

  const handleSendTestEmail = async (data) => {
    if (sendingTestEmail) return; // Prevent double submission

    setSendingTestEmail(true);
    try {
      await campaignService.sendTestEmail(
        clientId,
        campaignId,
        selectedTemplateForTest.id,
        data
      );
      setToast({
        open: true,
        message: "Email de prueba enviado correctamente",
        severity: "success",
      });
      setTestEmailModalOpen(false);
    } catch (error) {
      console.error("Error al enviar el email de prueba:", error);
      setToast({
        open: true,
        message:
          error.response?.data?.error || "Error al enviar el email de prueba",
        severity: "error",
      });
    } finally {
      setSendingTestEmail(false);
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") return;
    setToast({ ...toast, open: false });
  };

  const handleToggleAttachPresentationEmail = async (template, event) => {
    event.stopPropagation();
    const newValue = event.target.checked;

    try {
      // Update UI immediately for better responsiveness
      const updatedTemplates = templates.map((t) => {
        if (t.id === template.id) {
          return {
            ...t,
            attach_presentation_email: newValue,
          };
        }
        return t;
      });
      setTemplates(updatedTemplates);

      // Make API call in the background
      await campaignService.updateTemplateAttachPresentationEmail(
        clientId,
        campaignId,
        template.id,
        newValue
      );

      // Show toast only if the user has the toast component visible
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        setToast({
          open: true,
          message: newValue
            ? "Email de presentación adjuntado activado"
            : "Email de presentación adjuntado desactivado",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error updating attach presentation email:", error);

      // Revert UI change on error
      const revertedTemplates = templates.map((t) => {
        if (t.id === template.id) {
          return {
            ...t,
            attach_presentation_email:
              template.attach_presentation_email || false,
          };
        }
        return t;
      });
      setTemplates(revertedTemplates);

      setToast({
        open: true,
        message: "Error al actualizar el adjunto de email de presentación",
        severity: "error",
      });
    }
  };

  return (
    <>
      <Box sx={{ margin: "auto", p: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            {campaign?.name_campaign || "Cargando..."}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            mb: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MultiSelect
              options={verticals.map((v) => ({
                value: v.id.toString(),
                label: v.name_vertical,
              }))}
              value={selectedVerticals}
              onChange={setSelectedVerticals}
              label="Verticales"
              placeholder="Cualquier vertical"
            />
            <Tooltip title="Reiniciar filtro de verticales">
              <IconButton
                size="small"
                onClick={() => setSelectedVerticals([])}
                sx={{ opacity: selectedVerticals.length > 0 ? 1 : 0.3 }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MultiSelect
              options={typeOptions}
              value={selectedTypes}
              onChange={setSelectedTypes}
              label="Tipos"
              placeholder="Cualquier tipo"
              renderOption={(option) => (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      border: `2px solid ${option.color}`,
                      borderRadius: "50%",
                    }}
                  />
                  {option.label}
                </Box>
              )}
            />
            <Tooltip title="Reiniciar filtro de tipos">
              <IconButton
                size="small"
                onClick={() => setSelectedTypes([])}
                sx={{ opacity: selectedTypes.length > 0 ? 1 : 0.3 }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={showActiveOnly}
                onChange={(e) => setShowActiveOnly(e.target.checked)}
                size="small"
              />
            }
            label={
              <Typography sx={{ fontSize: "0.875rem" }}>
                Mostrar solo plantillas activas
              </Typography>
            }
          />
        </Box>

        <Button
          component={Link}
          to={`/clients/${clientId}/campaigns/${campaignId}/templates/new`}
          variant="contained"
          color="primary"
          size="small"
          sx={{ mb: 2 }}
        >
          Crear Nueva Plantilla
        </Button>

        <List>
          {getFilteredTemplates().map((template) => {
            const templateType = getTemplateType(template);
            return (
              <ListItem
                key={template.id}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "stretch", sm: "center" },
                  gap: 1,
                  border: getTemplateBorderStyle(templateType),
                  borderRadius: 1,
                  mb: 1,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(0,0,0,0.02)",
                  },
                }}
                onClick={(e) => {
                  if (e.target.closest(".MuiSwitch-root")) {
                    return;
                  }
                  navigate(
                    `/clients/${clientId}/campaigns/${campaignId}/templates/${template.id}`
                  );
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    minWidth: 0,
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              wordBreak: "break-word",
                              mb: 0.5,
                            }}
                          >
                            {template.subject}
                          </Typography>
                          <Tooltip title="Si está activado, este email adjuntará un email de presentación cuando se envíe.">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                checked={
                                  template.attach_presentation_email || false
                                }
                                onChange={(e) =>
                                  handleToggleAttachPresentationEmail(
                                    template,
                                    e
                                  )
                                }
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                                className="MuiCheckbox-root"
                                icon={<AttachFileIcon fontSize="small" />}
                                checkedIcon={
                                  <AttachFileIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                }
                                sx={{
                                  p: 0.5,
                                  ml: 1,
                                }}
                              />
                              {template.attach_presentation_email && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontSize: "0.6rem",
                                    bgcolor: "primary.main",
                                    color: "white",
                                    px: 0.5,
                                    py: 0.2,
                                    borderRadius: 5,
                                    ml: 0.5,
                                  }}
                                >
                                  Adjunto
                                </Typography>
                              )}
                            </Box>
                          </Tooltip>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{
                              color: "text.secondary",
                              bgcolor: "rgba(0,0,0,0.05)",
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {templateType === "followup"
                              ? `Seguimiento (${template.num_days_without_response} días)`
                              : templateType === "rejection"
                              ? "Rechazo"
                              : "Presentación"}
                          </Typography>
                          {template.vertical_ids?.map((verticalId) => {
                            const vertical = verticals.find(
                              (v) => v.id === verticalId
                            );
                            return (
                              vertical && (
                                <Typography
                                  key={verticalId}
                                  variant="caption"
                                  component="div"
                                  sx={{
                                    color: "text.secondary",
                                    bgcolor:
                                      templateType === "followup"
                                        ? "rgba(255, 215, 0, 0.1)"
                                        : templateType === "rejection"
                                        ? "rgba(255, 107, 107, 0.1)"
                                        : "rgba(0, 0, 0, 0.05)",
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1,
                                    border: `1px solid ${
                                      templateType === "followup"
                                        ? "#FFD700"
                                        : templateType === "rejection"
                                        ? "#FF6B6B"
                                        : "#ddd"
                                    }`,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {vertical.name_vertical}
                                </Typography>
                              )
                            );
                          })}
                        </Box>
                      </Box>
                    }
                    secondary={
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <span>
                          Emails Enviados: {template.emails_sent} (
                          {template.unique_contacts} contactos únicos,{" "}
                          {template.total_companies} empresas)
                        </span>
                        {template.datetime_start && (
                          <span>
                            Inicio:{" "}
                            {new Date(
                              template.datetime_start
                            ).toLocaleDateString()}
                          </span>
                        )}
                        <span
                          style={{
                            backgroundColor: !isTemplateActive(template)
                              ? "#ffebee"
                              : "#e8f5e9",
                            color: !isTemplateActive(template)
                              ? "#c62828"
                              : "#2e7d32",
                            padding: "2px 8px",
                            borderRadius: "12px",
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            display: "inline-block",
                            width: "fit-content",
                            border: `1px solid ${
                              !isTemplateActive(template)
                                ? "#ef9a9a"
                                : "#a5d6a7"
                            }`,
                          }}
                        >
                          {isTemplateActive(template) ? "Activo" : "Inactivo"}
                        </span>
                      </Typography>
                    }
                  />
                </Box>
                <Box
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    flexDirection: { xs: "row", sm: "row" },
                    justifyContent: { xs: "stretch", sm: "flex-end" },
                    alignItems: "flex-start",
                    minWidth: { xs: "100%", sm: "auto" },
                    zIndex: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePreview(template);
                    }}
                    sx={{ flex: { xs: 1, sm: "none" } }}
                  >
                    Vista Previa
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/clients/${clientId}/campaigns/${campaignId}/templates/${template.id}`
                      );
                    }}
                    sx={{ flex: { xs: 1, sm: "none" } }}
                  >
                    Editar
                  </Button>
                  <LoadingButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTestEmail(template);
                    }}
                    startIcon={<EmailIcon />}
                    loading={sendingTestEmail}
                    disabled={sendingTestEmail}
                  >
                    Probar
                  </LoadingButton>
                </Box>
                <Switch
                  checked={isTemplateActive(template)}
                  onChange={(e) => handleToggleStatus(template, e)}
                  size="small"
                  className="MuiSwitch-root"
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={selectedTemplate?.body || ""}
        textClosing={campaign?.text_closing || ""}
        textPersonalConnection={selectedTemplate?.text_personal_connection}
        textCompanyConnection={selectedTemplate?.text_company_connection}
        clientId={clientId}
        subject={selectedTemplate?.subject}
        templateId={selectedTemplate?.id}
      />
      <TestEmailModal
        open={testEmailModalOpen}
        onClose={() => setTestEmailModalOpen(false)}
        onSend={handleSendTestEmail}
        template={selectedTemplateForTest}
      />
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseToast}
          severity={toast.severity}
        >
          {toast.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CampaignTemplateList;
