import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Box sx={{ height: "60px" }} /> {/* Spacer */}
      <Box
        component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "1rem",
          backgroundColor: "#fff",
          borderTop: "1px solid",
          borderColor: "divider",
          textAlign: "center",
          zIndex: 1000,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          &copy; 2024 Gestor de Leads LemonAI. Todos los derechos reservados.
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
