import axios from "axios";

//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const positionService = {
  getPositions: async () => {
    try {
      const response = await axios.get(`${API_URL}/positions`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching positions:", error);
      throw error;
    }
  },

  // Si en el futuro necesitamos más operaciones con posiciones,
  // podemos añadir más métodos aquí siguiendo el mismo patrón:
  // - createPosition
  // - updatePosition
  // - deletePosition
  // etc.
};

export default positionService;
