import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  InputAdornment,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import keywordService from "../../services/keywordService";
import KeywordForm from "./KeywordForm";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const KeywordList = () => {
  const [keywords, setKeywords] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [openCompaniesModal, setOpenCompaniesModal] = useState(false);
  const [relatedCompanies, setRelatedCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companySearchText, setCompanySearchText] = useState("");
  const [selectedCompanyDescription, setSelectedCompanyDescription] =
    useState(null);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loadingCompanyId, setLoadingCompanyId] = useState(null);
  const [displayedCompanies, setDisplayedCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const companiesPerPage = 50;
  const [loadingKeywords, setLoadingKeywords] = useState(true);
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;

  const fetchKeywordCompanies = async (keywordId, event) => {
    event.stopPropagation();
    try {
      setLoadingCompanyId(keywordId);
      const response = await keywordService.getKeywordCompanies(keywordId);
      setRelatedCompanies(response);
      setOpenCompaniesModal(true);
    } catch (error) {
      console.error("Error al obtener empresas del subvertical:", error);
    } finally {
      setLoadingCompanyId(null);
    }
  };

  const fetchKeywords = async () => {
    try {
      setLoadingKeywords(true);
      const data = await keywordService.getKeywords();
      setKeywords(data);
    } catch (error) {
      console.error("Error al obtener subverticales:", error);
    } finally {
      setLoadingKeywords(false);
    }
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  useEffect(() => {
    const filtered = relatedCompanies.filter((company) =>
      company.name.toLowerCase().includes(companySearchText.toLowerCase())
    );
    setDisplayedCompanies(filtered.slice(0, currentPage * companiesPerPage));
  }, [relatedCompanies, companySearchText, currentPage]);

  const handleOpenForm = (keyword = null) => {
    setSelectedKeyword(keyword);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedKeyword(null);
  };

  const handleSave = async (keywordData) => {
    try {
      setLoading(true);
      let savedKeyword;
      if (selectedKeyword) {
        savedKeyword = await keywordService.updateKeyword(
          selectedKeyword.id,
          keywordData
        );
        await keywordService.processKeyword(savedKeyword.id);
      } else {
        savedKeyword = await keywordService.createKeyword(keywordData);
        await keywordService.processKeyword(savedKeyword.id);
      }
      await fetchKeywords();
      handleCloseForm();
    } catch (error) {
      console.error("Error al guardar subvertical:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowInfo = async (company, event) => {
    event.stopPropagation();
    try {
      setLoadingDescription(true);
      setSelectedCompany(company);
      const response = await keywordService.getCompanyDescription(company.id);
      setSelectedCompanyDescription(response.description);
      setOpenInfoModal(true);
    } catch (error) {
      console.error("Error al obtener la descripción:", error);
    } finally {
      setLoadingDescription(false);
    }
  };

  const filteredKeywords = keywords.filter((keyword) =>
    keyword.text_keyword.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredCompanies = relatedCompanies.filter((company) =>
    company.name.toLowerCase().includes(companySearchText.toLowerCase())
  );

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleLoadAll = () => {
    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);
    setCurrentPage(totalPages);
  };

  // Calcular elementos paginados
  const paginatedKeywords = filteredKeywords.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.5rem",
            fontWeight: 500,
          }}
        >
          Gestión de Palabras Clave
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenForm()}
          sx={{
            textTransform: "none",
            fontSize: "0.875rem",
            px: 2,
            py: 0.75,
          }}
        >
          Nueva Palabra Clave
        </Button>
      </Box>

      <Paper
        elevation={0}
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          px: 1.5,
          py: 0.5,
        }}
      >
        <SearchIcon sx={{ color: "text.secondary", fontSize: "1.25rem" }} />
        <TextField
          fullWidth
          placeholder="Buscar palabras clave..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="standard"
          sx={{
            ml: 1,
            "& .MuiInput-root": {
              fontSize: "0.875rem",
              "&:before, &:after": { display: "none" },
            },
          }}
          InputProps={{
            endAdornment: searchText && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={handleClearSearch}
                  sx={{ p: 0.5 }}
                >
                  <ClearIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      {loadingKeywords ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress size={32} />
        </Box>
      ) : (
        <>
          <List
            sx={{
              p: 0,
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            {paginatedKeywords.map((keyword, index) => (
              <ListItem
                key={keyword.id}
                sx={{
                  py: 0.75,
                  px: 2,
                  minHeight: "48px",
                  borderBottom:
                    index < paginatedKeywords.length - 1
                      ? "1px solid #e0e0e0"
                      : "none",
                  "&:hover": {
                    bgcolor: "action.hover",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleOpenForm(keyword)}
                secondaryAction={
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchKeywordCompanies(keyword.id, e);
                      }}
                      disabled={loadingCompanyId === keyword.id}
                      sx={{
                        textTransform: "none",
                        fontSize: "0.75rem",
                        minWidth: "auto",
                        py: 0.5,
                        px: 1.5,
                        height: "28px",
                      }}
                    >
                      {loadingCompanyId === keyword.id ? (
                        <CircularProgress size={14} />
                      ) : (
                        "Ver Empresas"
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenForm(keyword);
                      }}
                      sx={{
                        textTransform: "none",
                        fontSize: "0.75rem",
                        py: 0.5,
                        px: 1.5,
                        height: "28px",
                      }}
                    >
                      Editar
                    </Button>
                  </Box>
                }
              >
                <ListItemText
                  primary={keyword.text_keyword}
                  secondary={`${keyword.company_count} empresas relacionadas`}
                  sx={{
                    my: 0,
                    "& .MuiListItemText-primary": {
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      color: "text.primary",
                      lineHeight: 1.4,
                    },
                    "& .MuiListItemText-secondary": {
                      fontSize: "0.75rem",
                      color: "text.secondary",
                      lineHeight: 1.4,
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="caption" color="text.secondary">
              {filteredKeywords.length} resultados
            </Typography>
            <Pagination
              count={Math.ceil(filteredKeywords.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              size="small"
              sx={{
                "& .MuiPaginationItem-root": {
                  fontSize: "0.75rem",
                },
              }}
            />
          </Box>
        </>
      )}

      <Dialog
        open={openCompaniesModal}
        onClose={() => {
          setOpenCompaniesModal(false);
          setCurrentPage(1);
          setCompanySearchText("");
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Empresas Relacionadas</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                fullWidth
                label="Buscar empresas"
                variant="outlined"
                value={companySearchText}
                onChange={(e) => setCompanySearchText(e.target.value)}
                sx={{
                  mb: 2,
                  mt: 1,
                  "& .MuiOutlinedInput-root": {
                    height: "32px",
                  },
                  "& .MuiInputLabel-root": {
                    transform: "translate(14px, 6px) scale(0.85)",
                    "&.Mui-focused, &.MuiFormLabel-filled": {
                      transform: "translate(14px, -9px) scale(0.7)",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 10px",
                    fontSize: "0.75rem",
                  },
                }}
              />
              <TableContainer component={Paper}>
                <Table
                  size="small"
                  sx={{
                    "& td, & th": {
                      whiteSpace: "nowrap",
                      padding: "2px 8px",
                      maxWidth: "150px",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        ID
                      </TableCell>
                      <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Nombre
                      </TableCell>
                      <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Web
                      </TableCell>
                      <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        Población
                      </TableCell>
                      <TableCell sx={{ py: 0, fontSize: "0.75rem" }}>
                        CNAE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedCompanies.map((company) => (
                      <TableRow key={company.id} hover>
                        <TableCell
                          sx={{
                            py: 0,
                            fontSize: "0.75rem",
                            maxWidth: "50px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {company.id}
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0,
                            fontSize: "0.75rem",
                            maxWidth: "200px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                              "& .MuiTypography-root": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }}
                          >
                            <Typography variant="body2" component="span">
                              {company.name}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={(e) => handleShowInfo(company, e)}
                              disabled={loadingDescription}
                              sx={{
                                p: 0.25,
                                "& .MuiSvgIcon-root": {
                                  fontSize: "0.875rem",
                                },
                              }}
                            >
                              <InfoOutlined />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0,
                            fontSize: "0.75rem",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {company.web && (
                            <a
                              href={
                                company.web.startsWith("http")
                                  ? company.web
                                  : `http://${company.web}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "#1976d2",
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              title={company.web}
                            >
                              {company.web}
                            </a>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0,
                            fontSize: "0.75rem",
                            maxWidth: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              display: "block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            title={company.population}
                          >
                            {company.population}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 0,
                            fontSize: "0.75rem",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              display: "block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              fontSize: "0.75rem",
                            }}
                            title={company.cnae}
                          >
                            {company.cnae}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {displayedCompanies.length < filteredCompanies.length && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    size="small"
                    onClick={handleLoadMore}
                    sx={{
                      fontSize: "0.75rem",
                      py: 0.5,
                      px: 2,
                    }}
                  >
                    Ver más empresas ({displayedCompanies.length} de{" "}
                    {filteredCompanies.length})
                  </Button>
                  <Button
                    size="small"
                    onClick={handleLoadAll}
                    sx={{
                      fontSize: "0.75rem",
                      py: 0.5,
                      px: 2,
                    }}
                  >
                    Ver todas
                  </Button>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenCompaniesModal(false);
              setCurrentPage(1);
              setCompanySearchText("");
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
          setSelectedCompany(null);
          setSelectedCompanyDescription(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Información detallada</DialogTitle>
        <DialogContent>
          {selectedCompany && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Empresa: {selectedCompany.name}
              </Typography>
              {selectedCompany.cnae && (
                <Typography variant="body2" gutterBottom>
                  <strong>CNAE:</strong> {selectedCompany.cnae}
                </Typography>
              )}
              {selectedCompany.population && (
                <Typography variant="body2" gutterBottom>
                  <strong>Población:</strong> {selectedCompany.population}
                </Typography>
              )}
              {selectedCompany.web && (
                <Typography variant="body2" gutterBottom>
                  <strong>Web:</strong>{" "}
                  <a
                    href={
                      selectedCompany.web.startsWith("http")
                        ? selectedCompany.web
                        : `http://${selectedCompany.web}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedCompany.web}
                  </a>
                </Typography>
              )}
              {loadingDescription ? (
                <CircularProgress size={20} />
              ) : (
                selectedCompanyDescription && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Descripción:
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                      {selectedCompanyDescription}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoModal(false);
              setSelectedCompany(null);
              setSelectedCompanyDescription(null);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <KeywordForm
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSave}
        initialData={selectedKeyword}
        loading={loading}
      />
    </Box>
  );
};

export default KeywordList;
