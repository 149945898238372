import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import RichTextEditor from "../common/RichTextEditor";
import { processContent } from "../../utils/contentProcessor";

const EmailCredentialForm = ({
  open,
  onClose,
  initialData,
  onSubmit,
  clientId,
}) => {
  const [formData, setFormData] = useState({
    name_sender: "",
    email: "",
    password: "",
    server_smtp: "",
    port_smtp: "",
    server_imap: "",
    port_imap: "",
    text_closing: "",
    signature: "",
    link_email: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData({
        name_sender: "",
        email: "",
        password: "",
        server_smtp: "",
        port_smtp: "",
        server_imap: "",
        port_imap: "",
        text_closing: "",
        signature: "",
        link_email: "",
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const processedSignature = await processContent(
        formData.signature,
        clientId
      );
      const processedTextClosing = await processContent(
        formData.text_closing,
        clientId
      );

      const processedData = {
        ...formData,
        signature: processedSignature,
        text_closing: processedTextClosing,
      };

      await onSubmit(processedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar la credencial de email:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {initialData?.id
          ? "Editar Credencial de Email"
          : "Crear Credencial de Email"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Información Básica
            </Typography>
            <TextField
              fullWidth
              label="Nombre del Remitente"
              name="name_sender"
              value={formData.name_sender}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              type="email"
            />
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              required
              type="password"
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Configuración del Servidor
            </Typography>
            <TextField
              fullWidth
              label="Servidor SMTP"
              name="server_smtp"
              value={formData.server_smtp}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Puerto SMTP"
              name="port_smtp"
              value={formData.port_smtp}
              onChange={handleChange}
              margin="normal"
              required
              type="number"
            />
            <TextField
              fullWidth
              label="Servidor IMAP"
              name="server_imap"
              value={formData.server_imap}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Puerto IMAP"
              name="port_imap"
              value={formData.port_imap}
              onChange={handleChange}
              margin="normal"
              required
              type="number"
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Firma del Correo
            </Typography>
            <RichTextEditor
              value={formData.signature}
              onChange={(content) =>
                setFormData((prev) => ({ ...prev, signature: content }))
              }
              clientId={clientId}
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Texto de Cierre del Correo
            </Typography>
            <RichTextEditor
              value={formData.text_closing}
              onChange={(content) =>
                setFormData((prev) => ({ ...prev, text_closing: content }))
              }
              clientId={clientId}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {initialData?.id ? "Actualizar" : "Crear"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailCredentialForm;
