import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import campaignService from "../../services/campaignService";
import EmailPreviewModal from "../common/EmailPreviewModal";
import MultiSelect from "../common/MultiSelect";
import emailCredentialService from "../../services/emailCredentialService";
import { es } from "date-fns/locale";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, endOfMonth, startOfMonth } from "date-fns";
import fileService from "../../services/fileService";
import { resolveContent } from "../../utils/contentProcessor";
import keywordService from "../../services/keywordService";

const MonthlyReportDialog = ({ open, onClose, onGenerate, isLoading }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const handleGenerate = () => {
    const startDate = startOfMonth(selectedMonth);
    const endDate = endOfMonth(selectedMonth);
    onGenerate({ startDate, endDate });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Seleccionar Mes para el Reporte</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <DatePicker
            label="Mes"
            value={selectedMonth}
            onChange={setSelectedMonth}
            views={["month", "year"]}
            format="MMMM yyyy"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleGenerate}
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : "Generar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CampaignStats = () => {
  const { clientId, campaignId } = useParams();
  const { user } = useAuth();
  const [stats, setStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [filters, setFilters] = useState({
    company: "",
    type: [],
    response: [],
  });
  const [showOnlyWithResponse, setShowOnlyWithResponse] = useState(false);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [openMonthSelector, setOpenMonthSelector] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const isInitialMount = useRef(true);
  const imageCache = useRef(new Map());
  const [content, setContent] = useState("");
  const [openCompanyInfo, setOpenCompanyInfo] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyDescription, setCompanyDescription] = useState(null);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [emailCredential, setEmailCredential] = useState(null);
  const [processSignature, setProcessSignature] = useState(true);

  const templateTypeOptions = [
    { value: "presentation", label: "Presentación" },
    { value: "followup", label: "Seguimiento" },
    { value: "rejection", label: "Rechazo" },
  ];

  const responseTypeOptions = [
    { value: "AUTOMATIC", label: "Respuesta Automática" },
    { value: "INTERESTED", label: "Interesado" },
    { value: "NOT_INTERESTED", label: "No Interesado" },
    { value: "EMAIL_CHANGED", label: "Email Cambiado" },
    { value: "UNDELIVERED", label: "No Entregado" },
  ];

  const buttonStyle = {
    minWidth: "auto",
    py: 0.25,
    px: 1,
    fontSize: "0.75rem",
    height: "24px",
    whiteSpace: "nowrap",
  };

  const processImageContent = useCallback(
    async (htmlContent) => {
      if (!htmlContent) return htmlContent;

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      const images = Array.from(doc.getElementsByTagName("img"));

      await Promise.all(
        images.map(async (img) => {
          if (img.src.startsWith("cid:")) {
            const fileId = img.src.replace("cid:", "");

            try {
              if (imageCache.current.has(fileId)) {
                img.src = imageCache.current.get(fileId);
                return;
              }

              const fileResponse = await fileService.getFile(clientId, fileId);
              const blob = new Blob([fileResponse], { type: "image/png" });
              const url = URL.createObjectURL(blob);

              await new Promise((resolve, reject) => {
                const tempImg = new Image();
                tempImg.onload = () => resolve();
                tempImg.onerror = () => reject();
                tempImg.src = url;
              });

              imageCache.current.set(fileId, url);
              img.src = url;
            } catch (error) {
              console.error("Error loading image:", error);
            }
          }
        })
      );

      return doc.body.innerHTML;
    },
    [clientId]
  );

  const handleGenerateReport = async ({ startDate, endDate }) => {
    setIsLoading(true);
    try {
      await campaignService.generateCredentialsReport(
        clientId,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      setOpenMonthSelector(false);
    } catch (error) {
      console.error("Error al generar el reporte:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTemplateTypeLabel = (type) => {
    const typeMap = {
      presentation: "Presentación",
      followup: "Seguimiento",
      rejection: "Rechazo",
    };
    return typeMap[type] || type;
  };

  const getResponseTypeLabel = (type) => {
    const responseMap = {
      AUTOMATIC: "Respuesta Automática",
      INTERESTED: "Interesado",
      NOT_INTERESTED: "No Interesado",
      EMAIL_CHANGED: "Email Cambiado",
      UNDELIVERED: "No Entregado",
    };
    return responseMap[type] || "-";
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await campaignService.getCampaignEmailStats(
          clientId,
          campaignId
        );
        setStats(response.stats);
        setFilteredStats(response.stats);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };
    fetchStats();
  }, [clientId, campaignId]);

  useEffect(() => {
    let filtered = [...stats];

    if (filters.company) {
      filtered = filtered.filter((stat) =>
        stat.company_name.toLowerCase().includes(filters.company.toLowerCase())
      );
    }
    if (filters.type.length > 0) {
      filtered = filtered.filter((stat) =>
        filters.type.includes(stat.template_type)
      );
    }
    if (filters.response.length > 0) {
      filtered = filtered.filter((stat) =>
        filters.response.includes(stat.type_response)
      );
    }

    if (showOnlyWithResponse) {
      filtered = filtered.filter((stat) => stat.response !== null);
    }

    setFilteredStats(filtered);
    setPage(0);
  }, [stats, filters, showOnlyWithResponse]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al cargar las credenciales de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const campaignData = await campaignService.getCampaign(
          clientId,
          campaignId
        );
        setCampaign(campaignData);
      } catch (error) {
        console.error("Error al cargar la campaña:", error);
      }
    };

    fetchCampaign();
  }, [clientId, campaignId]);

  useEffect(() => {
    const loadContent = async () => {
      if (campaign && isInitialMount.current) {
        try {
          const resolvedContent = await resolveContent(
            campaign.text_personal_connection,
            clientId
          );
          const contentWithImages = await processImageContent(resolvedContent);
          setContent(contentWithImages);
          isInitialMount.current = false;
        } finally {
          // Capturar el valor actual del imageCache para la limpieza
          const currentImageCache = imageCache.current;
          return () => {
            currentImageCache.forEach((url) => URL.revokeObjectURL(url));
            currentImageCache.clear();
          };
        }
      }
    };
    loadContent();
  }, [campaign, clientId, processImageContent]);

  useEffect(() => {
    if (
      !isInitialMount.current &&
      campaign &&
      campaign.text_personal_connection !== content
    ) {
      const updateContent = async () => {
        const processedContent = await processImageContent(
          campaign.text_personal_connection
        );
        setContent(processedContent);
      };
      updateContent();
    }
  }, [campaign, content, processImageContent]);

  const handleViewEmail = (email) => {
    setSelectedEmail(email);

    // Determinar si debemos procesar la firma
    // Si el cuerpo ya contiene la firma, no la procesamos
    const shouldProcessSignature = !(
      email?.body &&
      emailCredential?.signature &&
      email.body.includes(emailCredential.signature)
    );

    setProcessSignature(shouldProcessSignature);
    setOpenEmailPreview(true);
  };

  const handleViewResponse = (response) => {
    // Si no hay body_raw, usar body normal
    if (!response.body_raw) {
      setSelectedResponse({
        ...response,
        body: response.body || "",
        isHtml: false,
      });
      setOpenResponseDialog(true);
      return;
    }

    const isHtml =
      response.body_raw.includes("<") && response.body_raw.includes(">");

    if (isHtml) {
      // Procesamos el HTML para mejorar su visualización
      let processedHtml = response.body_raw;

      // 1. Reemplazar entidades HTML
      processedHtml = processedHtml
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, '"');

      // 2. Corregir etiquetas <font> escapadas
      processedHtml = processedHtml
        .replace(/&lt;font([^&]*)&gt;/g, "<span$1>")
        .replace(/&lt;\/font&gt;/g, "</span>");

      // 3. Corregir etiquetas <font> normales
      processedHtml = processedHtml
        .replace(
          /<font color="([^"]+)" face="([^"]+)">/g,
          '<span style="color: $1; font-family: $2;">'
        )
        .replace(/<font face="([^"]+)">/g, '<span style="font-family: $1;">')
        .replace(/<font color="([^"]+)">/g, '<span style="color: $1;">')
        .replace(/<\/font>/g, "</span>");

      // 4. Limpiar etiquetas de imagen mal formadas
      processedHtml = processedHtml
        // Eliminar etiquetas de imagen vacías
        .replace(/<img[^>]*>\s*<\/img>/g, "")
        .replace(/<img[^>]*\/>\s*<\/img>/g, "")
        .replace(/<img[^>]*>(\s*)<\/img>/g, "")
        .replace(/<img\s*\/>/g, "")
        .replace(/<img\s*><\/img>/g, "")

        // Corregir imágenes con atributos de altura y anchura
        .replace(
          /<img height="(\d+)" src="([^"]+)" width="(\d+)"[^>]*>/g,
          '<img src="$2" style="max-width: 100%; height: auto;">'
        )

        // Corregir imágenes con alt
        .replace(
          /<img alt="([^"]*)" height="(\d+)" src="([^"]+)" width="(\d+)"[^>]*>/g,
          '<img src="$3" alt="$1" style="max-width: 100%; height: auto;">'
        )

        // Eliminar atributos style="max-width: 100%; height: auto;" duplicados
        .replace(
          /style="max-width: 100%; height: auto;"\s*style="max-width: 100%; height: auto;"/g,
          'style="max-width: 100%; height: auto;"'
        );

      // 5. Eliminar etiquetas de imagen rotas o incompletas
      processedHtml = processedHtml.replace(
        /" style="max-width: 100%; height: auto;">/g,
        function (match) {
          if (!processedHtml.includes("<img src=")) {
            return "";
          }
          return match;
        }
      );

      // 6. Crear un documento HTML completo con estilos ajustados
      const htmlDocument = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <base href="https://mail.google.com/" target="_blank">
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.3;
                color: #333;
                margin: 0;
                padding: 10px;
              }
              img {
                max-width: 100%;
                height: auto;
                display: inline-block;
                margin: 2px 0;
              }
              a {
                color: #1976d2;
                text-decoration: underline;
              }
              p {
                margin: 4px 0;
              }
              div {
                margin: 0;
                padding: 0;
              }
              .gmail_default {
                font-family: Arial, sans-serif;
                margin: 4px 0;
              }
              blockquote {
                border-left: 3px solid #ccc;
                padding-left: 10px;
                margin-left: 10px;
              }
              ul, ol {
                padding-left: 20px;
                margin: 4px 0;
              }
              br {
                line-height: 1;
              }
            </style>
          </head>
          <body>
            ${processedHtml}
          </body>
        </html>
      `;

      setSelectedResponse({
        ...response,
        body: htmlDocument,
        isHtml: true,
        rawHtml: processedHtml,
      });
    } else {
      // Si no es HTML, mostrar como texto plano
      setSelectedResponse({
        ...response,
        body: response.body_raw,
        isHtml: false,
      });
    }

    setOpenResponseDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortByDate = () => {
    setSortDirection((prev) => (prev === "desc" ? "asc" : "desc"));
    setFilteredStats((prev) =>
      [...prev].sort((a, b) => {
        const dateA = new Date(a.datetime_insert);
        const dateB = new Date(b.datetime_insert);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      })
    );
  };

  const handleShowCompanyInfo = async (stat, event) => {
    event.stopPropagation();
    try {
      setLoadingDescription(true);
      setSelectedCompany({
        id: stat.company_id,
        name: stat.company_name,
        web: stat.web,
        population: stat.population,
        cnae: stat.cnae,
      });
      setOpenCompanyInfo(true);

      // Obtener descripción de la empresa
      const response = await keywordService.getCompanyDescription(
        stat.company_id
      );
      setCompanyDescription(response.description);
    } catch (error) {
      console.error("Error al obtener la descripción:", error);
    } finally {
      setLoadingDescription(false);
    }
  };

  const isNormalUser = user.user
    ? user.user?.id_client != null
    : user?.id_client != null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Cambiar a columna en pantallas pequeñas
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
            mb: 2,
            gap: 2, // Separar elementos verticalmente en pantallas pequeñas
          }}
        >
          <Typography variant="h4" gutterBottom>
            Estadísticas de Campaña
          </Typography>
          <Button
            variant="outlined"
            size="medium"
            sx={{
              minWidth: "100px",
              py: 1,
              px: 2,
              fontSize: "1rem",
              height: "40px",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setOpenMonthSelector(true)}
            disabled={isLoading}
          >
            Generar Report
          </Button>
        </Box>

        <MonthlyReportDialog
          open={openMonthSelector}
          onClose={() => setOpenMonthSelector(false)}
          onGenerate={handleGenerateReport}
          isLoading={isLoading}
        />
        <Box
          sx={{
            mb: 3,
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <TextField
            label="Filtrar por empresa"
            value={filters.company}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, company: e.target.value }))
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
            }}
          />
          <MultiSelect
            options={templateTypeOptions}
            value={filters.type}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, type: value }))
            }
            label="Tipo de email"
            placeholder="Todos los tipos"
            height="40px"
          />
          <MultiSelect
            options={responseTypeOptions}
            value={filters.response}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, response: value }))
            }
            label="Tipo de respuesta"
            placeholder="Todas las respuestas"
            height="40px"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyWithResponse}
                onChange={(e) => setShowOnlyWithResponse(e.target.checked)}
                size="small"
              />
            }
            label="Solo con respuesta"
            sx={{ m: 0 }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={handleSortByDate}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    position: "relative",
                    pr: 4,
                    minWidth: "120px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Fecha envío
                    <Box
                      sx={{
                        position: "absolute",
                        right: 8,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {sortDirection === "desc" ? (
                        <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <KeyboardArrowUpIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>CNAE</TableCell>
                <TableCell>Keyword</TableCell>
                {!isNormalUser && <TableCell>Fuente</TableCell>}
                <TableCell>Tipo de email</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tipo de respuesta</TableCell>
                <TableCell>Fecha respuesta</TableCell>
                <TableCell>Respuesta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStats
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((stat, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "32px",
                    }}
                  >
                    <TableCell sx={{ py: 0.5 }}>
                      {new Date(stat.datetime_insert).toLocaleString()}
                    </TableCell>
                    <TableCell
                      sx={{
                        py: 0.5,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          color: "primary.main",
                        },
                      }}
                      onClick={(e) => handleShowCompanyInfo(stat, e)}
                    >
                      {stat.company_name}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>{stat.cnae || "-"}</TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {stat.keyword || "-"}
                      {(stat.extra_keyword || stat.extra2_keyword) && " ("}
                      {stat.extra_keyword}
                      {stat.extra_keyword && stat.extra2_keyword && ", "}
                      {stat.extra2_keyword}
                      {(stat.extra_keyword || stat.extra2_keyword) && ")"}
                    </TableCell>
                    {!isNormalUser && (
                      <TableCell sx={{ py: 0.5 }}>{stat.source_name}</TableCell>
                    )}
                    <TableCell sx={{ py: 0.5 }}>
                      {getTemplateTypeLabel(stat.template_type)}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={buttonStyle}
                        onClick={() => handleViewEmail(stat)}
                      >
                        Ver email
                      </Button>
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {getResponseTypeLabel(stat.type_response)}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {stat.datetime_response
                        ? new Date(stat.datetime_response).toLocaleString()
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {stat.response ? (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={buttonStyle}
                          onClick={() => handleViewResponse(stat.response)}
                        >
                          Ver respuesta
                        </Button>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredStats.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            sx={{
              ".MuiTablePagination-select": {
                py: 0.5,
              },
            }}
          />
        </TableContainer>

        {selectedEmail && (
          <EmailPreviewModal
            open={openEmailPreview}
            onClose={() => {
              setOpenEmailPreview(false);
              setSelectedEmail(null);
            }}
            signature={
              // Solo incluir la firma si processSignature es true y la firma no está ya en el body
              processSignature &&
              selectedEmail?.body &&
              !selectedEmail.body.includes(emailCredential?.signature)
                ? emailCredential?.signature || ""
                : ""
            }
            body={selectedEmail?.body || ""}
            textClosing={campaign?.text_closing || ""}
            textPersonalConnection={""}
            textCompanyConnection={""}
            clientId={clientId}
            subject={selectedEmail?.subject || ""}
            templateId={selectedEmail?.template_id}
            processSignature={processSignature}
          />
        )}

        <Dialog
          open={openResponseDialog}
          onClose={() => setOpenResponseDialog(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              maxHeight: "90vh",
              width: "80%",
              maxWidth: "800px",
            },
          }}
        >
          <DialogTitle>{selectedResponse?.subject || ""}</DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: 1,
              height: "70vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {selectedResponse?.isHtml ? (
              <iframe
                srcDoc={selectedResponse?.body}
                title="Email content"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  flexGrow: 1,
                }}
                sandbox="allow-same-origin allow-popups allow-forms"
                referrerPolicy="no-referrer"
                loading="eager"
                onLoad={(e) => {
                  // Script para ajustar el contenido del iframe después de cargar
                  try {
                    const iframeDoc = e.target.contentDocument;
                    if (iframeDoc) {
                      // Eliminar elementos de imagen vacíos o rotos
                      const images = iframeDoc.querySelectorAll("img");
                      images.forEach((img) => {
                        if (
                          !img.src ||
                          img.src === "about:blank" ||
                          img.src.includes("undefined")
                        ) {
                          img.style.display = "none";
                        }
                      });

                      // Ajustar espaciado
                      const divs = iframeDoc.querySelectorAll("div");
                      divs.forEach((div) => {
                        if (
                          div.innerHTML === "<br>" ||
                          div.innerHTML === "<br/>"
                        ) {
                          div.style.margin = "0";
                          div.style.padding = "0";
                          div.style.lineHeight = "0.8";
                        }
                      });
                    }
                  } catch (error) {
                    console.error("Error al ajustar el iframe:", error);
                  }
                }}
              />
            ) : (
              <Box sx={{ padding: 3, height: "100%", overflow: "auto" }}>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {selectedResponse?.body || ""}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenResponseDialog(false)}>Cerrar</Button>
            {selectedResponse?.isHtml && (
              <Button
                onClick={() => {
                  const win = window.open("", "_blank");
                  win.document.write(selectedResponse.body);
                  win.document.close();
                }}
              >
                Ver en nueva ventana
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCompanyInfo}
          onClose={() => {
            setOpenCompanyInfo(false);
            setSelectedCompany(null);
            setCompanyDescription(null);
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Información detallada</DialogTitle>
          <DialogContent>
            {selectedCompany && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Empresa: {selectedCompany.name}
                </Typography>
                {selectedCompany.cnae && (
                  <Typography variant="body2" gutterBottom>
                    <strong>CNAE:</strong> {selectedCompany.cnae}
                  </Typography>
                )}
                {selectedCompany.population && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Población:</strong> {selectedCompany.population}
                  </Typography>
                )}
                {selectedCompany.web && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Web:</strong>{" "}
                    <a
                      href={
                        selectedCompany.web.startsWith("http")
                          ? selectedCompany.web
                          : `http://${selectedCompany.web}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedCompany.web}
                    </a>
                  </Typography>
                )}
                {loadingDescription ? (
                  <CircularProgress size={20} />
                ) : (
                  companyDescription && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Descripción:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {companyDescription}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenCompanyInfo(false);
                setSelectedCompany(null);
                setCompanyDescription(null);
              }}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default CampaignStats;
