import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  OutlinedInput,
} from "@mui/material";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  renderOption,
  placeholder = "Cualquier opción",
  height = "40px",
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel
        sx={{
          lineHeight: "1rem",
          transform: "translate(14px, 8px) scale(1)",
          "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            label={label}
            sx={{
              height: height,
              ".MuiSelect-select": {
                paddingTop: "5px",
                paddingBottom: "5px",
                minHeight: "unset !important",
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.length === 0 ? (
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>{placeholder}</span>
            ) : (
              selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    options.find((opt) => opt.value === value)?.label || value
                  }
                  size="small"
                  sx={{ height: "24px" }}
                />
              ))
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ minHeight: ITEM_HEIGHT }}
          >
            {renderOption ? renderOption(option) : option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
