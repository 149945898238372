import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  Grid,
  Alert,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import exclusionService from "../../services/exclusionService";

const ClientExclusions = ({ clientId }) => {
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [columnConfig, setColumnConfig] = useState({
    name: { column: "", row: "" },
    cif: { column: "", row: "" },
    email: { column: "", row: "" },
    state: { column: "", row: "" },
    city: { column: "", row: "" },
    postal_code: { column: "", row: "" },
    phone: { column: "", row: "" },
  });
  const [startRow, setStartRow] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tooltips = {
    name: "Campo obligatorio - Columna y fila donde comienza el listado de nombres de empresa",
    cif: "Opcional - Columna y fila donde comienza el listado de CIF/NIF",
    email: "Opcional - Columna y fila donde comienza el listado de emails",
    state: "Opcional - Columna y fila donde comienza el listado de provincias",
    city: "Opcional - Columna y fila donde comienza el listado de ciudades",
    postal_code:
      "Opcional - Columna y fila donde comienza el listado de códigos postales",
    phone: "Opcional - Columna y fila donde comienza el listado de teléfonos",
  };

  useEffect(() => {
    // Cleanup cuando el componente se desmonta
    return () => {
      if (fileId) {
        exclusionService.cleanup(clientId, fileId).catch(console.error);
      }
    };
  }, [clientId, fileId]);

  const handleColumnChange = (field, type, value) => {
    setColumnConfig((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [type]: value.toUpperCase(),
      },
    }));
  };

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      const formData = new FormData();
      formData.append("file", uploadedFile);

      try {
        setLoading(true);
        setError(null);
        const response = await exclusionService.uploadFile(clientId, formData);
        setAvailableColumns(response.columns);
        setFileId(response.file_id);
        setSuccess("Archivo cargado correctamente");
      } catch (err) {
        setError(
          "Error al procesar el archivo. Asegúrate de que el formato es correcto."
        );
        setFile(null);
        setFileId(null);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePreview = async () => {
    if (!columnConfig.name.column || !columnConfig.name.row) {
      setError("La columna y fila del nombre de empresa son obligatorias");
      return;
    }

    if (!fileId) {
      setError("No se ha cargado ningún archivo");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const config = { columnConfig };

      const preview = await exclusionService.getPreview(
        clientId,
        config,
        fileId
      );
      setEditableData(
        preview.data.map((row, index) => ({ ...row, id: index }))
      );
      setSuccess("Vista previa generada correctamente");
    } catch (err) {
      setError("Error al generar la vista previa");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!editableData.length) {
      setError("No hay datos para guardar");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      await exclusionService.saveExclusions(clientId, editableData);
      setSuccess("Exclusiones guardadas correctamente");

      // Limpiar el estado después de guardar
      setFile(null);
      setFileId(null);
      setAvailableColumns([]);
      setEditableData([]);
      setColumnConfig({
        name: { column: "", row: "" },
        cif: { column: "", row: "" },
        email: { column: "", row: "" },
        state: { column: "", row: "" },
        city: { column: "", row: "" },
        postal_code: { column: "", row: "" },
        phone: { column: "", row: "" },
      });
      setStartRow(1);
    } catch (err) {
      setError("Error al guardar las exclusiones");
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar cambios en la tabla
  const handleCellEdit = (params) => {
    const updatedData = editableData.map((row) =>
      row.id === params.id ? { ...row, [params.field]: params.value } : row
    );
    setEditableData(updatedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteRow = (id) => {
    setEditableData(editableData.filter((row) => row.id !== id));
  };

  // Estilos comunes para inputs
  const inputStyles = {
    "& .MuiInputBase-input": {
      height: "8px", // Altura del input
      fontSize: "0.875rem", // Tamaño de fuente del input
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.875rem", // Tamaño de fuente del label
      transform: "translate(14px, 8px) scale(1)", // Posición del label
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)", // Posición del label cuando está activo
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)", // Color del borde
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Color del borde al hover
      },
    },
  };

  return (
    <Box sx={{ width: "100%", p: isMobile ? 1 : 2 }}>
      <Card elevation={3}>
        <CardContent sx={{ p: isMobile ? 1 : 2 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
            Importar Exclusiones
          </Typography>

          {/* File Upload Section */}
          <Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 1 }}>
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
                disabled={loading}
                size="small"
                sx={{
                  height: "32px",
                  textTransform: "none",
                  fontWeight: 400,
                }}
              >
                Seleccionar Excel
              </Button>
            </label>
            {file && (
              <Typography variant="caption" color="textSecondary">
                {file.name}
              </Typography>
            )}
          </Box>

          {fileId && (
            <Card variant="outlined" sx={{ mb: 2, p: 1.5 }}>
              <Grid container spacing={1.5}>
                {Object.entries(columnConfig).map(([field, value]) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={field}>
                    <Box
                      sx={{
                        p: 1,
                        border: "1px solid",
                        borderColor:
                          field === "name" ? "error.main" : "divider",
                        borderRadius: 1,
                        bgcolor:
                          field === "name"
                            ? "error.lighter"
                            : "background.paper",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            flexGrow: 1,
                            fontWeight: field === "name" ? 500 : 400,
                            color:
                              field === "name" ? "error.main" : "text.primary",
                          }}
                        >
                          {field === "name" ? "Nombre *" : field}
                        </Typography>
                        <Tooltip title={tooltips[field]} placement="top">
                          <IconButton size="small" sx={{ p: 0.25 }}>
                            <HelpOutlineIcon sx={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            placeholder="Columna"
                            value={value.column}
                            onChange={(e) =>
                              handleColumnChange(
                                field,
                                "column",
                                e.target.value
                              )
                            }
                            fullWidth
                            sx={inputStyles}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            size="small"
                            placeholder="Fila"
                            value={value.row}
                            onChange={(e) =>
                              handleColumnChange(field, "row", e.target.value)
                            }
                            fullWidth
                            sx={inputStyles}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  onClick={handlePreview}
                  disabled={loading}
                  size="small"
                  sx={{
                    height: "32px",
                    textTransform: "none",
                    fontWeight: 400,
                  }}
                >
                  Vista Previa
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setFile(null);
                    setFileId(null);
                    setEditableData([]);
                  }}
                  startIcon={<DeleteIcon />}
                  disabled={loading}
                  size="small"
                  sx={{
                    height: "32px",
                    textTransform: "none",
                    fontWeight: 400,
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            </Card>
          )}

          {/* Messages */}
          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 1,
                "& .MuiAlert-message": {
                  padding: "2px 0",
                },
              }}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{
                mb: 1,
                "& .MuiAlert-message": {
                  padding: "2px 0",
                },
              }}
            >
              {success}
            </Alert>
          )}

          {/* Preview Table */}
          {editableData.length > 0 && (
            <Box sx={{ width: "100%", overflow: "auto" }}>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  "& th": {
                    backgroundColor: theme.palette.grey[50],
                    borderBottom: `2px solid ${theme.palette.divider}`,
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    fontSize: "0.75rem", // Reduced header font size
                    padding: "8px 6px", // Reduced padding
                  },
                  "& td": {
                    padding: "4px 6px", // Reduced cell padding
                  },
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Nombre</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>CIF/NIF</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Provincia</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Ciudad</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>C.P.</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Teléfono</TableCell>
                    <TableCell
                      sx={{ width: 40, padding: "8px 0px !important" }}
                    />{" "}
                    {/* Reduced width for actions column */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.palette.action.hover,
                          },
                          "& td": {
                            borderBottom: `1px solid ${theme.palette.divider}`,
                          },
                        }}
                      >
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.name || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "name",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem", // Reduced font size
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.cif || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "cif",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.email || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "email",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.state || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "state",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.city || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "city",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.postal_code || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "postal_code",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="standard"
                            value={row.phone || ""}
                            onChange={(e) =>
                              handleCellEdit({
                                id: row.id,
                                field: "phone",
                                value: e.target.value,
                              })
                            }
                            sx={{
                              "& .MuiInput-input": {
                                padding: "2px 0",
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ padding: "0 8px !important", width: 40 }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteRow(row.id)}
                            sx={{
                              color: theme.palette.error.main,
                              padding: "2px",
                              "&:hover": {
                                backgroundColor: theme.palette.error.lighter,
                              },
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              {/* Pagination */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1, // Reduced padding
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
              >
                <TablePagination
                  component="div"
                  count={editableData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      minHeight: "36px", // Reduced height
                    },
                    ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                      {
                        fontSize: "0.75rem", // Reduced font size
                      },
                  }}
                />
              </Box>

              {/* Actions */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                  p: 1, // Reduced padding
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={loading}
                  size="small"
                  sx={{
                    height: "28px", // Reduced height
                    textTransform: "none",
                    fontWeight: 400,
                    fontSize: "0.75rem", // Reduced font size
                  }}
                >
                  Guardar Exclusiones
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ClientExclusions;
