import axios from "axios";
import keywordService from "./keywordService";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const verticalService = {
  getClientVerticals: async (clientId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/verticals`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching verticals:", error);
      throw error;
    }
  },

  getVerticalWithKeywords: async (clientId, verticalId) => {
    try {
      const [verticalResponse, keywordsResponse] = await Promise.all([
        axios.get(`${API_URL}/clients/${clientId}/verticals/${verticalId}`, {
          headers: getAuthHeader(),
        }),
        keywordService.getVerticalKeywords(clientId, verticalId),
      ]);

      return {
        ...verticalResponse.data,
        keywords: keywordsResponse.map((k) => k.id),
      };
    } catch (error) {
      console.error("Error fetching vertical with keywords:", error);
      throw error;
    }
  },

  createVertical: async (clientId, verticalData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/verticals`,
        verticalData,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  },

  updateVertical: async (clientId, verticalId, verticalData) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}`,
        verticalData,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  },

  getVerticalStats: async (clientId, verticalId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/stats`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching vertical stats:", error);
      throw error;
    }
  },

  updateVerticalKeywordExtra: async (
    clientId,
    verticalId,
    keywordId,
    extraKeywordId
  ) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords/${keywordId}/extra`,
        { id_keyword_extra: extraKeywordId },
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating keyword extra:", error);
      throw error;
    }
  },

  removeKeywordFromVertical: async (clientId, verticalId, keywordId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords/${keywordId}`,
        { headers: getAuthHeader() }
      );
      return response.data;
    } catch (error) {
      console.error("Error removing keyword from vertical:", error);
      throw error;
    }
  },

  updateKeywordExtra2: async (
    clientId,
    verticalId,
    keywordId,
    extraKeywordId
  ) => {
    const response = await fetch(
      `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords/${keywordId}/extra2`,
      {
        method: "PUT",
        headers: {
          ...getAuthHeader(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_keyword_extra2: extraKeywordId }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Error updating keyword extra2");
    }

    return response.json();
  },

  addKeywordToVertical: async (clientId, verticalId, keywordId) => {
    try {
      console.log(
        `Llamando a addKeywordToVertical: clientId=${clientId}, verticalId=${verticalId}, keywordId=${keywordId}, tipo de keywordId=${typeof keywordId}`
      );

      // Asegurarse de que keywordId sea un número
      const numericKeywordId = Number(keywordId);
      console.log(
        `keywordId convertido a número: ${numericKeywordId}, tipo: ${typeof numericKeywordId}`
      );

      // Crear el objeto de datos con la estructura correcta
      const data = { keyword_ids: [numericKeywordId] };
      console.log("Datos a enviar:", JSON.stringify(data));

      const response = await axios.post(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}/keywords`,
        data,
        { headers: getAuthHeader() }
      );

      console.log("Respuesta del servidor:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error adding keyword to vertical:", error);
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  },
};

export default verticalService;
