import fileService from "../services/fileService";

const processPreviewContent = async (content, clientId) => {
  if (!content) return content;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const images = Array.from(doc.getElementsByTagName("img"));

  await Promise.all(
    images.map(async (img) => {
      if (img.src.startsWith("cid:")) {
        try {
          const fileId = img.src.replace("cid:", "");
          const fileResponse = await fileService.getFile(clientId, fileId);

          // Convert blob to base64 URL
          const blob = new Blob([fileResponse], { type: "image/png" });
          const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });

          img.src = base64;
        } catch (error) {
          console.error("Error processing image:", error);
        }
      }
    })
  );

  return doc.body.innerHTML;
};

export default processPreviewContent;
