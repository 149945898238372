import axios from "axios";

//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const previewService = {
  getPreviewData: async (clientId, templateId, tab) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/templates/${templateId}/preview-data`,
        {
          params: { tab },
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching preview data:", error);
      throw error;
    }
  },
};

export default previewService;
