import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import emailCredentialService from "../../services/emailCredentialService";
import EmailCredentialForm from "./EmailCredentialForm";

const EmailCredentialsList = ({ clientId }) => {
  const [credentials, setCredentials] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);

  const fetchCredentials = useCallback(async () => {
    try {
      const data = await emailCredentialService.getEmailCredentials(clientId);
      setCredentials(data);
    } catch (error) {
      console.error("Error al obtener credenciales:", error);
    }
  }, [clientId]);

  useEffect(() => {
    fetchCredentials();
  }, [fetchCredentials]);

  const handleOpenForm = (credential = null) => {
    setSelectedCredential(credential);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedCredential(null);
  };

  const handleSave = async (credentialData) => {
    try {
      if (selectedCredential) {
        await emailCredentialService.updateEmailCredential(
          clientId,
          selectedCredential.id,
          credentialData
        );
      } else {
        await emailCredentialService.createEmailCredential(
          clientId,
          credentialData
        );
      }
      fetchCredentials();
      handleCloseForm();
    } catch (error) {
      console.error("Error al guardar la credencial de correo:", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Credenciales de Correo
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm()}
        style={{ marginBottom: "20px" }}
      >
        Agregar Nueva Credencial
      </Button>
      <List>
        {credentials.map((credential) => (
          <ListItem
            key={credential.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 2,
              p: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
                transition: "background-color 0.3s",
                cursor: "pointer",
              },
            }}
            onClick={() => handleOpenForm(credential)}
          >
            <ListItemText
              primary={
                <Typography variant="h6" component="div">
                  {credential.email}
                </Typography>
              }
              secondary={
                <Typography variant="body2" color="text.secondary">
                  Remitente: {credential.name_sender}
                  <br />
                  SMTP: {credential.server_smtp}:{credential.port_smtp}
                  <br />
                  IMAP: {credential.server_imap}:{credential.port_imap}
                </Typography>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenForm(credential);
              }}
            >
              Editar
            </Button>
          </ListItem>
        ))}
      </List>

      <EmailCredentialForm
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSave}
        initialData={selectedCredential}
        clientId={clientId}
      />
    </div>
  );
};

export default EmailCredentialsList;
