import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import campaignService from "../../services/campaignService";

const CampaignList = () => {
  const { clientId } = useParams();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const campaignsData = await campaignService.getCampaigns(clientId);
        setCampaigns(campaignsData);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };
    fetchCampaigns();
  }, [clientId]);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Campañas
      </Typography>
      <Button
        component={Link}
        to={`/clients/${clientId}/campaigns/new`}
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        Crear Nueva Campaña
      </Button>
      <List>
        {campaigns.map((campaign) => (
          <ListItem
            key={campaign.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 2,
              p: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            <ListItemText
              primary={campaign.name_campaign}
              secondary={
                <>
                  Fecha de inicio: {campaign.datetime_start || "No iniciada"}
                  <br />
                  Correos enviados: {campaign.emails_sent} (
                  {campaign.unique_contacts} contactos únicos,{" "}
                  {campaign.total_companies} empresas)
                </>
              }
            />
            <Button
              component={Link}
              to={`/clients/${clientId}/campaigns/${campaign.id}`}
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Editar
            </Button>
            <Button
              component={Link}
              to={`/clients/${clientId}/campaigns/${campaign.id}/templates`}
              variant="outlined"
              color="primary"
            >
              Ver Plantillas
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default CampaignList;
