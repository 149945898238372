import axios from "axios";
import keywordService from "./keywordService";
//dev
//const API_URL = "http://localhost:7000/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";
const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const verticalService = {
  getClientVerticals: async (clientId) => {
    try {
      const response = await axios.get(
        `${API_URL}/clients/${clientId}/verticals`,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching client verticals:", error);
      throw error;
    }
  },
  getVerticalWithKeywords: async (clientId, verticalId) => {
    try {
      const [verticalResponse, keywordsResponse] = await Promise.all([
        axios.get(`${API_URL}/clients/${clientId}/verticals/${verticalId}`, {
          headers: getAuthHeader(),
        }),
        keywordService.getVerticalKeywords(clientId, verticalId),
      ]);

      return {
        ...verticalResponse.data,
        keywords: keywordsResponse.map((k) => k.id),
      };
    } catch (error) {
      console.error("Error fetching vertical with keywords:", error);
      throw error;
    }
  },
  createClientVertical: async (clientId, verticalData) => {
    try {
      const response = await axios.post(
        `${API_URL}/clients/${clientId}/verticals`,
        { name_vertical: verticalData.name_vertical },
        {
          headers: getAuthHeader(),
        }
      );

      if (verticalData.keywords?.length > 0) {
        await keywordService.assignKeywordsToVertical(
          clientId,
          response.data.id,
          verticalData.keywords
        );
      }

      return response.data;
    } catch (error) {
      console.error("Error creating vertical:", error);
      throw error;
    }
  },

  updateClientVertical: async (clientId, verticalId, verticalData) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}/verticals/${verticalId}`,
        { name_vertical: verticalData.name_vertical },
        {
          headers: getAuthHeader(),
        }
      );

      if (verticalData.keywords?.length > 0) {
        await keywordService.assignKeywordsToVertical(
          clientId,
          verticalId,
          verticalData.keywords
        );
      }

      return response.data;
    } catch (error) {
      console.error("Error updating vertical:", error);
      throw error;
    }
  },
};

export default verticalService;
