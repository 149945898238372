import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  TextField,
  Paper,
  Collapse,
  IconButton,
  Chip,
  InputAdornment,
  Pagination,
  Alert,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Delete as DeleteIcon,
  Clear as ClearIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import verticalService from "../../services/verticalService";
import VerticalForm from "./VerticalForm";
import KeywordExtraDialog from "./KeywordExtraDialog";
import SearchIcon from "@mui/icons-material/Search";
import keywordService from "../../services/keywordService";

const VerticalList = ({ clientId }) => {
  const [verticals, setVerticals] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedVerticals, setExpandedVerticals] = useState({});
  const [keywordExtraDialogOpen, setKeywordExtraDialogOpen] = useState(false);
  const [selectedKeywordId, setSelectedKeywordId] = useState(null);
  const [selectedVerticalId, setSelectedVerticalId] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const [openKeywordExtra2Dialog, setOpenKeywordExtra2Dialog] = useState(false);
  const [editingVerticals, setEditingVerticals] = useState({});
  const [savingVerticals, setSavingVerticals] = useState({});
  const [availableKeywords, setAvailableKeywords] = useState([]);

  const fetchVerticals = useCallback(async () => {
    try {
      setLoading(true);
      const data = await verticalService.getClientVerticals(clientId);

      // Asegurarse de que todos los campos necesarios estén presentes en cada keyword
      const processedData = data.map((vertical) => ({
        ...vertical,
        keywords: vertical.keywords.map((keyword) => ({
          ...keyword,
          // Asegurar que los campos de extras estén definidos
          id_keyword_extra:
            keyword.id_keyword_extra !== undefined
              ? keyword.id_keyword_extra
              : null,
          id_keyword_extra2:
            keyword.id_keyword_extra2 !== undefined
              ? keyword.id_keyword_extra2
              : null,
          extra_keyword_text:
            keyword.extra_keyword_text !== undefined
              ? keyword.extra_keyword_text
              : null,
          extra2_keyword_text:
            keyword.extra2_keyword_text !== undefined
              ? keyword.extra2_keyword_text
              : null,
        })),
      }));

      setVerticals(processedData);
    } catch (error) {
      console.error("Error al obtener verticales:", error);
    } finally {
      setLoading(false);
    }
  }, [clientId]);

  const fetchKeywords = useCallback(async () => {
    try {
      const data = await keywordService.getKeywords();
      setAvailableKeywords(data);
    } catch (error) {
      console.error("Error al obtener keywords:", error);
    }
  }, []);

  useEffect(() => {
    fetchVerticals();
    fetchKeywords();
  }, [fetchVerticals, fetchKeywords]);

  const handleOpenForm = (vertical = null) => {
    if (vertical) {
      const formattedVertical = {
        ...vertical,
        keywords: vertical.keywords.map((k) => k.id),
        percentage_decissors: vertical.percentage_decissors || 0,
      };
      setSelectedVertical(formattedVertical);
    } else {
      setSelectedVertical(null);
    }
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedVertical(null);
  };

  const handleSave = async (verticalData) => {
    try {
      setLoading(true);
      if (selectedVertical) {
        await verticalService.updateVertical(
          clientId,
          selectedVertical.id,
          verticalData
        );
        setNotification({
          open: true,
          message: "Vertical actualizado correctamente",
          severity: "success",
          autoHideDuration: 5000,
        });
      } else {
        await verticalService.createVertical(clientId, verticalData);
        setNotification({
          open: true,
          message: "Vertical creado correctamente",
          severity: "success",
          autoHideDuration: 5000,
        });
      }
      await fetchVerticals();
      handleCloseForm();
    } catch (error) {
      setNotification({
        open: true,
        message: error.message || "Error al guardar el vertical",
        severity: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const handleToggleExpand = (verticalId) => {
    setExpandedVerticals((prev) => ({
      ...prev,
      [verticalId]: !prev[verticalId],
    }));
  };

  const handleRemoveKeyword = async (verticalId, keywordId) => {
    try {
      // Actualizar el estado local inmediatamente para una respuesta más rápida en la UI
      setVerticals((prevVerticals) => {
        return prevVerticals.map((vertical) => {
          if (vertical.id === verticalId) {
            // Filtrar la keyword que queremos eliminar
            return {
              ...vertical,
              keywords: vertical.keywords.filter((k) => k.id !== keywordId),
            };
          }
          return vertical;
        });
      });

      // Enviar la petición al servidor
      await verticalService.removeKeywordFromVertical(
        clientId,
        verticalId,
        keywordId
      );

      // Mostrar notificación de éxito
      setNotification({
        open: true,
        message: "Keyword eliminada correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });

      // Ya no llamamos a fetchVerticals() aquí para evitar el refresco
    } catch (error) {
      console.error("Error al eliminar la keyword:", error);

      // En caso de error, sí necesitamos recargar para asegurar consistencia
      fetchVerticals();

      setNotification({
        open: true,
        message:
          "Error al eliminar la keyword: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const handleRemoveKeywordExtra = async (verticalId, keywordId) => {
    try {
      const keyword = verticals
        .find((v) => v.id === verticalId)
        ?.keywords?.find((k) => k.id === keywordId);

      if (keyword?.extra2_keyword_text) {
        setNotification({
          open: true,
          message:
            "No se puede eliminar Extra mientras tenga Extra2. Elimine primero Extra2.",
          severity: "warning",
          autoHideDuration: 5000,
        });
        return;
      }

      // Actualizar el estado local inmediatamente para una respuesta más rápida en la UI
      setVerticals((prevVerticals) => {
        return prevVerticals.map((vertical) => {
          if (vertical.id === verticalId) {
            return {
              ...vertical,
              keywords: vertical.keywords.map((k) => {
                if (k.id === keywordId) {
                  return {
                    ...k,
                    id_keyword_extra: null,
                    extra_keyword_text: null,
                  };
                }
                return k;
              }),
            };
          }
          return vertical;
        });
      });

      await verticalService.updateVerticalKeywordExtra(
        clientId,
        verticalId,
        keywordId,
        null
      );

      // Ya no llamamos a fetchVerticals() aquí para evitar el refresco

      setNotification({
        open: true,
        message: "Palabra clave extra eliminada correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });
    } catch (error) {
      console.error("Error al eliminar palabra clave extra:", error);

      // En caso de error, sí necesitamos recargar para asegurar consistencia
      fetchVerticals();

      setNotification({
        open: true,
        message:
          "Error al eliminar la palabra clave extra: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const handleRemoveKeywordExtra2 = async (verticalId, keywordId) => {
    try {
      // Actualizar el estado local inmediatamente para una respuesta más rápida en la UI
      setVerticals((prevVerticals) => {
        return prevVerticals.map((vertical) => {
          if (vertical.id === verticalId) {
            return {
              ...vertical,
              keywords: vertical.keywords.map((k) => {
                if (k.id === keywordId) {
                  return {
                    ...k,
                    id_keyword_extra2: null,
                    extra2_keyword_text: null,
                  };
                }
                return k;
              }),
            };
          }
          return vertical;
        });
      });

      await verticalService.updateKeywordExtra2(
        clientId,
        verticalId,
        keywordId,
        null
      );

      // Ya no llamamos a fetchVerticals() aquí para evitar el refresco

      setNotification({
        open: true,
        message: "Palabra clave extra 2 eliminada correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });
    } catch (error) {
      console.error("Error al eliminar palabra clave extra 2:", error);

      // En caso de error, sí necesitamos recargar para asegurar consistencia
      fetchVerticals();

      setNotification({
        open: true,
        message:
          "Error al eliminar la palabra clave extra 2: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    }
  };

  const handleStartEditing = (verticalId) => {
    setEditingVerticals((prev) => ({
      ...prev,
      [verticalId]: true,
    }));

    // Eliminamos la expansión automática para verticales sin keywords
    // para que el usuario tenga que hacer clic explícitamente en el botón
  };

  const handleCancelEditing = (verticalId) => {
    setEditingVerticals((prev) => {
      const newState = { ...prev };
      delete newState[verticalId];
      return newState;
    });
  };

  const handleUpdateKeyword = (verticalId, keywordId, field, value) => {
    setVerticals((prevVerticals) => {
      // Primero, encontramos el vertical y la keyword que estamos editando
      const vertical = prevVerticals.find((v) => v.id === verticalId);
      if (!vertical) return prevVerticals;

      const keyword = vertical.keywords.find((k) => k.id === keywordId);
      if (!keyword) return prevVerticals;

      // Creamos una copia del vertical y sus keywords
      const updatedVertical = {
        ...vertical,
        keywords: vertical.keywords.map((k) => {
          if (k.id === keywordId) {
            // Solo para la keyword que estamos editando
            const updatedKeyword = { ...k };

            if (field === "extra_keyword_text") {
              if (value) {
                const extraKeyword = availableKeywords.find(
                  (ak) => ak.text_keyword === value
                );
                if (extraKeyword) {
                  updatedKeyword.id_keyword_extra = extraKeyword.id;
                } else {
                  updatedKeyword.id_keyword_extra = null;
                }
                updatedKeyword.extra_keyword_text = value;
              } else {
                updatedKeyword.id_keyword_extra = null;
                updatedKeyword.extra_keyword_text = null;
              }
              // Mantenemos los valores de extra2 sin cambios
            } else if (field === "extra2_keyword_text") {
              if (value) {
                const extra2Keyword = availableKeywords.find(
                  (ak) => ak.text_keyword === value
                );
                if (extra2Keyword) {
                  updatedKeyword.id_keyword_extra2 = extra2Keyword.id;
                } else {
                  updatedKeyword.id_keyword_extra2 = null;
                }
                updatedKeyword.extra2_keyword_text = value;
              } else {
                updatedKeyword.id_keyword_extra2 = null;
                updatedKeyword.extra2_keyword_text = null;
              }
              // Mantenemos los valores de extra sin cambios
            }

            return updatedKeyword;
          }
          return k;
        }),
      };

      // Actualizamos solo el vertical que cambió
      return prevVerticals.map((v) =>
        v.id === verticalId ? updatedVertical : v
      );
    });
  };

  const handleSaveKeywords = async (verticalId) => {
    try {
      setSavingVerticals((prev) => ({ ...prev, [verticalId]: true }));
      const vertical = verticals.find((v) => v.id === verticalId);

      const verticalData = {
        name_vertical: vertical.name_vertical,
        percentage_decissors: vertical.percentage_decissors || 0,
        keywords: vertical.keywords.map((k) => {
          // Asegurarse de que todos los campos necesarios estén presentes
          const keywordData = {
            id: k.id,
            id_keyword_extra: k.id_keyword_extra,
            id_keyword_extra2: k.id_keyword_extra2,
            extra_keyword_text: k.extra_keyword_text,
            extra2_keyword_text: k.extra2_keyword_text,
          };

          // Asegurarnos de que los campos nulos se envíen explícitamente como null
          if (keywordData.id_keyword_extra === undefined) {
            keywordData.id_keyword_extra = null;
          }
          if (keywordData.id_keyword_extra2 === undefined) {
            keywordData.id_keyword_extra2 = null;
          }
          if (keywordData.extra_keyword_text === undefined) {
            keywordData.extra_keyword_text = null;
          }
          if (keywordData.extra2_keyword_text === undefined) {
            keywordData.extra2_keyword_text = null;
          }

          return keywordData;
        }),
      };

      // Actualizar la vertical con todas las keywords
      await verticalService.updateVertical(clientId, verticalId, verticalData);

      setNotification({
        open: true,
        message: "Keywords actualizadas correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });

      handleCancelEditing(verticalId);
      // Ya no llamamos a fetchVerticals() aquí para evitar el refresco
    } catch (error) {
      console.error("Error al guardar keywords:", error);
      setNotification({
        open: true,
        message:
          "Error al actualizar las keywords: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setSavingVerticals((prev) => {
        const newState = { ...prev };
        delete newState[verticalId];
        return newState;
      });
    }
  };

  const handleAddNewKeyword = async (verticalId, keywordId) => {
    try {
      // Obtener la información de la keyword que estamos añadiendo
      const keywordToAdd = availableKeywords.find((k) => k.id === keywordId);

      if (!keywordToAdd) {
        throw new Error("No se encontró la keyword seleccionada");
      }

      // Verificar si es la primera keyword que se añade a esta vertical
      const vertical = verticals.find((v) => v.id === verticalId);
      const isFirstKeyword =
        !vertical.keywords || vertical.keywords.length === 0;

      // Actualizar el estado local inmediatamente para una respuesta más rápida en la UI
      setVerticals((prevVerticals) => {
        return prevVerticals.map((vertical) => {
          if (vertical.id === verticalId) {
            // Verificar si la keyword ya existe en esta vertical
            const keywordExists = vertical.keywords.some(
              (k) => k.id === keywordId
            );
            if (keywordExists) {
              return vertical; // No hacer nada si ya existe
            }

            // Añadir la nueva keyword a la lista
            return {
              ...vertical,
              keywords: [
                ...vertical.keywords,
                {
                  id: keywordToAdd.id,
                  text_keyword: keywordToAdd.text_keyword,
                  id_keyword_extra: null,
                  id_keyword_extra2: null,
                  extra_keyword_text: null,
                  extra2_keyword_text: null,
                },
              ],
            };
          }
          return vertical;
        });
      });

      // Expandir la vertical si no está ya expandida
      if (!expandedVerticals[verticalId]) {
        setExpandedVerticals((prev) => ({
          ...prev,
          [verticalId]: true,
        }));
      }

      // Iniciar modo de edición si no está ya en ese modo
      if (!editingVerticals[verticalId]) {
        setEditingVerticals((prev) => ({
          ...prev,
          [verticalId]: true,
        }));
      }

      // Asegurarse de que keywordId sea un número
      const numericKeywordId = Number(keywordId);

      // Enviar la petición al servidor con la estructura correcta
      await verticalService.addKeywordToVertical(
        clientId,
        verticalId,
        numericKeywordId
      );

      // Mostrar notificación de éxito
      setNotification({
        open: true,
        message: isFirstKeyword
          ? `Primera keyword "${keywordToAdd.text_keyword}" añadida correctamente`
          : "Keyword añadida correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });

      // Ya no llamamos a fetchVerticals() aquí para evitar el refresco
      // La UI ya se actualizó localmente
    } catch (error) {
      console.error("Error al añadir la keyword:", error);

      // En caso de error, sí necesitamos recargar para asegurar consistencia
      fetchVerticals();

      setNotification({
        open: true,
        message:
          "Error al añadir la keyword: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    }
  };

  // Añadir filtrado de verticales
  const filteredVerticals = verticals.filter(
    (vertical) =>
      vertical.name_vertical.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vertical.keywords?.some((k) =>
        k.text_keyword.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  // Calcular elementos paginados
  const paginatedVerticals = filteredVerticals.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSubmitKeywordExtra2 = async (keywordId) => {
    try {
      await verticalService.updateKeywordExtra2(
        clientId,
        selectedVerticalId,
        selectedKeywordId,
        keywordId
      );
      fetchVerticals();
      setNotification({
        open: true,
        message: "Palabra clave extra 2 añadida correctamente",
        severity: "success",
        autoHideDuration: 5000,
      });
    } catch (error) {
      console.error("Error adding keyword extra2:", error);
      setNotification({
        open: true,
        message:
          "Error al añadir la palabra clave extra 2: " +
          (error.message || "Error desconocido"),
        severity: "error",
        autoHideDuration: 5000,
      });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.25rem",
            fontWeight: 500,
          }}
        >
          Gestión de Verticales
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenForm()}
          sx={{
            textTransform: "none",
            fontSize: "0.875rem",
            px: 2,
            py: 0.75,
          }}
        >
          Nuevo Vertical
        </Button>
      </Box>

      <Paper
        elevation={0}
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          px: 1.5,
          py: 0.5,
        }}
      >
        <SearchIcon sx={{ color: "text.secondary", fontSize: "1.25rem" }} />
        <TextField
          fullWidth
          placeholder="Buscar verticales o keywords..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="standard"
          sx={{
            ml: 1,
            "& .MuiInput-root": {
              fontSize: "0.875rem",
              "&:before, &:after": { display: "none" },
            },
          }}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => setSearchTerm("")}
                  sx={{ p: 0.5 }}
                >
                  <ClearIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <List>
        {paginatedVerticals.map((vertical) => (
          <Paper key={vertical.id} sx={{ mb: 2 }}>
            <ListItem
              sx={{
                py: 1,
                px: 2,
                borderBottom: "1px solid #e0e0e0",
                bgcolor: "background.paper",
              }}
            >
              <ListItemText
                primary={vertical.name_vertical}
                secondary={`${vertical.company_count || 0} empresas · ${
                  vertical.keywords?.length || 0
                } keywords · ${vertical.percentage_decissors || 0}% decisores`}
                sx={{
                  my: 0,
                  "& .MuiListItemText-primary": {
                    fontSize: "0.875rem",
                    fontWeight: 500,
                  },
                  "& .MuiListItemText-secondary": {
                    fontSize: "0.75rem",
                  },
                }}
              />
              {vertical.keywords?.length === 0 && (
                <Chip
                  size="small"
                  label="Sin keywords"
                  sx={{
                    mr: 1,
                    height: "20px",
                    fontSize: "0.7rem",
                    backgroundColor: "#ffebee",
                    color: "#d32f2f",
                  }}
                />
              )}
              <IconButton
                size="small"
                onClick={() => handleToggleExpand(vertical.id)}
                sx={{ mr: 1 }}
              >
                {expandedVerticals[vertical.id] ? (
                  <ExpandLessIcon sx={{ fontSize: "1.25rem" }} />
                ) : (
                  <ExpandMoreIcon sx={{ fontSize: "1.25rem" }} />
                )}
              </IconButton>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleOpenForm(vertical)}
                sx={{
                  fontSize: "0.75rem",
                  py: 0.5,
                  px: 1.5,
                  height: "28px",
                  textTransform: "none",
                }}
              >
                Editar
              </Button>
            </ListItem>

            <Collapse in={expandedVerticals[vertical.id]} timeout="auto">
              {vertical.keywords?.length > 0 ? (
                <List sx={{ pl: 4, pr: 2, py: 1 }}>
                  {vertical.keywords?.map((keyword) => (
                    <ListItem
                      key={keyword.id}
                      sx={{
                        py: 0.5,
                        borderBottom: "1px solid #f0f0f0",
                        "&:last-child": {
                          borderBottom: "none",
                        },
                        flexDirection: "column",
                        alignItems: "stretch",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        {editingVerticals[vertical.id] ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              gap: 2,
                              width: "100%",
                              flexWrap: { md: "wrap" },
                            }}
                          >
                            <Autocomplete
                              size="small"
                              options={availableKeywords}
                              getOptionLabel={(option) => option.text_keyword}
                              value={
                                availableKeywords.find(
                                  (k) => k.id === keyword.id
                                ) || null
                              }
                              onChange={(_, newValue) => {
                                if (newValue) {
                                  handleUpdateKeyword(
                                    vertical.id,
                                    keyword.id,
                                    "text_keyword",
                                    newValue.text_keyword
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Keyword principal"
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                    minWidth: { xs: "100%", md: "220px" },
                                    flex: 1,
                                    "& .MuiInputLabel-root": {
                                      fontSize: "0.875rem",
                                      transform:
                                        "translate(14px, 10px) scale(1)",
                                      "&.MuiInputLabel-shrink": {
                                        transform:
                                          "translate(14px, -6px) scale(0.75)",
                                      },
                                    },
                                    "& .MuiInputBase-root": {
                                      fontSize: "0.875rem",
                                      minHeight: "40px",
                                      paddingTop: "0",
                                      paddingBottom: "0",
                                    },
                                    "& .MuiAutocomplete-input": {
                                      padding: "4px 4px 4px 6px !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 0.23)",
                                    },
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              size="small"
                              options={availableKeywords}
                              getOptionLabel={(option) => option.text_keyword}
                              value={
                                keyword.extra_keyword_text
                                  ? availableKeywords.find(
                                      (k) =>
                                        k.text_keyword ===
                                        keyword.extra_keyword_text
                                    )
                                  : null
                              }
                              onChange={(_, newValue) => {
                                handleUpdateKeyword(
                                  vertical.id,
                                  keyword.id,
                                  "extra_keyword_text",
                                  newValue?.text_keyword || null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Keyword extra"
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                    minWidth: { xs: "100%", md: "220px" },
                                    flex: 1,
                                    "& .MuiInputLabel-root": {
                                      fontSize: "0.875rem",
                                      transform:
                                        "translate(14px, 10px) scale(1)",
                                      "&.MuiInputLabel-shrink": {
                                        transform:
                                          "translate(14px, -6px) scale(0.75)",
                                      },
                                    },
                                    "& .MuiInputBase-root": {
                                      fontSize: "0.875rem",
                                      minHeight: "40px",
                                      paddingTop: "0",
                                      paddingBottom: "0",
                                    },
                                    "& .MuiAutocomplete-input": {
                                      padding: "4px 4px 4px 6px !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 0.23)",
                                    },
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              size="small"
                              options={availableKeywords}
                              getOptionLabel={(option) => option.text_keyword}
                              value={
                                keyword.extra2_keyword_text
                                  ? availableKeywords.find(
                                      (k) =>
                                        k.text_keyword ===
                                        keyword.extra2_keyword_text
                                    )
                                  : null
                              }
                              onChange={(_, newValue) => {
                                handleUpdateKeyword(
                                  vertical.id,
                                  keyword.id,
                                  "extra2_keyword_text",
                                  newValue?.text_keyword || null
                                );
                              }}
                              disabled={!keyword.extra_keyword_text}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Keyword extra 2"
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                    minWidth: { xs: "100%", md: "220px" },
                                    flex: 1,
                                    "& .MuiInputLabel-root": {
                                      fontSize: "0.875rem",
                                      transform:
                                        "translate(14px, 10px) scale(1)",
                                      "&.MuiInputLabel-shrink": {
                                        transform:
                                          "translate(14px, -6px) scale(0.75)",
                                      },
                                    },
                                    "& .MuiInputBase-root": {
                                      fontSize: "0.875rem",
                                      minHeight: "40px",
                                      paddingTop: "0",
                                      paddingBottom: "0",
                                    },
                                    "& .MuiAutocomplete-input": {
                                      padding: "4px 4px 4px 6px !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 0.23)",
                                    },
                                  }}
                                />
                              )}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              flexGrow: 1,
                            }}
                          >
                            <Typography sx={{ fontSize: "0.875rem" }}>
                              {keyword.text_keyword}
                            </Typography>
                            {keyword.extra_keyword_text && (
                              <Chip
                                size="small"
                                label={keyword.extra_keyword_text}
                                onDelete={() =>
                                  handleRemoveKeywordExtra(
                                    vertical.id,
                                    keyword.id
                                  )
                                }
                                sx={{
                                  height: "20px",
                                  fontSize: "0.75rem",
                                  backgroundColor: "#e3f2fd",
                                }}
                              />
                            )}
                            {keyword.extra2_keyword_text && (
                              <Chip
                                size="small"
                                label={keyword.extra2_keyword_text}
                                onDelete={() =>
                                  handleRemoveKeywordExtra2(
                                    vertical.id,
                                    keyword.id
                                  )
                                }
                                sx={{
                                  height: "20px",
                                  fontSize: "0.75rem",
                                  backgroundColor: "#e3f2fd",
                                }}
                              />
                            )}
                          </Box>
                        )}
                        <Box sx={{ ml: "auto", display: "flex", gap: 1 }}>
                          {editingVerticals[vertical.id] ? (
                            <>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleRemoveKeyword(vertical.id, keyword.id)
                                }
                                sx={{ p: 0.5 }}
                              >
                                <DeleteIcon sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleRemoveKeyword(vertical.id, keyword.id)
                              }
                              sx={{ p: 0.5 }}
                            >
                              <DeleteIcon sx={{ fontSize: "1rem" }} />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {editingVerticals[vertical.id] ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: 2,
                            width: "100%",
                            mb: 2,
                            flexWrap: { sm: "wrap" },
                          }}
                        >
                          <Autocomplete
                            size="small"
                            options={availableKeywords.filter(
                              (k) =>
                                !vertical.keywords.some((vk) => vk.id === k.id)
                            )}
                            getOptionLabel={(option) => option.text_keyword}
                            onChange={(_, newValue) => {
                              if (newValue) {
                                handleAddNewKeyword(vertical.id, newValue.id);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Añadir nueva keyword"
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  minWidth: { xs: "100%", md: "300px" },
                                  flex: 1,
                                  "& .MuiInputLabel-root": {
                                    fontSize: "0.875rem",
                                    transform: "translate(14px, 10px) scale(1)",
                                    "&.MuiInputLabel-shrink": {
                                      transform:
                                        "translate(14px, -6px) scale(0.75)",
                                    },
                                  },
                                  "& .MuiInputBase-root": {
                                    fontSize: "0.875rem",
                                    minHeight: "40px",
                                    paddingTop: "0",
                                    paddingBottom: "0",
                                  },
                                  "& .MuiAutocomplete-input": {
                                    padding: "4px 4px 4px 6px !important",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: { xs: "center", sm: "flex-end" },
                            gap: 2,
                          }}
                        >
                          <Button
                            size="small"
                            onClick={() => handleCancelEditing(vertical.id)}
                            sx={{
                              fontSize: "0.75rem",
                              py: 0.5,
                              px: 1.5,
                              height: "28px",
                              textTransform: "none",
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleSaveKeywords(vertical.id)}
                            disabled={savingVerticals[vertical.id]}
                            startIcon={
                              savingVerticals[vertical.id] && (
                                <CircularProgress size={16} />
                              )
                            }
                            sx={{
                              fontSize: "0.75rem",
                              py: 0.5,
                              px: 1.5,
                              height: "28px",
                              textTransform: "none",
                            }}
                          >
                            {savingVerticals[vertical.id]
                              ? "Guardando..."
                              : "Guardar"}
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEditing(vertical.id)}
                        sx={{
                          fontSize: "0.75rem",
                          py: 0.5,
                          px: 1.5,
                          height: "28px",
                          textTransform: "none",
                        }}
                      >
                        Añadir keywords
                      </Button>
                    )}
                  </Box>
                </List>
              ) : (
                <Box sx={{ p: 2, textAlign: "center" }}>
                  {editingVerticals[vertical.id] ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      <Autocomplete
                        size="small"
                        options={availableKeywords}
                        getOptionLabel={(option) => option.text_keyword}
                        onChange={(_, newValue) => {
                          if (newValue) {
                            handleAddNewKeyword(vertical.id, newValue.id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Añadir nueva keyword"
                            variant="outlined"
                            sx={{
                              width: "100%",
                              minWidth: { xs: "100%", md: "300px" },
                              flex: 1,
                              "& .MuiInputLabel-root": {
                                fontSize: "0.875rem",
                                transform: "translate(14px, 10px) scale(1)",
                                "&.MuiInputLabel-shrink": {
                                  transform:
                                    "translate(14px, -6px) scale(0.75)",
                                },
                              },
                              "& .MuiInputBase-root": {
                                fontSize: "0.875rem",
                                minHeight: "40px",
                                paddingTop: "0",
                                paddingBottom: "0",
                              },
                              "& .MuiAutocomplete-input": {
                                padding: "4px 4px 4px 6px !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23)",
                              },
                            }}
                          />
                        )}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 2,
                          mt: 2,
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => handleCancelEditing(vertical.id)}
                          sx={{
                            fontSize: "0.75rem",
                            py: 0.5,
                            px: 1.5,
                            height: "28px",
                            textTransform: "none",
                          }}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        Esta vertical no tiene keywords asignadas
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEditing(vertical.id)}
                        sx={{
                          fontSize: "0.75rem",
                          py: 0.5,
                          px: 1.5,
                          height: "28px",
                          textTransform: "none",
                        }}
                      >
                        Añadir keywords
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Collapse>
          </Paper>
        ))}
      </List>

      {/* Añadir paginación */}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {filteredVerticals.length} resultados
        </Typography>
        <Pagination
          count={Math.ceil(filteredVerticals.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          size="small"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "0.75rem",
            },
          }}
        />
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={notification.autoHideDuration}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      <VerticalForm
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSave}
        initialData={selectedVertical}
        clientId={clientId}
        loading={loading}
      />

      <KeywordExtraDialog
        open={keywordExtraDialogOpen}
        onClose={() => setKeywordExtraDialogOpen(false)}
        onSubmit={async (extraKeywordId) => {
          try {
            await verticalService.updateVerticalKeywordExtra(
              clientId,
              selectedVerticalId,
              selectedKeywordId,
              extraKeywordId
            );
            await fetchVerticals();
            setKeywordExtraDialogOpen(false);
          } catch (error) {
            console.error("Error updating keyword extra:", error);
          }
        }}
      />

      <KeywordExtraDialog
        open={openKeywordExtra2Dialog}
        onClose={() => setOpenKeywordExtra2Dialog(false)}
        onSubmit={(keywordId) => {
          handleSubmitKeywordExtra2(keywordId);
          setOpenKeywordExtra2Dialog(false);
        }}
      />
    </Box>
  );
};

export default VerticalList;
