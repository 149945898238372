import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import processPreviewContent from "../../utils/previewContentProcessor";

const EmailPreviewModal = ({
  open,
  onClose,
  signature,
  body,
  textClosing,
  clientId,
  subject,
  processSignature = true,
}) => {
  const [processedContent, setProcessedContent] = useState({
    signature: "",
    body: "",
    textClosing: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const processContent = async () => {
      if (open) {
        setLoading(true);
        try {
          const promises = [
            processPreviewContent(body, clientId),
            processSignature
              ? processPreviewContent(signature, clientId)
              : Promise.resolve(""),
            processSignature
              ? processPreviewContent(textClosing, clientId)
              : Promise.resolve(""),
          ];

          const [processedBody, processedSignature, processedClosing] =
            await Promise.all(promises);

          setProcessedContent({
            signature: processedSignature,
            body: processedBody,
            textClosing: processedClosing,
          });
        } catch (error) {
          console.error("Error al procesar el contenido:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    processContent();
  }, [open, signature, body, textClosing, clientId, processSignature]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{subject || "Vista Previa del Correo"}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ p: 2 }}>
            <Box
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{ __html: processedContent.body }}
            />
            {processSignature && (
              <>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: processedContent.textClosing,
                  }}
                />
                <Box
                  sx={{ mb: 2 }}
                  dangerouslySetInnerHTML={{
                    __html: processedContent.signature,
                  }}
                />
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreviewModal;
