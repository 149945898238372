import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Tabs, Tab, Typography, Box, Button } from "@mui/material";
import CampaignList from "../campaigns/CampaignList";
import EmailCredentialsList from "../emailCredentials/EmailCredentialsList";
import VerticalList from "../verticals/VerticalList";
import clientService from "../../services/clientService";

const ClientProfile = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const data = await clientService.getClient(clientId);
        setClient(data);
      } catch (error) {
        console.error("Error al obtener cliente:", error);
      }
    };
    fetchClient();
  }, [clientId]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!client) {
    return <Typography>Cargando...</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {client.name}
      </Typography>
      <Button
        component={Link}
        to={`/clients/${clientId}/edit`}
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        Editar Cliente
      </Button>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Campañas" />
          <Tab label="Credenciales de Email" />
          <Tab label="Verticales" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabValue === 0 && <CampaignList clientId={clientId} />}
        {tabValue === 1 && <EmailCredentialsList clientId={clientId} />}
        {tabValue === 2 && <VerticalList clientId={clientId} />}
      </Box>
    </div>
  );
};

export default ClientProfile;
