import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";
import MultiSelect from "../common/MultiSelect";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const CampaignTemplateList = () => {
  const { clientId, campaignId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailCredential, setEmailCredential] = useState(null);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const [templatesData, verticalsData, campaignData] = await Promise.all([
          campaignService.getCampaignTemplates(clientId, campaignId),
          verticalService.getClientVerticals(clientId),
          campaignService.getCampaign(clientId, campaignId),
        ]);
        setTemplates(templatesData);
        setVerticals(verticalsData);
        setCampaign(campaignData);
      } catch (error) {
        setError(error.response?.data?.message || "Error al cargar los datos");
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [clientId, campaignId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al cargar las credenciales de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const getFilteredTemplates = () => {
    return templates.filter((template) => {
      const matchesVerticals =
        selectedVerticals.length === 0 ||
        selectedVerticals.some((verticalId) =>
          template.vertical_ids?.includes(parseInt(verticalId))
        );

      const templateType = getTemplateType(template);
      const matchesTypes =
        selectedTypes.length === 0 || selectedTypes.includes(templateType);

      return matchesVerticals && matchesTypes;
    });
  };

  const getTemplateType = (template) => {
    if (template.after_rejection) return "rejection";
    if (template.num_days_without_response > 0) return "followup";
    return "presentation";
  };

  const getTemplateBorderStyle = (type) => {
    switch (type) {
      case "followup":
        return "2px solid #FFD700"; // Yellow border for followup
      case "rejection":
        return "2px solid #FF6B6B"; // Red border for rejection
      default:
        return "1px solid #ddd"; // Default border for presentation
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 800, margin: "auto", mt: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const typeOptions = [
    {
      value: "presentation",
      label: "Presentación",
      color: "#ddd",
    },
    {
      value: "followup",
      label: "Seguimiento",
      color: "#FFD700",
    },
    {
      value: "rejection",
      label: "Rechazo",
      color: "#FF6B6B",
    },
  ];

  return (
    <>
      <Box sx={{ margin: "auto", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {campaign?.name_campaign || "Cargando..."}
          </Typography>
          <Button
            component={Link}
            to={`/clients/${clientId}/campaigns/${campaignId}`}
            variant="outlined"
            color="primary"
          >
            Editar Campaña
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 3,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MultiSelect
              options={verticals.map((v) => ({
                value: v.id.toString(),
                label: v.name_vertical,
              }))}
              value={selectedVerticals}
              onChange={setSelectedVerticals}
              label="Verticales"
              placeholder="Cualquier vertical"
            />
            <Tooltip title="Reiniciar filtro de verticales">
              <IconButton
                size="small"
                onClick={() => setSelectedVerticals([])}
                sx={{ opacity: selectedVerticals.length > 0 ? 1 : 0.3 }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <MultiSelect
              options={typeOptions}
              value={selectedTypes}
              onChange={setSelectedTypes}
              label="Tipos"
              placeholder="Cualquier tipo"
              renderOption={(option) => (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      border: `2px solid ${option.color}`,
                      borderRadius: "50%",
                    }}
                  />
                  {option.label}
                </Box>
              )}
            />
            <Tooltip title="Reiniciar filtro de tipos">
              <IconButton
                size="small"
                onClick={() => setSelectedTypes([])}
                sx={{ opacity: selectedTypes.length > 0 ? 1 : 0.3 }}
              >
                <RestartAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Button
          component={Link}
          to={`/clients/${clientId}/campaigns/${campaignId}/templates/new`}
          variant="contained"
          color="primary"
          sx={{ mb: 3 }}
        >
          Crear Nueva Plantilla
        </Button>

        <List>
          {getFilteredTemplates().map((template) => {
            const templateType = getTemplateType(template);
            return (
              <ListItem
                key={template.id}
                component={Link}
                to={`/clients/${clientId}/campaigns/${campaignId}/templates/${template.id}`}
                sx={{
                  border: getTemplateBorderStyle(templateType),
                  borderRadius: 1,
                  mb: 2,
                  p: 2,
                  backgroundColor: "background.paper",
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "stretch", sm: "flex-start" },
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    minWidth: 0,
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            wordBreak: "break-word",
                            mb: 1,
                          }}
                        >
                          {template.subject}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                            mb: 1,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              color: "text.secondary",
                              bgcolor: "rgba(0,0,0,0.05)",
                              px: 1,
                              py: 0.5,
                              borderRadius: 1,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {templateType === "followup"
                              ? `Seguimiento (${template.num_days_without_response} días)`
                              : templateType === "rejection"
                              ? "Rechazo"
                              : "Presentación"}
                          </Typography>
                          {template.vertical_ids?.map((verticalId) => {
                            const vertical = verticals.find(
                              (v) => v.id === verticalId
                            );
                            return (
                              vertical && (
                                <Typography
                                  key={verticalId}
                                  variant="caption"
                                  sx={{
                                    color: "text.secondary",
                                    bgcolor:
                                      templateType === "followup"
                                        ? "rgba(255, 215, 0, 0.1)"
                                        : templateType === "rejection"
                                        ? "rgba(255, 107, 107, 0.1)"
                                        : "rgba(0, 0, 0, 0.05)",
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1,
                                    border: `1px solid ${
                                      templateType === "followup"
                                        ? "#FFD700"
                                        : templateType === "rejection"
                                        ? "#FF6B6B"
                                        : "#ddd"
                                    }`,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {vertical.name_vertical}
                                </Typography>
                              )
                            );
                          })}
                        </Box>
                      </Box>
                    }
                    secondary={
                      <Box sx={{ typography: "body2" }}>
                        <Box>
                          Emails Enviados: {template.emails_sent} (
                          {template.unique_contacts} contactos únicos,{" "}
                          {template.total_companies} empresas)
                        </Box>
                        {template.datetime_start && (
                          <Box>
                            Inicio:{" "}
                            {new Date(
                              template.datetime_start
                            ).toLocaleDateString()}
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </Box>
                <Box
                  onClick={(e) => e.preventDefault()}
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: { xs: "row", sm: "row" },
                    justifyContent: { xs: "stretch", sm: "flex-end" },
                    alignItems: "flex-start",
                    minWidth: { xs: "100%", sm: "auto" },
                    zIndex: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreview(template);
                    }}
                    sx={{ flex: { xs: 1, sm: "none" } }}
                  >
                    Vista Previa
                  </Button>
                  <Button
                    component={Link}
                    to={`/clients/${clientId}/campaigns/${campaignId}/templates/${template.id}`}
                    variant="outlined"
                    color="primary"
                    onClick={(e) => e.stopPropagation()}
                    sx={{ flex: { xs: 1, sm: "none" } }}
                  >
                    Editar
                  </Button>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={selectedTemplate?.body || ""}
        textClosing={emailCredential?.text_closing || ""}
        clientId={clientId}
        subject={selectedTemplate?.subject || ""}
      />
    </>
  );
};

export default CampaignTemplateList;
