import fileService from "../services/fileService";

const convertToPng = async (blob) => {
  try {
    const img = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);
    return new Promise((resolve) => {
      canvas.toBlob(resolve, "image/png", 1.0);
    });
  } catch (error) {
    console.error("Error converting to PNG:", error);
    return blob; // Devolver el blob original si falla la conversión
  }
};

const processContent = async (content, clientId) => {
  if (!content) return content;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const images = Array.from(doc.getElementsByTagName("img"));

  await Promise.all(
    images.map(async (img) => {
      if (img.src.startsWith("cid:")) return;

      try {
        // Guardar dimensiones originales
        const width = img.getAttribute("width");
        const height = img.getAttribute("height");

        const response = await fetch(img.src);
        let blob = await response.blob();

        // Convertir a PNG
        blob = await convertToPng(blob);

        const hashBuffer = await crypto.subtle.digest(
          "SHA-256",
          await blob.arrayBuffer()
        );
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");
        const fileName = `${hashHex.slice(0, 12)}.png`;

        const fileResponse = await fileService.uploadFile(
          clientId,
          blob,
          fileName,
          "image/png"
        );

        img.src = `cid:${fileResponse.id}`;

        // Restaurar dimensiones originales
        if (width) img.setAttribute("width", width);
        if (height) img.setAttribute("height", height);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    })
  );

  return doc.body.innerHTML;
};

const resolveContent = async (content, clientId) => {
  if (!content) return content;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const images = Array.from(doc.getElementsByTagName("img"));

  await Promise.all(
    images.map(async (img) => {
      if (img.src.startsWith("cid:")) {
        const fileId = img.src.replace("cid:", "");
        try {
          const blob = await fileService.getFile(clientId, fileId);
          const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          img.src = base64;
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    })
  );

  return doc.body.innerHTML;
};

export { processContent, resolveContent, convertToPng };
