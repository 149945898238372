import React, { useRef, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { resolveContent } from "../../utils/contentProcessor";

const RichTextEditor = ({ value, onChange, clientId }) => {
  const editorRef = useRef(null);
  const [initialContent, setInitialContent] = useState("");
  const isInitialMount = useRef(true);

  useEffect(() => {
    const loadContent = async () => {
      if (value && isInitialMount.current) {
        const resolvedContent = await resolveContent(value, clientId);
        setInitialContent(resolvedContent);
        isInitialMount.current = false;
      }
    };
    loadContent();
  }, [value, clientId]);

  return (
    <Editor
      apiKey="kpt3oowrhdgikx9wbpg086s2flaoo3dbmavsesiq0wfhdklz"
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      initialValue={initialContent}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "help",
          "wordcount",
        ],
        toolbar:
          "imagen | deshacer rehacer | formato | negrita cursiva color de fondo | " +
          "alinear izquierda alinear centro alinear derecha justificar | " +
          "lista con viñetas lista numerada reducir sangría aumentar sangría | quitar formato | ayuda",
        paste_data_images: true,
        automatic_uploads: false,
        images_upload_handler: (blobInfo, progress) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(blobInfo.blob());
          });
        },
        file_picker_types: "image",
        convert_urls: false,
      }}
      onEditorChange={(content, editor) => {
        onChange(content);
      }}
    />
  );
};

export default RichTextEditor;
