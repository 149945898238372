import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Alert,
  Typography,
} from "@mui/material";
import processPreviewContent from "../../utils/previewContentProcessor";
import previewService from "../../services/previewService";

const EmailPreviewModal = ({
  open,
  onClose,
  signature,
  body,
  textClosing,
  textPersonalConnection,
  textCompanyConnection,
  clientId,
  subject,
  templateId,
  processSignature = true,
}) => {
  console.log("lo que le llega al componente", {
    open: open,
    onClose: onClose,
    signature: signature,
    body: body,
    textClosing: textClosing,
    textPersonalConnection: textPersonalConnection,
    textCompanyConnection: textCompanyConnection,
    clientId: clientId,
    subject: subject,
    templateId: templateId,
    processSignature: processSignature,
  });

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [previewData, setPreviewData] = useState(null);
  const [processedContent, setProcessedContent] = useState({
    signature: "",
    body: "",
    textClosing: "",
  });
  const [previewError, setPreviewError] = useState(null);

  // Mover safeReplace fuera del efecto usando useCallback
  const safeReplace = useCallback((text, placeholder, replacement) => {
    if (!text) return text;
    // Solo reemplazamos si hay un valor de reemplazo
    if (!replacement) return text;
    return text.replace(new RegExp(placeholder, "g"), replacement);
  }, []);

  // Actualizar processContent para manejar correctamente los textos de conexión
  const processContent = useCallback(
    async (body, currentPreviewData, currentTab) => {
      let processedBody = body || "";
      // Usar el texto de conexión correspondiente según la pestaña
      const introText =
        currentTab === 0 ? textPersonalConnection : textCompanyConnection;
      processedBody = introText ? processedBody : processedBody;

      const currentData = currentPreviewData
        ? currentTab === 0
          ? currentPreviewData.personData
          : currentPreviewData.companyData
        : null;

      // Procesar el texto de conexión primero
      let processedIntro = introText || "";
      processedIntro = safeReplace(
        processedIntro,
        "\\[NOMBRE_EMPRESA\\]",
        currentData?.companyName
      );
      processedIntro = safeReplace(
        processedIntro,
        "\\[NOMBRE_DESTINATARIO\\]",
        currentData?.contactName
      );
      processedIntro = safeReplace(
        processedIntro,
        "\\[SUBVERTICAL\\]",
        currentPreviewData?.keyword
      );
      processedIntro = safeReplace(
        processedIntro,
        "\\[SUBVERTICAL\\(s\\)\\]",
        currentPreviewData?.keywordPlural
      );

      // Procesar el cuerpo del mensaje
      processedBody = safeReplace(
        processedBody,
        "\\[NOMBRE_EMPRESA\\]",
        currentData?.companyName
      );
      processedBody = safeReplace(
        processedBody,
        "\\[NOMBRE_DESTINATARIO\\]",
        currentData?.contactName
      );
      processedBody = safeReplace(
        processedBody,
        "\\[SUBVERTICAL\\]",
        currentPreviewData?.keyword
      );
      processedBody = safeReplace(
        processedBody,
        "\\[SUBVERTICAL\\(s\\)\\]",
        currentPreviewData?.keywordPlural
      );

      return { processedIntro, processedBody };
    },
    [safeReplace, textPersonalConnection, textCompanyConnection]
  );

  useEffect(() => {
    const loadPreviewAndProcess = async () => {
      if (!open) return;

      setLoading(true);
      try {
        // Cargar datos de preview solo si hay templateId y no tenemos datos previos
        let currentPreviewData = previewData;
        if (templateId && !currentPreviewData) {
          const data = await previewService.getPreviewData(
            clientId,
            templateId
          );
          currentPreviewData = data;
          setPreviewData(data);
        }

        // Procesar el contenido incluso sin previewData
        const { processedIntro, processedBody } = await processContent(
          body,
          currentPreviewData,
          tab
        );

        // Procesar contenido con imágenes
        const [
          processedIntroResult,
          processedBodyResult,
          processedSignature,
          processedClosing,
        ] = await Promise.all([
          processPreviewContent(processedIntro || "", clientId),
          processPreviewContent(processedBody || "", clientId),
          processSignature
            ? processPreviewContent(signature || "", clientId)
            : Promise.resolve(""),
          processSignature
            ? processPreviewContent(textClosing || "", clientId)
            : Promise.resolve(""),
        ]);

        setProcessedContent({
          signature: processedSignature,
          body: processedBodyResult,
          textClosing: processedClosing,
          intro: processedIntroResult,
        });
      } catch (error) {
        console.error("Error en preview:", error);
        setPreviewError(
          "Error al procesar la vista previa. Algunas sustituciones pueden no estar disponibles."
        );
      } finally {
        setLoading(false);
      }
    };

    loadPreviewAndProcess();
  }, [
    open,
    templateId,
    clientId,
    tab,
    body,
    signature,
    textClosing,
    textPersonalConnection,
    textCompanyConnection,
    processSignature,
    processContent,
  ]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const getCurrentPreviewData = () => {
    if (!previewData) return null;
    return tab === 0 ? previewData.personData : previewData.companyData;
  };

  const getReplacementsInfo = () => {
    const currentData = getCurrentPreviewData();
    if (!currentData) return [];

    const replacements = [
      {
        original: "[NOMBRE_EMPRESA]",
        replacement: currentData.companyName,
        warning:
          !currentData.companyName &&
          "No se encontró ninguna empresa para esta vertical",
      },
      {
        original: "[NOMBRE_DESTINATARIO]",
        replacement: currentData.contactName,
        warning:
          tab === 0 &&
          !currentData.contactName &&
          "No se encontró ningún contacto personal",
      },
      {
        original: "[SUBVERTICAL]",
        replacement: previewData?.keyword,
        warning: !previewData?.keyword && "No se encontró la keyword",
      },
      {
        original: "[SUBVERTICAL(s)]",
        replacement: previewData?.keywordPlural,
        warning:
          !previewData?.keywordPlural && "No se encontró la keyword en plural",
      },
    ];

    return replacements;
  };

  const getContent = () => {
    // Verificar si el body ya incluye el texto de cierre
    const bodyIncludesClosing =
      processedContent.textClosing &&
      processedContent.body &&
      processedContent.body.includes(processedContent.textClosing);

    // Verificar si el body ya incluye parte de la firma
    const bodyIncludesSignature =
      processedContent.signature &&
      processedContent.body &&
      (processedContent.body.includes("¡Un saludo!") ||
        processedContent.body.includes(
          processedContent.signature.substring(0, 30)
        ));

    // Si el body incluye parte de la firma, vamos a eliminar esa parte para evitar duplicación
    let processedBody = processedContent.body;
    if (bodyIncludesSignature && processedContent.body) {
      // Buscar el índice donde comienza "¡Un saludo!" o similar
      const saludo1Index = processedContent.body.indexOf("¡Un saludo!");
      const saludo2Index = processedContent.body.indexOf("Un saludo");

      // Encontrar el primer índice válido (si existe)
      let cutIndex = -1;
      if (saludo1Index !== -1) cutIndex = saludo1Index;
      else if (saludo2Index !== -1) cutIndex = saludo2Index;

      // Si encontramos un punto de corte, truncamos el body
      if (cutIndex !== -1) {
        processedBody = processedContent.body.substring(0, cutIndex);
      }
    }

    return (
      <>
        <Box
          sx={{ mb: 2 }}
          dangerouslySetInnerHTML={{ __html: processedContent.intro }}
        />
        <Box dangerouslySetInnerHTML={{ __html: processedBody }} />
        {!bodyIncludesClosing && processedContent.textClosing && (
          <Box
            sx={{ mb: 2 }}
            dangerouslySetInnerHTML={{ __html: processedContent.textClosing }}
          />
        )}
        {/* Siempre mostrar la firma completa */}
        {processedContent.signature && (
          <Box
            dangerouslySetInnerHTML={{ __html: processedContent.signature }}
          />
        )}
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{subject || "Vista Previa del Correo"}</DialogTitle>
      <DialogContent>
        {/* Mostrar tabs solo si hay templateId */}
        {templateId && (
          <Tabs value={tab} onChange={handleChangeTab} sx={{ mb: 2 }}>
            <Tab label="Persona" />
            <Tab label="Empresa" />
          </Tabs>
        )}

        {previewError && <Alert severity="warning">{previewError}</Alert>}

        {/* Mostrar info de sustituciones solo si hay previewData */}
        {!loading && previewData && (
          <Box sx={{ mb: 2 }}>
            <Alert severity="info" sx={{ mb: 1 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Sustituciones activas:
              </Typography>
              {getReplacementsInfo().map((replacement, index) => (
                <Box key={index} sx={{ ml: 2, mb: 0.5 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: replacement.warning ? "warning.main" : "inherit",
                    }}
                  >
                    {replacement.original} {" → "}
                    {replacement.warning ? (
                      <span style={{ color: "#ed6c02" }}>
                        {replacement.warning}
                      </span>
                    ) : (
                      replacement.replacement
                    )}
                  </Typography>
                </Box>
              ))}
            </Alert>
          </Box>
        )}

        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ px: 2 }}>{getContent()}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreviewModal;
