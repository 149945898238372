import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
} from "@mui/material";
import emailCredentialService from "../../services/emailCredentialService";
import EmailCredentialForm from "./EmailCredentialForm";

const EmailCredentialsList = ({ clientId }) => {
  const [credentials, setCredentials] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);

  const fetchCredentials = useCallback(async () => {
    try {
      const data = await emailCredentialService.getEmailCredentials(clientId);
      setCredentials(data);
    } catch (error) {
      console.error("Error al obtener credenciales:", error);
    }
  }, [clientId]);

  useEffect(() => {
    fetchCredentials();
  }, [fetchCredentials]);

  const handleOpenForm = (credential = null) => {
    setSelectedCredential(credential);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedCredential(null);
  };

  const handleSave = async (credentialData) => {
    try {
      if (selectedCredential) {
        await emailCredentialService.updateEmailCredential(
          clientId,
          selectedCredential.id,
          credentialData
        );
      } else {
        await emailCredentialService.createEmailCredential(
          clientId,
          credentialData
        );
      }
      fetchCredentials();
      handleCloseForm();
    } catch (error) {
      console.error("Error al guardar la credencial de correo:", error);
    }
  };

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{
          fontSize: "1.15rem",
          fontWeight: 500,
        }}
      >
        Credenciales de Correo
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm()}
        sx={{
          mb: 2,
          py: 0.5,
          fontSize: "0.875rem",
        }}
      >
        Agregar Nueva Credencial
      </Button>

      <List sx={{ p: 0 }}>
        {credentials.map((credential) => (
          <ListItem
            key={credential.id}
            sx={{
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 1.5,
              p: 1.5,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
                transition: "background-color 0.3s",
                cursor: "pointer",
              },
            }}
            onClick={() => handleOpenForm(credential)}
          >
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.875rem" }}>
                  {credential.email}
                </Typography>
              }
              secondary={
                <Typography sx={{ fontSize: "0.75rem" }} color="text.secondary">
                  Remitente: {credential.name_sender}
                  <br />
                  SMTP: {credential.server_smtp}:{credential.port_smtp}
                  <br />
                  IMAP: {credential.server_imap}:{credential.port_imap}
                </Typography>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenForm(credential);
              }}
              sx={{
                py: 0.25,
                px: 1,
                fontSize: "0.75rem",
                minWidth: "auto",
              }}
            >
              Editar
            </Button>
          </ListItem>
        ))}
      </List>

      <EmailCredentialForm
        open={openForm}
        onClose={handleCloseForm}
        onSubmit={handleSave}
        initialData={selectedCredential}
        clientId={clientId}
      />
    </Box>
  );
};

export default EmailCredentialsList;
