import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";

const CampaignTemplateList = () => {
  const { clientId, campaignId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVertical, setSelectedVertical] = useState("all");
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailCredential, setEmailCredential] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const [templatesData, verticalsData] = await Promise.all([
          campaignService.getCampaignTemplates(clientId, campaignId),
          verticalService.getClientVerticals(clientId),
        ]);
        setTemplates(templatesData);
        setVerticals(verticalsData);
      } catch (error) {
        setError(error.response?.data?.message || "Error al cargar los datos");
        console.error("Error al cargar los datos:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [clientId, campaignId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al cargar las credenciales de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const handleVerticalChange = (event, newValue) => {
    setSelectedVertical(newValue);
  };

  const getFilteredTemplates = () => {
    if (selectedVertical === "all") {
      return templates;
    }
    return templates.filter(
      (template) =>
        template.vertical_ids &&
        template.vertical_ids.includes(parseInt(selectedVertical))
    );
  };

  const getTemplateType = (template) => {
    if (template.after_rejection) return "rejection";
    if (template.num_days_without_response > 0) return "followup";
    return "presentation";
  };

  const getTemplateBorderStyle = (type) => {
    switch (type) {
      case "followup":
        return "2px solid #FFD700"; // Yellow border for followup
      case "rejection":
        return "2px solid #FF6B6B"; // Red border for rejection
      default:
        return "1px solid #ddd"; // Default border for presentation
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 800, margin: "auto", mt: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  return (
    <>
      <Box sx={{ margin: "auto", p: 2 }}>
        <Typography variant="h4" gutterBottom>
          Plantillas de Campaña
        </Typography>

        <Box
          sx={{
            mb: 3,
            mt: 2,
            display: "flex",
            alignItems: "center",
            gap: 3,
            p: 1.5,
            borderRadius: 2,
            bgcolor: "rgba(0, 0, 0, 0.02)",
            border: "1px solid rgba(0, 0, 0, 0.04)",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontWeight: 500,
              minWidth: "fit-content",
            }}
          >
            Tipos:
          </Typography>
          <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  border: "1px solid #ddd",
                  borderRadius: "50%",
                }}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Presentación
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  border: "2px solid #FFD700",
                  borderRadius: "50%",
                }}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Seguimiento
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  border: "2px solid #FF6B6B",
                  borderRadius: "50%",
                }}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Rechazo
              </Typography>
            </Box>
          </Box>
        </Box>

        <Button
          component={Link}
          to={`/clients/${clientId}/campaigns/${campaignId}/templates/new`}
          variant="contained"
          color="primary"
          sx={{ mb: 3 }}
        >
          Crear Nueva Plantilla
        </Button>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs
            value={selectedVertical}
            onChange={handleVerticalChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Todos" value="all" />
            {verticals.map((vertical) => (
              <Tab
                key={vertical.id}
                label={vertical.name_vertical}
                value={vertical.id.toString()}
              />
            ))}
          </Tabs>
        </Box>

        <List>
          {getFilteredTemplates().map((template) => {
            const templateType = getTemplateType(template);
            return (
              <ListItem
                key={template.id}
                sx={{
                  border: getTemplateBorderStyle(templateType),
                  borderRadius: 1,
                  mb: 2,
                  p: 2,
                  backgroundColor: "background.paper",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography variant="h6">{template.subject}</Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          bgcolor: "rgba(0,0,0,0.05)",
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                        }}
                      >
                        {templateType === "followup"
                          ? `Seguimiento (${template.num_days_without_response} días)`
                          : templateType === "rejection"
                          ? "Rechazo"
                          : "Presentación"}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box sx={{ typography: "body2" }}>
                      <Box>
                        Vertical:{" "}
                        {template.vertical_ids &&
                        template.vertical_ids.length > 0
                          ? verticals
                              .filter((v) =>
                                template.vertical_ids.includes(v.id)
                              )
                              .map((v) => v.name_vertical)
                              .join(", ")
                          : "Sin vertical asignado"}
                      </Box>
                      <Box>
                        Emails Enviados: {template.emails_sent} (
                        {template.unique_contacts} contactos únicos,{" "}
                        {template.total_companies} empresas)
                      </Box>
                      {template.datetime_start && (
                        <Box>
                          Inicio:{" "}
                          {new Date(
                            template.datetime_start
                          ).toLocaleDateString()}
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handlePreview(template)}
                  >
                    Vista Previa
                  </Button>
                  <Button
                    component={Link}
                    to={`/clients/${clientId}/campaigns/${campaignId}/templates/${template.id}`}
                    variant="outlined"
                    color="primary"
                  >
                    Editar
                  </Button>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={selectedTemplate?.body || ""}
        textClosing={emailCredential?.text_closing || ""}
        clientId={clientId}
        subject={selectedTemplate?.subject || ""}
      />
    </>
  );
};

export default CampaignTemplateList;
