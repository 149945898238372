import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Login from "./components/auth/Login";
import ClientList from "./components/clients/ClientList";
import ClientProfile from "./components/clients/ClientProfile";
import ClientForm from "./components/clients/ClientForm";
import CampaignList from "./components/campaigns/CampaignList";
import CampaignForm from "./components/campaigns/CampaignForm";
import CampaignTemplateEditor from "./components/campaigns/CampaignTemplateEditor";
import CampaignTemplateList from "./components/campaigns/CampaignTemplateList";
import Breadcrumbs from "./components/common/Breadcrumbs";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import KeywordList from "./components/keywords/KeywordList";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, isLoading } = useAuth();

  if (isLoading) {
    return (
      <CircularProgress
        sx={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );
  }

  return isLoggedIn ? children : <Navigate to="/login" />;
};

function AppContent() {
  const { isLoggedIn } = useAuth();

  return (
    <div className="App">
      {isLoggedIn && <Header />}
      {isLoggedIn && <Breadcrumbs />}
      <main style={{ padding: "0 24px", margin: "0 auto" }}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Navigate to="/clients" />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <ClientList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/new"
            element={
              <ProtectedRoute>
                <ClientForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId"
            element={
              <ProtectedRoute>
                <ClientProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/edit"
            element={
              <ProtectedRoute>
                <ClientForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns"
            element={
              <ProtectedRoute>
                <CampaignList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns/new"
            element={
              <ProtectedRoute>
                <CampaignForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns/:campaignId"
            element={
              <ProtectedRoute>
                <CampaignForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns/:campaignId/templates"
            element={
              <ProtectedRoute>
                <CampaignTemplateList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns/:campaignId/templates/new"
            element={
              <ProtectedRoute>
                <CampaignTemplateEditor />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId/campaigns/:campaignId/templates/:templateId"
            element={
              <ProtectedRoute>
                <CampaignTemplateEditor />
              </ProtectedRoute>
            }
          />
          <Route path="/keywords" element={<KeywordList />} />
          <Route
            path="/"
            element={
              isLoggedIn ? <Navigate to="/clients" /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
